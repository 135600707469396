export const inspectionSummary = ["inspectionOfficer", "inspectionId", "purposeOfInspection", "date", "time", "recommendation"];
export const debators = ["description", "period", "amount", "numberOfDays"];
export const creditors = ["description", "period", "amount", "numberOfDays"];
export const stock = ["type", "description", "amount", "numberOfDays"];
export const empDetails = ["employeeType", "permanent", "contract", "belowFourty"];
export const expenseBusiness = ["expenseType", "frequency", "expense"];
export const salesandPurchaseInformationofFastMovingItemsOtherdata = ["cashInTill", "recommendedProfit", "item"];
export const income = ["sourceOfIncome", "income"];
export const totaleBusinessAssertOtherDetails = ["assetCategory", "assertType", "forcedSaleValue", "marketValue", "assertLiquidity", "assertTimeFrame"];
export const totaleBusinessFixedAssertOtherDetails = ["assetCategory", "assertType", "forcedSaleValue", "marketValue", "assertLiquidity", "assertTimeFrame"];
export const totaleBusinessCurrentAssertOtherDetails = ["assetCategory", "assertType", "forcedSaleValue", "marketValue", "assertLiquidity", "assertTimeFrame"];

// export const businessLiabilityExpenses = ["liabilityType",
// "bankorInstitute",
// "amountofTheFacility",
// "installmentFrequency",
// "installmentAmount",
// "numberofInstallmentsLeft",];
export const employementDetails = {
    extractEmployeType: ["employeeType", "permanent", "contract"],
    extractAgeRange: {
        noOfFemale: ["belowSixteen", "betweenSixteenAndTwentyFive", "betweenTwentySixAndThirtyFive", "betweenThirtySixAndFortyFive", "aboveFourtyFive"],
        noOfMale: ["belowSixteen", "betweenSixteenAndTwentyFive", "betweenTwentySixAndThirtyFive", "betweenThirtySixAndFortyFive", "aboveFourtyFive"]
    },
}

export const businessLiabilityExpenses = {
    default: ["bankorInstitute"],
    1: ["liabilityType", "amountofTheFacility", "obligationType", "installmentFrequency", "currentOutstanding", "numberofInstallmentsLeft", "installmentAmount"],
    2: ["limitOfOverDraft", "outStanding", "expiryDate", "securityCategoryOverDraft"]

}
