import { Downgraded, useState } from "@hookstate/core";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../../../../configs/constants/styleValues";
import { creditData, userDetails } from "../../../../../../../../configs/mainStore";
import { getAppraisalData, saveAppraisalData } from "../../../../../../../../services/creditFileApiCall";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { addToaster } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";

import { standardDateFormatter } from "los-util";
import moment from "moment";
import { privilegeKeys } from "../../../../../../../../configs/constants/privilegeKeys";
import { dbDateFormatterFn } from "../../../../../../../../utility/fomatters";
import ContentExtractor from "../../../../../../../../utility/helpers/ContentExtractor";
import { getIsDisbursed } from "../../../../../../../../utility/helpers/getIsDisbursed";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";

const salaryDeductionState = {
  monthlyRegulatedNumber: "",
  validFrom: "",
  validTo: "",
};

const SalaryDeduction = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion, innerComponentData, tabsToEdit }) => {
  const salaryDeduction = useState(salaryDeductionState);
  const isLoading = useState(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isSaving = useState(false);

  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const params = {
        applicationId: applicationId,
        section: "salary_deduction",
      };
      const data: any = await getAppraisalData(params);

      if (data && Object.keys(data).length > 0) {
        salaryDeduction.set(data);
      }
      isLoading.set(false);
    })();
  }, []);

  const onSaveData = async () => {
    isSaving.set(true);
    const res: any = await saveAppraisalData({
      section: "salary_deduction",
      applicationId: applicationId,
      data: { ...salaryDeduction?.get(), userId: userId },
    });

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }
    isSaving.set(false);
  };

  const extractValidityPeriodDateFrom = function (validityDate) {
    let date = validityDate?.get()?.validFrom === "" || validityDate?.get()?.validFrom == "Invalid Date" ? null : validityDate?.get()?.validFrom;
    try {
      return date;
    } catch (error) {
      return null;
    }
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(privilegeKeys.APPRAISAL_TAB_EDIT_APPRAISAL, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  function getMinDate() {
    var d = new Date();
    d.setMonth(d.getMonth() - 6);
    const formattedDate = standardDateFormatter(d);
    let year = parseInt(formattedDate.split("/")[2]);
    let month = parseInt(formattedDate.split("/")[1]) - 1;
    let date = parseInt(formattedDate.split("/")[0]);
    return moment().set("year", year).set("month", month).set("date", date);
  }

  const Header = () => {
    return <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 100, top: "0px" }} justifyContent={"center"}>
      <Typography className="basic-font basic-font-color-bold">Salary Deduction</Typography>
    </Stack>;
  }

  if (isLoading?.get()) {
    return <Paper>
      <Stack style={{ height: "300px" }}>
        <Header />
        <Stack style={{ height: "100%", width: "100%" }} justifyContent={"center"} alignItems={"center"}>
          <CircularProgressComponent size={30} />
        </Stack>
      </Stack>
    </Paper>;
  }

  return <Paper>
    <Stack>
      <Header />
      <Stack flex={1} p={2}>
        <Stack flex={1} direction={"column"}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}> Monetary Regulation Number </Typography>
            <Stack width={"25%"}>
              <ContentExtractor text="APPRAISAL_TAB_EDIT_APPRAISAL">
                <TextBoxComponent label={"Monetary Regulation Number"} value={salaryDeduction?.monthlyRegulatedNumber?.get()} fullWidth={true} onChange={(e) => salaryDeduction?.monthlyRegulatedNumber?.set(e.target.value)} onInput={OnDemandValueFormatter.alphaNumeric} disabled={checkPrivilegeStatus()} />
              </ContentExtractor>
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={1} alignItems={"flex-start"} mt={4}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              Validity Period From (DD/MM/YYYY)
            </Typography>
            <Stack width={"25%"}>
              <ValidateDatePicker
                label={"Validity Period"}
                value={extractValidityPeriodDateFrom(salaryDeduction)}
                minDate={getMinDate()}
                onChange={(date) => {
                  date && salaryDeduction?.validFrom?.set(dbDateFormatterFn(date._d));
                }}
                enableFuture={true}
                disablePast={false}
                disabled={checkPrivilegeStatus()}
              />
            </Stack>
          </Stack>

          <Divider sx={{ marginTop: 4, marginBottom: 1 }}></Divider>
          <Stack direction={"row"} spacing={1} flex={1}>
            <Stack width={"150pt"}></Stack>
            <Stack flex={1} direction={"row-reverse"}>
              <StyledButton styles={isSaving?.get() ? loadingButtonStyle : continueButtonStyle} title={"Save"} onClick={onSaveData} loading={isSaving?.get()} disabled={checkPrivilegeStatus()} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Paper>
};

export default SalaryDeduction;
