import { yearArrayObject } from "../../../../../../CreditInspection/FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";
import { onChangeHandler } from "../../Calculations/GenaralizedDataSetter";

export const generalizedSetStateFromTheBusinessData = ({ state, busineeData }) => {

    let yearArray: number[] = Object.values(yearArrayObject);

    const { businessIncome = {}, deductionsCostsOfGoods = {}, businessExpenses = {}, totaleBusinessAssertOtherDetails = {} } = busineeData;

    let itemObject = yearArray.reduce((dataObject: any, year: number) => {

        dataObject = onChangeHandler({ value: getTotal(businessIncome?.[year], "annualBusinessIncome"), yearDataKey: year, itemDataKey: "revenueIncome", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(deductionsCostsOfGoods?.[year], "annualDeductionsCOG"), yearDataKey: year, itemDataKey: "reductions", formData: dataObject, selectedYears: yearArray });

        const { sellingDistributionExpenses, administrativeExpenses } = annualExpenseCalculationHandler(businessExpenses?.[year]);

        dataObject = onChangeHandler({ value: getTotal(sellingDistributionExpenses, "annualExpense"), yearDataKey: year, itemDataKey: "sellingDistributionExpenses", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(administrativeExpenses, "annualExpense"), yearDataKey: year, itemDataKey: "administrativeExpenses", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(totaleBusinessAssertOtherDetails?.[year], "depreciationExpense"), yearDataKey: year, itemDataKey: "depreciation", formData: dataObject, selectedYears: yearArray })

        return dataObject;
    }, {});
    state(itemObject);
}

const getTotal = (state, key: string) => {
    return Array.isArray(state) ? state.reduce((cal, item) => cal += Number(item?.[key] || 0), 0) : 0;
}

const annualExpenseCalculationHandler = (state) => {
    const response = { "sellingDistributionExpenses": [], "administrativeExpenses": [] }

    if (!Array.isArray(state)) return response;

    return state.reduce((filteredData, item) => {

        if (!item || item?.['removedItem']) return filteredData;

        if (item?.expenseType === "Selling & Distribution") {
            filteredData.sellingDistributionExpenses.push(item)
        } else {
            filteredData.administrativeExpenses.push(item)
        }
        return filteredData
    }, response)
}
