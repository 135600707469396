import React from "react";
import AuditLogApplicationChangesModal from "../../../layouts/AuditLog/Modal/AuditLogApplicationChangesModal";
import { AddEditChannelManagementComponent } from "../../../layouts/Channels/AddEditChannelManagementTemplateComponent";
import ChannelManagementAddUserModal from "../../../layouts/Channels/ChannelManagementAddUsers";
import GenerateQRCode from "../../../layouts/Channels/GenerateQRCode";
import ChargeDeleteConfirm from "../../../layouts/Charges/ManageCharges/subComponents/ChargeDeleteConfirm";
import ConfigChargeModal from "../../../layouts/Charges/ManageCharges/subComponents/ConfigChargeModal";
import ProductTypeSelectComponent from "../../../layouts/Charges/ManageCharges/subComponents/ProductTypeSelectComponent";
import CustomEntryModal from "../../../layouts/CustomEntryModal";
import AddApplicationSection from "../../../layouts/DocumentManagement/ApplicationSectionMap/AddApplicationSection";
import { JsonEditorComponentModal } from "../../../layouts/JsonEditorComponent";
import AddWorkflowStageModal from "../../../layouts/Preferences/AddWorkflowStageModal";
import ChecklistConfigurationModal from "../../../layouts/Preferences/ChecklistConfiguration/ChecklistConfigurationModal";
import ConditionCloneModal from "../../../layouts/Preferences/PredefinedConditionManage/ConditionCloneModal";
import CreateNewConditions from "../../../layouts/Preferences/PredefinedConditionManage/Modals/CreateNewConditions";
import StagePrivilegeSaveConfirmation from "../../../layouts/Preferences/StageDocumentProductConfiguration/StagePrivilegeSaveConfirmation";
import AllowedDecision from "../../../layouts/Preferences/WorkflowTransitionConfiguration/Components/AllowedDecision";
import AllowedValidation from "../../../layouts/Preferences/WorkflowTransitionConfiguration/Components/AllowedValidation";
import ProductCatalogCreateModal from "../../../layouts/Preferences/products/ProductCatalogCreateModal";
import ProductDetailsUpdateModal from "../../../layouts/Preferences/products/ProductDetailsUpdateModal";
import ProductFormDependencies from "../../../layouts/Preferences/products/ProductFormMapping/ProductFormDependencies";
import ProductFormMappingBuildModal from "../../../layouts/Preferences/products/ProductFormMapping/ProductFormMappingBuildModal";
import ProductManageModal from "../../../layouts/Preferences/products/ProductManageModal";
import ProductTypeSectorConfiguration from "../../../layouts/Preferences/products/ProductTypeSectorConfiguration";
import ProductTypeSectorSchemeConfiguration from "../../../layouts/Preferences/products/ProductTypeSectorSchemeConfiguration";
import ProductWorkflowMappingBuildModal from "../../../layouts/Preferences/products/ProductWorkflowMappingBuildModal";
import ConfirmMultipleAssignment from "../../../layouts/PrivilegeLayout/Modal/ConfirmMultipleAssignment";
import PrevilegeGroupModal from "../../../layouts/PrivilegeLayout/PrivilegeGroupModal";
import PrivilegeAssignmentModal from "../../../layouts/PrivilegeLayout/UserPrivilegeSubComponents/PrivilegeAssignmentModal";
import UserRegisterLayout from "../../../layouts/PrivilegeLayout/UserRegisterLayout";
import UserRegisterationWizard from "../../../layouts/PrivilegeLayout/UserRegisterationWizard";
import SSCCBSLayout from "../../../layouts/SSCCBSLayout";
import SalesSupportEmployers from "../../../layouts/SalesSupportEmployers";
import SuperPrivilegeTrackingModal from "../../../layouts/SuperPrivilegeTrackingModal";
import ManageProductDla from "../../../layouts/UserConfigs/Components/ManageProductDla";
import SelectHubModal from "../../../layouts/UserConfigs/Components/SelectHubModal";
import UserConfigerationBranchModal from "../../../layouts/UserConfigs/Components/UserConfigerationBranchModal";
import UserConfigerationModal from "../../../layouts/UserConfigs/Components/UserConfigerationModal";
import UserPrivilegeModal from "../../../layouts/UserConfigs/Components/UserPrivilegeModal";
import ViewTC from "../../../layouts/ViewTC";
import StepSectionChangesConfirmationModal from "../../../utility/CustomHooks/StepSectionChangesConfirmationModal";
import { NewApplicationForm } from "../AppFromBuilder/NewApplicationForm";
import ApplicationHistoryModal from "../ApplicationForm/ModalSections/ApplicationHistoryModal";
import ModalApplicationFormDetails from "../ApplicationForm/ModalSections/ModalApplicationFormDetails";
import SectionDeleteConfirm from "../ApplicationForm/ModalSections/SectionDeleteConfirm";
import { ConfirmDialogModal } from "../ApplicationForm/SavedFormDelete";
import AgricultureForm from "../ApplicationForm/Sections/AgricultureDetails/Components/AgricultureForm";
import ShareholderAssignModal from "../ApplicationForm/Sections/BusinessDetails/AthamaruBusinessSections/ShareholderAssignModal";
import ShareholderClearConfirm from "../ApplicationForm/Sections/BusinessDetails/Employment/SelfEmployed/SelfEmployment/ShareholderClearConfirm";
import DeleteJointBorrowerModal from "../ApplicationForm/Sections/JointBorrower/DeleteJointBorrower";
import AssigneeChangeModal from "../ApplicationLeadManagement/Application/AssigneeChangeModal";
import ReferralChangeAssignee from "../ApplicationLeadManagement/Referral/Modal/ReferralChangeAssigneeModal";
import { AddEditSmsPlaceholderTemplateComponent } from "../CommunicationService/sms/AddEditSmsPlaceholderTemplateComponent";
import { CreatePlaceholdersModal } from "../CommunicationService/sms/Modal/CreatePlaceholdersModal";
import AgreementConditionModal from "../CreditFile/Components/AgreementCondition/AgreementConditionModal";
import { BusinessAuthCertificateDocumentPreviewComponent } from "../CreditFile/Components/Application/BusinessDetails/BusinessObtainedAuthCertificateDocs/DocumentPreviewComponent";
import GroupSelectionModal from "../CreditFile/Components/Application/GroupSelection/GroupSelectionModal";
import UserSelectionModal from "../CreditFile/Components/Application/UserSelection/UserSelectionModal";
import WorkflowStagesSelectionModal from "../CreditFile/Components/Application/UserSelection/WorkflowStagesSelectionModal";
import ApplicationChangesModal from "../CreditFile/Components/ApplicationHistory/Modal/ApplicationChangesModal";
import RequirementComment from "../CreditFile/Components/Appraisal/Micro/Components/AnalysisOfLoanRequirement/subComponents/RequirementComment";
import ViewMarketAnalysis from "../CreditFile/Components/Appraisal/Micro/Components/AnalysisOfProposedLoans/ViewAnalysis";
import BalanceSheetModal from "../CreditFile/Components/Appraisal/Micro/Components/FinancialPositionOfTheCompany/Components/BalanceSheet/BalanceSheetModal";
import DeleteConfirm from "../CreditFile/Components/Appraisal/Micro/Helpers/Modals/DeleteConfirm";
import NetOtherIncomeModal from "../CreditFile/Components/Appraisal/Retail/Components/AbilityToPayLoan/NetOtherIncomeModal";
import AuditModal from "../CreditFile/Components/Audit/AuditModal";
import CAUHDModal from "../CreditFile/Components/CAUHubDepartment/CAUHDModal";
import ChargesModal from "../CreditFile/Components/Charges/ChargesModal";
import { AddTaskChecklistModal } from "../CreditFile/Components/Checklist/TaskChecklist/AddTaskChecklist";
import ConditionModal from "../CreditFile/Components/Condition/ConditionModal";
import OfferLetterPredefineConditionModal from "../CreditFile/Components/Condition/OfferLetterPredefineConditionModal";
import { InspectionDocumentPreviewComponent } from "../CreditFile/Components/CreditInspection/FormBuilder/Tools/Widgets/DocumentPreviewComponent";
import { AddCribNoteModal } from "../CreditFile/Components/Crib/AddCribNoteModal";
import { AddCribRecordModal } from "../CreditFile/Components/Crib/AddCribRecordModal";
import { DocumentSubflowPreviewComponent } from "../CreditFile/Components/DocumentSubflowPreviewComponent";
import ScoreCardLayout from "../CreditFile/Components/InspectionScoreCard/ScoreCardLayout";
import InsuranceDepartmentModal from "../CreditFile/Components/InsurancePolicy/SubFlowComponent/InsuranceDepartmentModal";
import LegalModal from "../CreditFile/Components/Legal/LegalModal";
import MasterDataTitleEditModal from "../CreditFile/Components/MasterData/MasterDataTitleEditModal";
import NoteModal from "../CreditFile/Components/Note/NoteModal";
import ConditionDeleteConfirm from "../CreditFile/Components/Predisbursement/ConditionDeleteConfirm";
import AdditionalConditionsParameterModal from "../CreditFile/Components/Predisbursement/PredisbursedmentAsPerTheNewReq/AdditionalConditionsParameterModal";
import PredisbursementCustomModal from "../CreditFile/Components/Predisbursement/PredisbursementCustomModal";
import PredisbursementModal from "../CreditFile/Components/Predisbursement/PredisbursementModal";
import HeadCauReferModal from "../CreditFile/Components/Refer/CauReferModal/HeadReferModal";
import OfficerCauReferModal from "../CreditFile/Components/Refer/CauReferModal/OfficerReferModal";
import InsuranceDepReferralModal from "../CreditFile/Components/Refer/InsuranceDepReferralModal";
import OfficerHeadReferHeadModal from "../CreditFile/Components/Refer/OfficerHeadRefer/OfficerHeadReferHeadModal";
import OfficerHeadReferManagerModal from "../CreditFile/Components/Refer/OfficerHeadRefer/OfficerHeadReferManagerModal";
import OfficerHeadReferModal from "../CreditFile/Components/Refer/OfficerHeadRefer/OfficerHeadReferModal";
import ReferActionModal from "../CreditFile/Components/Refer/ReferActionModal";
import ReferActionOppinionModal from "../CreditFile/Components/Refer/ReferActionOppinionModal";
import ReferDetachModal from "../CreditFile/Components/Refer/ReferDetachModal/ReferDetachModal";
import ReferModal from "../CreditFile/Components/Refer/ReferModal";
import { AddCreditAuditModal } from "../CreditFile/Components/Review/AddCreditAuditModal";
import { FundReleaseApprovalModal } from "../CreditFile/Components/Review/FundReleaseApprovalModal";
import ReferralRiskModal from "../CreditFile/Components/Risk/ReferralRiskModal";
import RiskModal from "../CreditFile/Components/Risk/RiskModal";
import ViewReferralHistory from "../CreditFile/Components/Risk/ViewReferralHistory";
import SecurityDocumentTypeModal from "../CreditFile/Components/SecurityDocuments/SecurityDocumentModals/SecurityDocumentTypeModal";
import StandingOrderInputModal from "../CreditFile/Components/SecurityDocuments/SecurityDocumentModals/StandingOrderInputModal";
import SubWorkflowChangesValidation from "../CreditFile/Components/SubWorkflowStagesModal";
import ApplicationAppraisalSummary from "../CreditFile/Components/SummaryComponent/ApplicationAppraisalSummary";
import LetterAmountModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterAmountModal";
import LetterConditionStages from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterConditionStagesaModal";
import LetterDepositAmountModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterDepositAmountModal";
import LetterDepositPercentageModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterDepositPercentageModal";
import LetterDisbursementOfFacilityModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterDisbursementOfFacilityModal";
import LetterRateOfInterestModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterRateofInterestModal";
import LetterSignatureModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterSignatureModal";
import LetterThankYouModal from "../CreditFile/Components/SummaryLetterComponents/SummaryModal/LetterThankYouModal";
import { AddFollowUpChecklistModal } from "../CreditFile/Components/Tasks/AddFollowUpChecklistModal";
import DisbursementChecklistModal from "../CreditFile/Components/Tasks/DisbursementChecklistModal";
import TaskActionModal from "../CreditFile/Components/Tasks/TaskActionModal";
import TaskAssigneeModal from "../CreditFile/Components/Tasks/TaskAssigneeModal";
import TaskModal from "../CreditFile/Components/Tasks/TaskModal";
import WorkflowUIJobHandler from "../CreditFile/Components/UIJobHandler/WorkflowUIJobHandler";
import { TabValidationModal } from "../CreditFile/Components/Validation/TabValidationModal";
import ViewReferralNoteModal from "../CreditFile/Components/ViewReferralNoteModal";
import WorkflowRevert from "../CreditFile/Components/Workflow/WorkflowRevert";
import ApplicationSubmit from "../CreditFile/HeaderSection/ApplicationSubmit";
import { WorkflowActionConfirmModal } from "../CreditFile/HeaderSection/workflowActionConfirmModal";
import DifferApplicationsConfirmationModal from "../DifferApplication/DifferApplicationsConfirmationModal";
import AddDisbursementDocuments from "../MainSectionsWrapper/AddDisbursementDocuments";
import ViewDisbursementDocumentUploadHistory from "../MainSectionsWrapper/ViewDisbursementDocumentUploadHistory";
import MasterDataModal from "../MasterDataComponents/MasterDataModal";
import ResetPasswordModal from "../MenuBarComponent/ResetPasswordModal";
import ModalCustomerPerformance from "../OnlineApplication/ModalCustomerPerformance";
import PriorActionModal from "../PriorApprovals/prior-approval-data-table/PriorActionModal";
import PriorApprovalReferenceModal from "../PriorApprovals/prior-approval-data-table/PriorApprovalReferenceModal";
import { DisbursementDocumentPreviewComponent } from "../ProductDocumentUploadComponent/DisbursementDocumentPreviewComponent";
import { DocumentDetailsInfoModal } from "../ProductDocumentUploadComponent/DocumentDetailsInfoModal";
import { DocumentPreviewComponent } from "../ProductDocumentUploadComponent/DocumentPreviewComponent";
import ScoreCardGroupFormLayout from "../ProductScoreCardGroup/ScoreCardGroupOverview/ScoreCardGroupFormLayout";
import ScorecardQuestionsMappingModal from "../ScorecardsConfiguration/ScorecardQuestionsMappingModal";
import ScorecardTemplateModal from "../ScorecardsConfiguration/ScorecardTemplateModal";
import ProductPreferences from "../WorkflowComponents/MappedWorkflows/ProductPreferences";
import EditEmailJobModalContainer from "../WorkflowComponents/WorkflowDraw/SubComponents/edit/node/Job/email/EditEmailJobModalContainer";
import { EditSMSJobModalContainerV2 } from "../WorkflowComponents/WorkflowDraw/SubComponents/edit/node/Job/sms/EditSMSJobModalContainer";
import CloneWorkflowFormModal from "../WorkflowComponents/WorkflowOverview/CloneWorkflowFormContainer";
import { CreateWorkflowModal } from "../WorkflowComponents/WorkflowOverview/CreateWorkflowFormContainer";
import WorkflowStageOrderConfig from "../WorkflowComponents/WorkflowOverview/WorkflowStageOrderConfig";
import WorkflowStageActionManageModal from "../WorkflowComponents/WorkflowStageActionsManage/WorkflowStageActionManageModal";

export const modalComponentMapping: any = {
  "document-data-preview": DocumentPreviewComponent,
  "inspection-document-data-preview": InspectionDocumentPreviewComponent,
  "disbursement-document-data-preview": DisbursementDocumentPreviewComponent,
  "workflow-action-confirm": WorkflowActionConfirmModal,
  "task-modal": TaskModal,
  "fund-release-approval": FundReleaseApprovalModal,
  "credit-audit": AddCreditAuditModal,
  "follow-up-checklist": AddFollowUpChecklistModal,
  "task-action-modal": TaskActionModal,
  "refer-action-modal": ReferActionModal,
  "referral-modal": ReferModal,
  "upload-crib-record": AddCribRecordModal,
  "add-workflow-stage-modal": AddWorkflowStageModal,
  "trail-calculator-layout": SSCCBSLayout,
  "tab-validation-modal": TabValidationModal,
  "note-modal": NoteModal,
  "condition-modal": ConditionModal,
  "agreement-condition-modal": AgreementConditionModal,
  "legal-modal": LegalModal,
  "risk-modal": RiskModal,
  "audit-modal": AuditModal,
  "pre-disbursement-modal": PredisbursementModal,
  "offer-letter-predefine-condition-modal": OfferLetterPredefineConditionModal,
  "pre-disbursement-custom-modal": PredisbursementCustomModal,
  "application-preview": ApplicationAppraisalSummary,
  "task-check-list-modal": AddTaskChecklistModal,
  "workflow-ui-job-handler": WorkflowUIJobHandler,
  "offer-letter-amount": LetterAmountModal,
  "offer-letter-rof": LetterRateOfInterestModal,
  "offer-letter-dof": LetterDisbursementOfFacilityModal,
  "offer-letter-thank-you": LetterThankYouModal,
  "offer-letter-signature": LetterSignatureModal,
  "offer-letter-condition": LetterConditionStages,
  "offer-letter-deposit-percentage": LetterDepositPercentageModal,
  "offer-letter-deposit-amount": LetterDepositAmountModal,
  "charges-modal": ChargesModal,
  "refer-action-oppinion-modal": ReferActionOppinionModal,
  "referral-risk-modal": ReferralRiskModal,
  "security-document-type-modal": SecurityDocumentTypeModal,
  "standing-order-input-modal": StandingOrderInputModal,
  "reset-password-modal": ResetPasswordModal,
  "additional-conditions-parameters": AdditionalConditionsParameterModal,
  "assignee-change-modal": AssigneeChangeModal,
  "officer-head-refer-modal": OfficerHeadReferModal,
  "referral-cau-manager": OfficerHeadReferManagerModal,
  "referral-cau-head": OfficerHeadReferHeadModal,
  "condition-delete-confirm": ConditionDeleteConfirm,
  "user-configeration-modal": UserConfigerationModal,
  "user-configeration-branch-modal": UserConfigerationBranchModal,
  "net-other-income-modal": NetOtherIncomeModal,
  "privelege-group-modal": PrevilegeGroupModal,
  "insuranse-dep-referral-modal": InsuranceDepReferralModal,
  "add-application-section": AddApplicationSection,
  "view-tc": ViewTC,
  "user-privilege-modal": UserPrivilegeModal,
  "application-changes-modal": ApplicationChangesModal,
  "audit-log-application-changes-modal": AuditLogApplicationChangesModal,
  "edit-master-data-title": MasterDataTitleEditModal,
  "confirm-dialog-modal": ConfirmDialogModal,
  "differ-application-confirmation": DifferApplicationsConfirmationModal,
  "disbursement-checklist": DisbursementChecklistModal,
  "create-sms-placeholders": AddEditSmsPlaceholderTemplateComponent,
  "application-submit": ApplicationSubmit,
  "officer-cau-referral-modal": OfficerCauReferModal,
  "head-cau-referral-modal": HeadCauReferModal,
  "create-new-template": AddEditChannelManagementComponent,
  "channel-management-add-users": ChannelManagementAddUserModal,
  "qr-code-modal": GenerateQRCode,
  "modal-application-form-edit": ModalApplicationFormDetails,
  "performance-modal": ModalCustomerPerformance,
  "prior-Action-Modal": PriorActionModal,
  "add-documents": AddDisbursementDocuments,
  "view-disbursement-document-upload-history": ViewDisbursementDocumentUploadHistory,
  "modal-application-stage-revert-nolog": WorkflowRevert,
  "modal-lead-preview-submit-application": PriorApprovalReferenceModal,
  "app-section-delete-confirm": SectionDeleteConfirm,
  "task-assignee-modal": TaskAssigneeModal,
  "product-preferences": ProductPreferences,
  "create-placeholders-modal": CreatePlaceholdersModal,
  "stage-privilege-save-confirmation": StagePrivilegeSaveConfirmation,
  "update-rules": AllowedValidation,
  "update-decisions": AllowedDecision,
  "user-create-or-update-modal": UserRegisterLayout,
  "user-registration-wizard": UserRegisterationWizard,
  "assign-shareholder-modal": ShareholderAssignModal,
  "add-master-data-type-modal": MasterDataModal,
  "add-score-card-product-modal": ScorecardTemplateModal,
  "add-score-card-questions-modal": ScorecardQuestionsMappingModal,
  "add-crib-note": AddCribNoteModal,
  "score-card-group-form-layout": ScoreCardGroupFormLayout,
  "view-score-card": ScoreCardLayout,
  "requirement-comment": RequirementComment,
  "micro-appraisal-delete-confirm": DeleteConfirm,
  "json-view-component-modal": JsonEditorComponentModal,
  "view-market-analysis": ViewMarketAnalysis,
  "select-hub-modal": SelectHubModal,
  "privilege-assignment-modal": PrivilegeAssignmentModal,
  "config-charge-modal": ConfigChargeModal,
  "charge-delete-confirm": ChargeDeleteConfirm,
  "modal-product-manage": ProductManageModal,
  "modal-product-type-unpublish-request": ProductTypeSectorConfiguration,
  "modal-product-type-scheme-unpublish-request": ProductTypeSectorSchemeConfiguration,
  "modal-workflow-stage-actions-manage": WorkflowStageActionManageModal,
  "confirm-group-user-multiple-assignment": ConfirmMultipleAssignment,
  "modal-product-details-editor": ProductDetailsUpdateModal,
  "modal-product-details-create": ProductCatalogCreateModal,
  "modal-add-workflows": ProductWorkflowMappingBuildModal,
  "modal-add-product-form": ProductFormMappingBuildModal,
  "product-form-dependencies": ProductFormDependencies,
  "sub-workflow-stages-validation": SubWorkflowChangesValidation,
  "view-referral-history": ViewReferralHistory,
  "shareholder-data-clear-confirm": ShareholderClearConfirm,
  "refer-detach": ReferDetachModal,
  "checklist-configuration-modal": ChecklistConfigurationModal,
  "condition-clone-modal": ConditionCloneModal,
  "sms-job-modal": EditSMSJobModalContainerV2,
  "email-job-modal": EditEmailJobModalContainer,
  "appsection-unsaved-changes-confirm": StepSectionChangesConfirmationModal,
  "view-referral-note": ViewReferralNoteModal,
  "manage-product-dla": ManageProductDla,
  'agriculture-application-form': AgricultureForm,
  "insuranse-department-modal": InsuranceDepartmentModal,
  "cau-hub-dep-modal": CAUHDModal,
  "subflow-document-preview": DocumentSubflowPreviewComponent,
  "referral-change-assignee": ReferralChangeAssignee,
  "create-workflow-modal": CreateWorkflowModal,
  "clone-workflow-modal": CloneWorkflowFormModal,
  "group-selection-modal": GroupSelectionModal,
  "pick-system-user-modal": UserSelectionModal,
  "pick-available-stages-modal": WorkflowStagesSelectionModal,
  "create-charge-template": ProductTypeSelectComponent,
  "application-history-modal": ApplicationHistoryModal,
  "workflow-stage-order-config": WorkflowStageOrderConfig,
  "custom-entry": CustomEntryModal,
  "balance-sheet-modal": BalanceSheetModal,
  "delete-joint-borrower-modal": DeleteJointBorrowerModal,
  "create-new-conditions-modal": CreateNewConditions,
  "document-details-info-modal": DocumentDetailsInfoModal,
  "create-form-modal": NewApplicationForm,
  "employers-registry": SalesSupportEmployers,
  "super-privilege-tracking-modal": SuperPrivilegeTrackingModal,
  "create-new-data-verification-checklist-item": React.lazy(() => import("../../../layouts/Preferences/DataVerificationChecklist/modal/CreateChecklists")),
  "clone-to-new-data-verification-checklist": React.lazy(() => import("../../../layouts/Preferences/DataVerificationChecklist/modal/CloneChecklists")),
  "business-auth-document-data-preview": BusinessAuthCertificateDocumentPreviewComponent
};
