import { useState } from "@hookstate/core";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { enDictionary } from "../../../../../../configs/languageFiles/english";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import { MainSectionsWrapper } from "../../../../MainSectionsWrapper/MainSectionsWrapper";
import DetailItem from "../../DetailItem";
import { collateralDropDownList } from "./IntermediateDetailsSanitizer";

const CollateralDetailsBuilder = ({ collateralData, title }: any) => {
  const isExpanded = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const masterDataStore: any = MasterDataProvider.provideMasterData();

  if (!collateralData && Object.keys(collateralData).length === 0) {
    return <></>;
  }

  const getValue = (value, key) => {
    if (["actionBrandNewRecondition", "actionRecondition", "actionSecondHand"].includes(key)) {
      const actionMapping = {
        actionBrandNewRecondition: "Brand New",
        actionRecondition: "Recondition",
        actionSecondHand: "Second Hand",
      };

      if (key === "actionBrandNewRecondition" && value === false) {
        return "Recondition";
      }

      if (value === true) {
        return actionMapping[key] || "-";
      }

      return "-";
    }

    if (value === true || value === false) {
      return value ? "Yes" : "No";
    }

    if (value == "" || value == null || value == undefined) {
      return "-";
    }

    try {
      if (value instanceof Date) {
        return value;
      }

      if (collateralDropDownList.hasOwnProperty(key) && value && value.toString().length > 7) {
        return value;
      }

      if (key && collateralDropDownList.hasOwnProperty(key)) {
        return masterDataStore.getMasterDataDescription(collateralDropDownList[key], value)
          ? masterDataStore.getMasterDataDescription(collateralDropDownList[key], value)
          : "-";
      }

      if (typeof value === "string") {
        if (!isNaN(Number(value))) {
          return formatCurrency(Number(value));
        }
      }
    } catch { }

    return value ? value : "-";
  };

  return (
    <MainSectionsWrapper title={title} noBtn={true} isSectionExpand={isExpanded.get()} keyCode={`_paperdoc_property_${title}_${Math.random()}`} onExpand={() => isExpanded.set(!JSON.parse(JSON.stringify(isExpanded.get())))} showStatus={false} subTitle={""}>
      {Object.keys(collateralData).map((mainKey: any, itemIndex: number) => {
        if (collateralData[mainKey].length === 0) {
          return <></>;
        }
        return (
          <Stack p={2} key={`${itemIndex}_stack_${mainKey}`} width={"100%"}>
            <Divider key={`divider_${itemIndex}`} />
            <>
              {collateralData[mainKey].map((obj: any, index: number) => (
                <>
                  <Typography key={`${itemIndex}_typograp`} pt={2}>
                    {translate(mainKey)}
                    {index > 0 ? `(${index + 1})` : ""}
                  </Typography>
                  <Grid container columns={12} key={`${index}_grid_container`}>
                    {Object.keys(obj).map((elemetKey, i: number) => {
                      if (["type", "itemIndex", "entryId", "isExpanded", "propertyValuation", "isNew"].includes(elemetKey)) return;
                      let lpDateKeys = ["lpPolicyDate", "lpDueDate"];
                      let liDateKeys = ["liDueDate", "liDateInsurancePolicyCertified", "liDateConfirmationLetterInsurance", "liDateConfirmationLetterAgeAccepted", "liDateLetterSignedThirdParty", "liDateSignedLetterBeneficiaries", "liDateCommencementInsurance", "liRegistrationDate"];
                      let fdAgainstDateKeys = ["fdLoanDetailsAccountOpenedOn", "fdMaturityDate", "fdDepositDate", "fdExpiryDate", "fdEffectiveDate", "fdLoanAutoRecoveryAccountOpenedOn"];
                      let wareHouseDateKeys = ["whrDepositedDate"];
                      let movableMachineryDateKeys = ["mmHPOrLienNotedOn", "mmExpiryDate", "mmInvoiceDate", "warrantyStartDate", "warrantyEndDate", "policyExpiryDate", "policyNextRenewalDate", "dateCommencementInsurance", "mmDatePreparedMortgageDeed", "mmDateRegistrationMortgageDeed", "mmDateFinalRegisteredFolio", "vhCReleasedDate"];
                      let movablePledgeDateKeys = ["pledgeDateOfLeaseAgreement", "pledgeDateBanksLockKey", "pledgeStockStatementValidUpToDate", "pledgeDatePreparePledgeAgreement", "pledgeDateOfInspection", "pledgeDateCommencementOfInsurance", "pledgeDateOfInventory"];
                      let movableVehicle = ["vhCReleasedDate", "vhValuerDate", "vhDateQuatation", "vhInvoiceDate"];
                      let immovableMachineryDateKeys = ["immHPOrLienNotedOn", "immExpiryDate", "immInvoiceDate", "immDatePreparedMortgageDeed", "immDateRegistrationMortgageDeed", "immDateFinalRegisteredFolio"];
                      let immovablePropertyDateKeys = ["propDateOfMortagage", "propSurveyPlanDate", "propRegistratonDate", "propObtainedDate", "propDatePreparedMortgageDeed", "propDateRegistrationMortgageDeed", "propDateFinalRegisteredFolio", "propIssuedDateTitleReport", "propDateNotaryPreparedDeed", "propDateRegistrationDeed", "propDateIssueStreetLineNonVestingCertificate", "propDateCertifiedBuildingPlan", "propDateValuation", "propDatePreparedBOQ", "propDateRegisteredPlan"];
                      let dateFieldKeys = [...lpDateKeys, ...liDateKeys, ...fdAgainstDateKeys, ...wareHouseDateKeys, ...movableMachineryDateKeys, ...movableVehicle, ...immovableMachineryDateKeys, ...movablePledgeDateKeys, ...immovablePropertyDateKeys];
                      let isDateField = false;
                      dateFieldKeys.includes(elemetKey) ? (isDateField = true) : (isDateField = false);

                      if (!enDictionary.hasOwnProperty(elemetKey)) {
                        return <></>
                      }

                      return (
                        <Grid xs={3} md={3} padding={1.5} key={i}>
                          <DetailItem key={`${elemetKey}_detail_item`} title={translate(elemetKey)} value={getValue(obj[elemetKey], elemetKey)} valueFontClass="font-size-14" titleFontClass="font-size-12" isDateInput={isDateField} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              ))}
            </>
          </Stack>
        );
      })}
    </MainSectionsWrapper>
  );
};

export default CollateralDetailsBuilder;
