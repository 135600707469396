import { Downgraded, useHookstate, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { productDetails } from "../../../../configs/mainStore";
import { productFormMapping } from "../../../../services/productApis";
import { ComponentMapper } from "./ComponentMapper";

export default ({ callback, values: addedItems }) => {
    const productMasterData: any = useState(productDetails);
    const [values, setValues]: any = React.useState([]);

    const setup: any = useHookstate({
        activeWF: true,
        WFtype: true,
        selections: [],
        loading: true,
        products: [],
        wfMap: {},
        newList: [],
        disabled: false
    });

    useEffect(() => {
        loadCurrentWorkflowMappings();
    }, []);

    const loadCurrentWorkflowMappings = async () => {
        try {
            setup.loading.set(true);
            const activeWorkflows = await productFormMapping();
            const productCatalog = activeWorkflows?.productCatalog;

            processList(productCatalog);
        } catch (error) { }
    }

    const processList = async (list: any = null) => {
        const to = setTimeout(() => {
            const map = {};
            const productsList: any = [];
            const items = (list).map(item => {
                const { id, productType, productSector, productScheme, isActive } = item;

                let _disabled = false;


                const getProductType =
                    productMasterData?.productTypesMap?.get()?.[productType]?.["TYPE_NAME"];
                const getProductSector =
                    productMasterData?.productSectorsMap?.get()?.[productSector]?.["TYPE_NAME"];
                const getProductScheme =
                    productMasterData?.productSchemesMap?.get()?.[productScheme]?.["TYPE_NAME"];

                const productName = `${getProductType} ${getProductSector} ${getProductScheme}`

                productsList.push(`${productType}-${productSector}-${productScheme}`);

                return {
                    id: `${productType}-${productSector}-${productScheme}`,
                    name: productName,
                    desc: ``,
                    active: isActive === 1 ? true : false,
                    disabled: _disabled
                };
            });

            setup.products.get().length === 0 && setup.products.set(items);


            setValues(items);
            setup.loading.set(false);
            setup.wfMap.set(map);
            clearTimeout(to);
        }, 500);
    }

    const addItemsFromToMapping = async () => {
        const list: any = [];
        const _selection = setup.selections.attach(Downgraded).get();

        for (const item of setup.products.get()) {
            if (_selection.includes(item.id)) {
                list.push(item);
            }
        }

        callback(list, setup.wfMap.get());
    }

    const handleSelection = async (status, data) => {
        setup.selections.set([data.id]);
    }

    if (setup.loading.get()) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Paper elevation={0}><CircularProgressComponent size={30} /></Paper></Stack>;
    }

    if (values.length === 0) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Paper elevation={0}><Typography color={"gray"}>No {setup.activeWF.get() ? 'Active' : 'Inactive'} items found.</Typography></Paper></Stack>;
    }

    return <ComponentMapper title={`${setup.activeWF.get() ? 'Active' : 'Inactive'} Workflows`} items={values} selections={setup.selections.get()} handleSelection={handleSelection} loading={setup.loading.get()} disabled={setup.disabled.get()}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {setup.selections.get().length > 0 && <ButtonComponent title={"Add"} onClick={addItemsFromToMapping}></ButtonComponent>}
            <ButtonComponent title={<CloseIcon />} onClick={closeGlobalModal}></ButtonComponent>
        </Stack>
    </ComponentMapper>;
};
