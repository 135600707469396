import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { masterDataDetails } from "../../../../../configs/mainStore";
import AppConfig from "../../../../../utility/AppConfig";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import { AmountCalculator } from "./InquiryOfObligations/Controller";
import { CreditCards } from "./InquiryOfObligations/CreditCards";
import { LoanLease } from "./InquiryOfObligations/LoanLease";
import { Overdraft } from "./InquiryOfObligations/Overdraft";
import { Pawning } from "./InquiryOfObligations/Pawning";

const InquiryOfObligations = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  let isCommercial = isProductOf() ? true : false;
  const masterData: any = useState(masterDataDetails);
  const pawningAndObligations = data["formData"]["inquiryOfObligationsData"];
  const calculator = new AmountCalculator();
  let label = "Inquiry of Obligations";

  try {
    const { primaryApplicant = undefined } = data?.formStructure;
    label = primaryApplicant?.[innerComponentData?.id]?.label;
  } catch {
  }

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Inquiry of Obligations"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          {!isMainContactDetails && (
            <>
              <Grid container style={{ backgroundColor: "#e6f0fa" }} className="align-center" mb={1.5}>
                <Grid item xs={12}>
                  <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">{label ? label : "Inquiry of Obligations"}</div>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            {pawningAndObligations?.liablityCategory !== AppConfig.config.noOtherObligations ? (
              <>
                <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
                <CreditCards data={pawningAndObligations} masterData={masterData} calculator={calculator} title="Credit Card"></CreditCards>
                <LoanLease data={pawningAndObligations} masterData={masterData} calculator={calculator} title="Loan Lease"></LoanLease>
                <Overdraft data={pawningAndObligations} masterData={masterData} calculator={calculator} title="Overdraft"></Overdraft>
                {!isCommercial && <Pawning data={pawningAndObligations} masterData={masterData} calculator={calculator} title="Pawning"></Pawning>}
              </>
            ) : (
              <ErrorMessageComponent headMessage={""} errorMessage={"No Other Obligations"} showImg={false} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InquiryOfObligations;
