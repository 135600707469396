import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { deleteMultipleInputItem } from "../../../services/multipleInputDelete";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { FormLabelComponent } from "../../InputHelperComponents/FormLabelComponent/FormLabelComponent";

interface MultipleInputWrapperProps {
  label: string;
  sourceArray: any;
  children: React.ReactNode;
  defaultDataObject: Record<string, any>;
  actionReset?: () => void;
  removeComponent?: boolean;
  isValidateNewFieldAdding?: boolean;
  growable?: boolean;
  specialOption?: boolean;
  showControlsAfterDelete?: boolean;
  onFieldAddingExecutor?: () => void;
  willAddNewItem?: Function;
}

export const MultipleInputWrapperV2: React.FC<MultipleInputWrapperProps> = ({
  label,
  sourceArray,
  children,
  defaultDataObject,
  actionReset,
  removeComponent = false,
  isValidateNewFieldAdding = false,
  growable = true,
  specialOption = false,
  showControlsAfterDelete = false,
  willAddNewItem,
  onFieldAddingExecutor
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  const onValuesReset = () => setOpenModal(true);


  const onNewFieldAdding = () => {

    sourceArray.current = [{ ...defaultDataObject }, ...sourceArray.current];
    if (onFieldAddingExecutor) onFieldAddingExecutor();

  };

  const proceedWithValidation = () => {
    if (!willAddNewItem) {
      onNewFieldAdding();
      return;
    }
    const approval = willAddNewItem();
    if (approval) {
      onNewFieldAdding();
    }
  }

  return (
    <>
      <Stack direction="row" mb={2} alignItems="center" >
        <FormLabelComponent>{label}</FormLabelComponent>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          ml={2}
        >
          <ButtonComponent
            iconbtn
            onClick={proceedWithValidation}
            disabled={isValidateNewFieldAdding || !growable}
          >
            <AddCircleOutlineIcon />
          </ButtonComponent>
          {/* <ButtonComponent iconbtn onClick={onValuesReset}>
            <RotateLeftIcon />
          </ButtonComponent> */}
        </Stack>
      </Stack>
      {children}
    </>
  );
};
interface MultipleInputItemProps {
  selectedItem: any;
  index?: any;
  deleteFunction?: (index: number | string, selectedItem: any) => void;
  onSave?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  isSaved?: boolean;
  valueSet?: any;
  refresh?: any;
}

export const MultipleInput: React.FC<MultipleInputItemProps> = ({ selectedItem, onDelete, onSave, isSaved, valueSet, index, refresh }: MultipleInputItemProps) => {

  const onValuesReset = () => {
    const updatedDetails = [...valueSet.current];
    updatedDetails[index]["purpose"] = null;
    updatedDetails[index]["officer"] = "";
    valueSet.current = updatedDetails;

    refresh(index + 1);
  };

  if (Boolean(selectedItem?.removedItem)) {
    return <></>;
  }


  return (
    <Stack direction={'column'}>
      {Boolean(onSave || onDelete) && <Stack direction={'row-reverse'} spacing={2} style={{ borderTop: '1px solid #BFBFBF' }} mt={2} pt={2}>

        {Boolean(onSave) &&
          <ButtonComponent size={'small'} title={'Save'} onClick={onSave} variant={'outlined'} />
        }
        {
          Boolean(!isSaved) &&
          <ButtonComponent size={'small'} onClick={onValuesReset} title={'Reset'} variant={'outlined'} color={'secondary'} />
        }

        {Boolean(onDelete) &&
          <ButtonComponent size={'small'} title={'Delete'} onClick={onDelete} variant={'outlined'} color={'error'} />
        }
      </Stack>}
    </Stack>
  );
};

export function DeleteButton({ selectedItem, index, enableDeleteOnFirstItem = true }) {
  if (selectedItem?.removedItem === true) {
    return <> </>;
  }

  return (
    <Box>
      {(index !== 0 || enableDeleteOnFirstItem === true) && (
        <ButtonComponent
          iconbtn
          children={<DeleteIcon />}
          onClick={() => {
            deleteMultipleInputItem(selectedItem);
          }}
        />
      )}
    </Box>
  );
}

export const OnDemandDeleteBtn = ({ onDeleteHandler }) => {
  return <Box>
    <ButtonComponent
      iconbtn
      children={<DeleteIcon />}
      onClick={onDeleteHandler}
    />
  </Box>
}
