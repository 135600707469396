import { Box, Paper, Tab, Tabs } from "@mui/material";
import React from "react";
import InnerSectionHeaderComponent from "../../../../InnerSectionHeaderComponent";
import ManagementAnalysis from "./EnvironmentAnalysis/EnvironmentAnalysis";
import EnvironmentAnalysis from "./ManagementAnalysis/ManagementAnalysis";
import MarketAnalysis from "./MarketAnalysis/MarketAnalysis";
import TechnicalAnalysis from "./TechnicalAnalysis/TechnicalAnalysis";

const AnalysisOfProposedLoans = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
    const [activeTab, setActiveTab] = React.useState(0)

    const tabsArray = [
        {
            id: 0,
            key: "marketing-analysis",
            name: "Marketing Analysis",
            component: MarketAnalysis,
        },
        {
            id: 1,
            key: "technical-analysis",
            name: "Technical Analysis",
            component: TechnicalAnalysis,
        },
        {
            id: 2,
            key: "management-analysis",
            name: "Management Analysis",
            component: ManagementAnalysis,
        },
        {
            id: 3,
            key: "environment-analysis",
            name: "Environment Analysis",
            component: EnvironmentAnalysis,
        }
    ]

    const handleChange = (event: React.SyntheticEvent, val: number) => {
        setActiveTab(val)
    }

    return (
        <div className="full-width">
            <InnerSectionHeaderComponent
                innerComponentData={innerComponentData}
                applicationId={applicationId}
                currentApplicationWfData={currentApplicationWfData}
            />
            <Paper>
                <React.Fragment>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        sx={{
                            textTransform: "none",
                            fontSize: "16px",
                            fontFamily: "Poppins, Medium",
                            borderRight: 1,
                            borderColor: "divider",
                            alignItems: "flex-start",
                        }}
                    >
                        {tabsArray.map((item, index) => {
                            return <Tab key={`${index}_Tab_${item?.id}`} label={item?.name} {...a11yProps(index, item?.id)} />
                        })}
                    </Tabs>

                    {tabsArray.map((item: any, index: number) => {
                        const props = {
                            title: item?.name,
                            data: item,
                            index: index,
                            key: `${index}_Tab_${item?.id}`,
                            applicationId,
                            currentApplicationWfData,
                            innerComponentData,
                            tabsToEdit
                        };

                        let Component: any = item?.component;
                        return (
                            <CustomTabPanel key={`${index}_CustomTabPanel_${item?.id}`} value={activeTab} index={index} >
                                <Component {...props} />
                            </CustomTabPanel>
                        );
                    })}
                </React.Fragment>
            </Paper>
        </div>
    )
}

function a11yProps(index: number, currentId: number) {
    return {
        id: `${currentId}-tab-${index}`,
        "aria-controls": `${currentId}-tabpanel-${index}`,
    };
}

function CustomTabPanel(props: any) {
    const { children, value, index, data } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${index + 1}-tab-${data?.id}`}>
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default AnalysisOfProposedLoans;