import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { treasuryBondDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { TresuryBond } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/TresuryBond/TresuryBond";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditTresuryBond = ({ data, applicationId, innerComponentData }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...treasuryBondDefault },
      mainTitle: "Treasury Bond", parentTitle: 'Paper Docs',
      parentDataKey: "paperDocs", dataKey: "treasuryBond"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={TresuryBond} />
  )
};
