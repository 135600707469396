import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { lifeInsuranceDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { LifeInsurance } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/LifeInsurance/LifeInsurance";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditLifeInsurance = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {

  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...lifeInsuranceDefault },
      mainTitle: "Life Insurance", parentTitle: 'Paper Docs',
      parentDataKey: "paperDocs", dataKey: "lifeInsurance"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={LifeInsurance} />
  )
};
