import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { pledgeDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { Pledge } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Movable/Pledge/Pledge";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditPledge = ({ data, applicationId, innerComponentData }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...pledgeDefault },
      mainTitle: "Pledge", parentTitle: 'Movable',
      parentDataKey: "movable", dataKey: "pledge"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={Pledge} />
  )
};
