import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { select_basic } from "../../../../../../../../../styles";
import { MultipleInput } from '../../../../../../../../InputComponents/MultipleInputFields/MultipleInputWrapperV2';
import { SelectComponent } from '../../../../../../../../InputComponents/SelectComponent/SelectComponent';
import { TextBoxComponent } from '../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import ValidateDatePicker from '../../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent';
import ValidateTimePicker from '../../../../../../../../InputComponents/ValidateTimePicker/ValidateTimePicker';
import { InputFieldsStack } from '../../../../../../../InputFieldsStack/InputFieldsStack';
import { InputGrid } from '../../../../../../../InputGrid/InputGrid';
import { CircularProgressComponent } from '../../../../../../../ProgressComponent/ProgressComponent';
import { MASTER_DATA_KEYS } from '../../../../../../../../../configs/constants/masterDataKeys';
import MasterDataProvider from '../../../../../../../../../utility/MasterDataProvider';

const InspecionOfficerForm = ({ detail, index, refresh, loadingIndex, getInspectionFormOfficerList, deleteMultipleInputItem, handleInspectionOfficerDetails, valueSet }) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const masterDataValueState = {
        purposeOfInspection: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.PURPOSE_OF_INSPECTION),
    };

    if (loadingIndex === index + 1) {
        return <Stack border={1} p={2} mb={2} borderColor={"#BFBFBF"} borderRadius={2} justifyContent={"center"} alignItems={"center"} gap={2}>
            <Typography variant={"caption"}>Generating Inspection ID</Typography>
            <CircularProgressComponent size={30} /></Stack>
    }

    const inspectionDate = detail?.date !== "" && detail?.date !== null ? moment(detail?.date) : "";
    const inspectionTime = detail?.time !== "" && detail?.time !== null ? moment(detail?.time) : "";
    const inspectionId = detail?.inspectionId ? detail?.inspectionId : detail.inspection ? detail.inspection : "";
    return (
        <Stack border={1} p={2} mb={2} borderColor={"#BFBFBF"} borderRadius={2}>
            <InputFieldsStack>
                <InputGrid visibility={1}>
                    <SelectComponent
                        label={"Inspection Officer"}
                        value={detail?.officer}
                        values={getInspectionFormOfficerList}
                        className={select_basic}
                        required={1}
                        onChange={(e) => {
                            detail["officer"] = e.target.value;
                            refresh(index + 1);

                        }}
                    />
                </InputGrid>
                <InputGrid visibility={1}>
                    <TextBoxComponent
                        label={"Inspection Id"}
                        value={inspectionId}
                        disabled={true}
                        required={1}
                    />
                </InputGrid>
                <InputGrid visibility={1}>
                    <SelectComponent
                        label={"Purpose of Inspection"}
                        value={detail?.purpose}
                        values={masterDataValueState.purposeOfInspection}
                        className={select_basic}
                        required={1}
                        onChange={(e) => {
                            detail["purpose"] = e.target.value;
                        }}
                    />
                </InputGrid>
                <InputGrid visibility={1}>
                    <ValidateDatePicker
                        label={"Inspection Date"}
                        required={1}
                        onChange={(date) => {
                            if (date) {
                                detail["date"] = date._d.toString() ? date._d.toString() : inspectionDate;
                                refresh(index + 1);
                            }
                        }}
                        value={inspectionDate}
                    />
                </InputGrid>
                <InputGrid visibility={1}>
                    <ValidateTimePicker
                        label="Inspection Time"
                        value={inspectionTime}
                        required={1}
                        onChange={(date) => {
                            if (date) {
                                detail["time"] = date._d.toString() ? date._d.toString() : inspectionTime;
                                refresh(index + 1);
                            }
                        }}
                    />
                </InputGrid>
            </InputFieldsStack>
            <MultipleInput key={`InpectionFormOfficerDetails_${index}`} selectedItem={detail} index={index} deleteFunction={() => deleteMultipleInputItem(detail)} onDelete={() => handleInspectionOfficerDetails("delete", detail)} onSave={() => handleInspectionOfficerDetails("save", detail)} isSaved={detail?.id ? true : false} valueSet={valueSet} refresh={refresh} >
            </MultipleInput>
        </Stack>
    )
}

export default InspecionOfficerForm