import moment from "moment";
import { generateUniqueId, getCopy } from "../../../../../../../../../utility/util";
import { InspectionProps } from "../OnSubmitInspections/SaveChangesInspection";

export const inspection = {
  borrowers: {},
  summery: {},
  business: {},
  participation: {},
  collateral: {},
  scoreCard: {},
  otherQuestions: {},
  sustainableEnvironmentalAndSafeguard: {},
};

export const inspectionState: InspectionProps = JSON.parse(JSON.stringify(inspection))
export const inspetionId = null;

export const sectionDataKey = {
  primaryApplicant: "borrowers",
  jointBorrower: "borrowers",
  summery: "summery",
  participation: "participation",
  collateral: "collateral",
  business: "business",
  sustainableEnvironmentalAndSafeguard: "sustainableEnvironmentalAndSafeguard"
};

export const businessAssertOtherDetails = {
  assetCategory: "",
  assertType: "",
  description: "",
  assertLiquidity: "",
  assertTimeFrame: "",
  forcedSaleValue: 0,
  marketValue: "",
  assertAge: "",
  assertMorgaged: 0,
  morgageInstitute: "",
  morgageValue: "",
  needtoDepreciate: 0,
  assertLifeTime: "",
  rateofdepreciation: "",
  depreciationExpense: "",
};

const businessAssertOtherDetailsState = JSON.parse(JSON.stringify(businessAssertOtherDetails));

export const businessAssertOtherDetailsYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
}

export const salesandPurchaseInformationofFastMovingItemsOtherdata = {
  item: "",
  buyingPrice: "",
  sellingPrice: "",
  quantity: "",
  frequency: "",
  profit: "",
  dataId: generateUniqueId(),
};

const salesandPurchaseInformationofFastMovingItemsOtherdataState = getCopy(salesandPurchaseInformationofFastMovingItemsOtherdata);

export const salesandPurchaseInformationofFastMovingItemsdata = {
  busineeCategory: "",
  profit: "",
  cashInTill: "",
  recommendedProfit: "",
  totalProfit: 0,
  dataId: generateUniqueId(),
  otherDetails: getCopy([salesandPurchaseInformationofFastMovingItemsOtherdataState]),
};

const salesandPurchaseInformationofFastMovingItemsdataState = getCopy(salesandPurchaseInformationofFastMovingItemsdata);

export const businessLiabilityExpensesDetails = {
  liabilityType: "",
  bankorInstitute: "",
  amountofTheFacility: "",
  grantedDate: "",
  obligationType: "",
  debtee: "",
  installmentFrequency: "",
  installmentAmount: "",
  numberofInstallmentsLeft: "",
  currentOutstanding: "",
  securityCategoryLoanLease: "",
  securityType: "",
  valuation: "",
  statusOfLoan: "",
  moreInfoOnRepaymentLoan: "",
  type: 1,
};

export const businessLiabilityExpensesOverDraft = {
  liabilityType: "",
  bankorInstitute: "",
  limitOfOverDraft: "",
  outStanding: "",
  expiryDate: "",
  securityCategoryOverDraft: "",
  numberofInstallmentsLeft: "",
  installmentAmount: "",
  type: 2,
};

const businessLiabilityExpensesState = JSON.parse(JSON.stringify(businessLiabilityExpensesDetails));

export const businessLoanLeaseDefault = {
  [Number(moment().year()) + 1]: [],
  [Number(moment().year())]: [],
  [Number(moment().year()) - 1]: [],
}

export const businessOverDraftDefault = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
}

export const businessIncome = {
  sourceOfIncome: "",
  frequency: "",
  income: "",
  annualBusinessIncome: "",
  cashBasisIncome: "",
  crediBasisIncome: "",
};

const businessIncomeState = JSON.parse(JSON.stringify(businessIncome));

export const businessIncomeYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
}

export const businessExpenses = {
  expenseType: "",
  frequency: "",
  expense: "",
  annualExpense: "",
  cashBasisExpense: "",
  crediBasisExpense: "",
};

const businessExpensesState = JSON.parse(JSON.stringify(businessExpenses));

export const businessExpensesYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
}

export const deductionsCostsOfGoods = {
  frequency: "",
  deductionsCOG: "",
  annualDeductionsCOG: "",
  cashBasisDeductionsCOG: "",
  crediBasisDeductionsCOG: "",
};

export const rawMaterialsCost = {
  description: "",
  ...deductionsCostsOfGoods,
}

const rawMaterialsCostState = getCopy(rawMaterialsCost);

export const otherDirectCost = {
  deductionsCOGType: "",
  ...deductionsCostsOfGoods,
}

const otherDirectCostState = getCopy(otherDirectCost);

const deductionsCostsOfGoodsState = JSON.parse(JSON.stringify(deductionsCostsOfGoods));

export const deductionsCostsOfGoodsYears = {
  [Number(moment().year()) + 1]: { rawMaterialsCost: [getCopy({ ...rawMaterialsCostState, dataId: generateUniqueId() })], otherDirectCost: [getCopy({ ...otherDirectCostState, dataId: generateUniqueId() })] },
  [Number(moment().year())]: { rawMaterialsCost: [getCopy({ ...rawMaterialsCostState, dataId: generateUniqueId() })], otherDirectCost: [getCopy({ ...otherDirectCostState, dataId: generateUniqueId() })] },
  [Number(moment().year()) - 1]: { rawMaterialsCost: [getCopy({ ...rawMaterialsCostState, dataId: generateUniqueId() })], otherDirectCost: [getCopy({ ...otherDirectCostState, dataId: generateUniqueId() })] },
}


export const stock = {
  type: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

export const stockSecondVersion = {
  [Number(moment().year()) + 1]: [getCopy({ ...stock, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...stock, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...stock, dataId: generateUniqueId() })],
}

const stockStateOldVersion = [JSON.parse(JSON.stringify(stock))];
const stockState = JSON.parse(JSON.stringify(stockSecondVersion));

export const debtors = {
  period: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

export const debtorsSecondVersion = {
  [Number(moment().year()) + 1]: [getCopy({ ...debtors, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...debtors, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...debtors, dataId: generateUniqueId() })],
}

const debtorsOldState = [JSON.parse(JSON.stringify(debtors))];
const debtorsState = JSON.parse(JSON.stringify(debtorsSecondVersion));

export const creditors = {
  period: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

export const creditorsSecondVersion = {
  [Number(moment().year()) + 1]: [getCopy({ ...creditors, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...creditors, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...creditors, dataId: generateUniqueId() })],
}

const creditorsOldState = [JSON.parse(JSON.stringify(creditors))];
const creditorsState = JSON.parse(JSON.stringify(creditorsSecondVersion));

export const defaultExtractEmployeType = { employeeType: "", permanent: "", contract: "" };

const extractEmployeTypeState = JSON.parse(JSON.stringify(defaultExtractEmployeType));

const employementDetails = {
  extractEmployeType: [extractEmployeTypeState],
  extractAgeRange: {
    noOfFemale: {
      belowFourty: 0,
      aboveFourty: 0,
      belowSixteen: 0,
      betweenSixteenAndTwentyFive: 0,
      betweenTwentySixAndThirtyFive: 0,
      betweenThirtySixAndFortyFive: 0,
      aboveFourtyFive: 0
    },
    noOfMale: {
      belowFourty: 0,
      aboveFourty: 0,
      belowSixteen: 0,
      betweenSixteenAndTwentyFive: 0,
      betweenTwentySixAndThirtyFive: 0,
      betweenThirtySixAndFortyFive: 0,
      aboveFourtyFive: 0
    },
  },
};

export const markettingArrangement = {
  isMarketingArrangementAvailable: 1,
  markettingArrangement: "",
  comment: "",
};

const businessInspectionDetails = {
  salesandPurchaseInformationofFastMovingItemsOtherdata: getCopy([salesandPurchaseInformationofFastMovingItemsdataState]),
  totaleBusinessAssertValue: "",
  totaleBusinessFixedAssertOtherDetails: getCopy(businessAssertOtherDetailsYears),
  totaleBusinessCurrentAssertOtherDetails: getCopy(businessAssertOtherDetailsYears),
  totaleBusinessLiabilityExpenses: "",
  businessLiabilityExpenses: getCopy(businessLoanLeaseDefault),
  totalBusinessIncome: "",
  businessIncome: getCopy(businessIncomeYears),
  totalBusinessExpense: "",
  businessExpenses: getCopy(businessExpensesYears),
  deductionsCostsOfGoods: getCopy(deductionsCostsOfGoodsYears),
  stock: stockState,
  debtors: debtorsState,
  creditors: creditorsState,
  employementDetails,
  markettingArrangement,
};

export const defaultBusinessState = JSON.parse(JSON.stringify(businessInspectionDetails));

const businessInspectionDetailsState = JSON.parse(JSON.stringify(businessInspectionDetails));

export const inspectionOfficerDetails = {
  inspectionOfficer: "",
  inspectionId: "",
  purposeOfInspection: "",
  date: null,
  time: null,
  recommendation: "",
};

const inspectionOfficerDetailsstr = JSON.parse(JSON.stringify(inspectionOfficerDetails));

export const inspectionOfficerDetailsState = [JSON.parse(JSON.stringify(inspectionOfficerDetailsstr))];

export const scoreCardQuestionDefault: any = {};

export const scoreCardSection = {
  // 0: "agricultureModelIndividual",
  // 1: "agricultureModelJointB",
  // 2: "agricultureModelBusiness",
  // 3: "manufactureTradeAndServiceIndividual",
  // 4: "manufactureTradeAndServiceJointB",
  // 5: "manufactureTradeAndServiceBusiness",
  0: "cultivationModel",
  1: "otherThancultivationModel",
};

export const inspectionFormData = { businessInspectionDetailsState };

export const yearArrayObject = {
  prevYear: Number(moment().year()) - 1,
  currentYear: Number(moment().year()),
  nextYear: Number(moment().year()) + 1
};

