import { Box, Stack } from "@mui/material"
import { useEffect } from "react"
import { useApplicationFormContext } from "../../../../../../providers/ApplicationFormManageProvider"
import { MainSectionsWrapper } from "../../../../../MainSectionsWrapper/MainSectionsWrapper"
import { GlobalTextChangeListener } from "../../../../Utilities/Utility"

const GrowableArrayAccordian = ({ itemArray, mainTitle = '', isectionExpand, dataKey, children, otherKey = '', itemIndex = 0 }) => {
    if (!itemArray || itemArray.length === 0) {
        return <></>
    }

    const onExpand = () => {
        isectionExpand.set((prev) => dataKey);
    }


    return <Stack className="data-box">
        <MainSectionsWrapper
            showStatus={false}
            noBtn={true}
            title={mainTitle}
            keyCode={undefined}
            onExpand={onExpand}
            isSectionExpand={isectionExpand.get() === dataKey}>
            <>
                {/* {otherKey && <OtherTitleBuilder key={`${otherKey}_${itemIndex}`} id={mainTitle.replaceAll(" ", "")} />} */}
                {(isectionExpand.get() === dataKey) && <Box pt={2}> {children}</Box>}
            </>
        </MainSectionsWrapper>
    </Stack>

}

export default GrowableArrayAccordian


const OtherTitleBuilder = ({ id }) => {
    const { formStore, otherParticipantDataState }: any = useApplicationFormContext();
    const { currentParticipantId, currentParticipantType } = otherParticipantDataState.get();

    let eventListnerFn = ({ initialsInFull = "", primaryLastName = "" }) => {
        const element = document.getElementById(id);
        if (element && element.id === id) {
            element.innerHTML = `${initialsInFull} ${primaryLastName}`.toUpperCase();
        }
    }

    useEffect(() => {
        GlobalTextChangeListener.subscribeEvent({ key: `${currentParticipantId + currentParticipantType}_personalDataChange`, option: { elementId: id } })
        window.addEventListener(`${currentParticipantId + currentParticipantType}_personalDataChange`, ({ detail }: any) => {
            let { initialsInFull = "", primaryLastName = "" } = detail;
            eventListnerFn({ initialsInFull, primaryLastName })
        });

        return () => {
            window.removeEventListener(`${currentParticipantId + currentParticipantType}_personalDataChange`, ({ detail }: any) => {
                let { initialsInFull = "", primaryLastName = "" } = detail;
                eventListnerFn({ initialsInFull, primaryLastName })
            })
        }
    }, [])

    return <Box className="mainSection__heading--bold" id={id} px={2} key={id}>Text</Box>
};
