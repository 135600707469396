import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { fdAgainstLoansDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { FdAgainstLoans } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/FDAgainstLoans/FDAgainstLoans";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditFdAgainstLoans = ({ data, applicationId, innerComponentData }) => {

  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...fdAgainstLoansDefault },
      mainTitle: "FD Against Loans", parentTitle: 'Paper Docs',
      parentDataKey: "paperDocs", dataKey: "fdAgainstLoans"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={FdAgainstLoans} />
  )
};


