import { Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { deleteInspectionOfficerDetails, generateInspectionId, getInspectionOfficerDetails, saveInspectionOfficerDetails } from "../../../../../../../../../services/apiCalls";
import CachingStorage from "../../../../../../../../../services/cacheStorage";
import { deleteMultipleInputItem } from "../../../../../../../../../services/multipleInputDelete";
import MasterDataProvider from "../../../../../../../../../utility/MasterDataProvider";
import { userName } from "../../../../../../../../../utility/helpers/creditFileUtility";
import { MultipleInputWrapperV2 } from "../../../../../../../../InputComponents/MultipleInputFields/MultipleInputWrapperV2";
import { FieldSectionWrapper } from "../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { closeGlobalModal, openGlobalModal } from "../../../../../../../GlobalModal/GlobalModal";
import { Toast } from "../../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";
import { SubSectionsWrapper } from "../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import InspecionOfficerForm from "./InspecionOfficerForm";

type UserData = { id: number | string, name: string };

const masterDataStore: any = MasterDataProvider.provideMasterData();

const InpectionFormOfficerDetails = ({ value, onChange, metaData, otherParams }) => {
  const [loadingIndex, setLoadingIndex]: any = React.useState(0);
  const valueSet: any = useRef([]);
  const defaultObject = {};

  const getInspectionFormOfficerList: UserData[] = React.useMemo(() => {
    let { users = [] } = CachingStorage.read(CachingStorage.Keys.INSPECTION_USER_LIST) || {};
    return users.reduce((filteredUsers, id) => {
      return [...filteredUsers, { id, name: `${id} - ${userName(id)}` }]
    }, [])
  }, [])

  useEffect(() => { fetchInspectionDetails() }, [])

  const fetchInspectionDetails = async () => {
    try {
      setLoadingIndex(-1);
      if (value.applicationId) {
        const { data: mappedData } = await getInspectionOfficerDetails(value.applicationId);
        const insPectionOfficerData = (mappedData ?? []).map((item: any) => {
          return {
            ...item,
            date: moment(item.inspectedAt),
            time: moment(item.inspectedAt),
          };
        });

        valueSet.current = insPectionOfficerData;
      }
    } catch (error) {

    } finally {
      setLoadingIndex(0);
    }
  }

  const refresh = (index: number) => {
    setLoadingIndex(index);
    const timeOut = setTimeout(() => {
      setLoadingIndex(0);
      clearTimeout(timeOut);
    }, 50);

  }

  const getInspectionId = async () => {
    try {
      if (value.applicationId) {
        setLoadingIndex(1);

        // check unsave data
        let unsavedIndex = valueSet.current.findIndex(item => !item.id && item.inspectionId);

        if (unsavedIndex !== -1) {
          const inspectedAt = getInspectedAt(valueSet.current[unsavedIndex]?.date, valueSet.current[unsavedIndex]?.time);
          const updatedItem = {
            ...valueSet.current[unsavedIndex],
            applicationId: value.applicationId,
            inspectedAt
          };
          const { id } = await saveInspectionOfficerDetails(updatedItem);
          valueSet.current[unsavedIndex] = { ...updatedItem, id };
        }


        const inspectionId = await generateInspectionId(value.applicationId);
        const [newItem, ...rest] = valueSet.current;
        const date = new Date();

        valueSet.current = [{ ...newItem, inspectionId, date: moment(date)?.["_d"], time: moment(date)?.["_d"] }, ...rest];
      }

    } catch (error) {

    } finally {
      setLoadingIndex(0);
    }
  }

  const getInspectedAt = (date: any, time: any) => {
    const InspectedDate = new Date(date);
    const inspectedTime = time ? moment(time).format("HH:mm:ss") : "";

    const year = InspectedDate.getFullYear();
    const month = String(InspectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(InspectedDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}T${inspectedTime}`;

  }

  const handleInspectionOfficerDetails = async (type: string, obj: any) => {

    const inspectedAt = getInspectedAt(obj.date, obj.time);
    const payload = {
      ...obj, inspectedAt, applicationId: value.applicationId || ""
    }

    if (type === "delete") {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "app-section-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "Inspection Officer details with inspection id: " + obj.inspectionId + " will be deleted!.",
          callBackOnAction: () => {
            deleteOfficerDetails(payload);
            closeGlobalModal();
          },
        },
      });
      return;
    }

    saveOfficerDetails(payload);
    closeGlobalModal();
  }

  const deleteOfficerDetails = async (payload) => {
    try {
      const res = await deleteInspectionOfficerDetails(payload);
      if (res) {

        fetchInspectionDetails();
      }
    } catch (error) {

    }
  }
  const saveOfficerDetails = async (payload) => {
    try {
      const res = await saveInspectionOfficerDetails(payload);
    } catch (error) {

    }
  }

  const willAddNewItem = () => {
    let exceptionalItem = null;
    const mandatoryKeys = ["officer", "purpose", "date", "time", "inspectionId"];

    for (const item of valueSet.current) {
      for (const key of mandatoryKeys) {
        if (!item.hasOwnProperty(key)) {
          exceptionalItem = item;
          break;
        }
      }
      if (exceptionalItem) {
        break;
      }
    }
    if (exceptionalItem) {
      Toast.error("Please fill all the mandatory fields!");
    }
    return exceptionalItem === null;
  }
  if (loadingIndex === -1) {
    return <Stack width={"100%"} height={"300px"} alignItems={"center"} justifyContent={"center"}>
      <CircularProgressComponent size={30} />
    </Stack>
  }


  return (
    <SubSectionsWrapper title={""}>
      <FieldSectionWrapper>
        {loadingIndex !== -1 && (
          <MultipleInputWrapperV2
            label="Inspection Officer Details"
            sourceArray={valueSet}
            defaultDataObject={defaultObject}
            onFieldAddingExecutor={getInspectionId}
            willAddNewItem={willAddNewItem}
          >
            {valueSet.current
              .filter((detail) => !detail["removedItem"])
              .map((detail: any, index) => (
                <InspecionOfficerForm
                  key={index}
                  detail={detail}
                  index={index}
                  refresh={refresh}
                  loadingIndex={loadingIndex}
                  getInspectionFormOfficerList={getInspectionFormOfficerList}
                  deleteMultipleInputItem={deleteMultipleInputItem}
                  handleInspectionOfficerDetails={handleInspectionOfficerDetails}
                  valueSet={valueSet}
                />
              ))}
          </MultipleInputWrapperV2>
        )}
      </FieldSectionWrapper>

    </SubSectionsWrapper>
  );
};

export default InpectionFormOfficerDetails;
