import { Downgraded, useState } from "@hookstate/core";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import { ErrorMessageComponent } from "../../components/OtherComponents/ErrorMessageComponent";
import { addToaster } from "../../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { appSetting } from "../../configs/mainStore";
import { userListStore } from "../../configs/stores/userStore";
import { getPrivilegeByUserGroup, getPrivilegeTypeList, updatePrivilegeByUserGroup } from "../../services/userPrivilegeService";
import { PrivilegeSectionView } from "./PrivilegeSectionView";

export const UserGroupPrivilegeViewEdit = ({ selectedUserGroupItem, setPage }) => {
  const { privilegeList }: any = useState(userListStore);
  const loggedFrom = "UserPrivilegesAndAssignments";
  const appSettings: any = useState(appSetting);
  const { t: translate } = useTranslation();

  const [privilegeTypeList, setPrivilegeTypeList] = React.useState<any>();
  const [groupPrivilegeDataList, setGroupPrivilegeDataList] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [privilegeTypeChanges, setPrivilegeTypeChanges] = React.useState<any>();
  const [itemUpdateUploading, setItemUpdateUploading] = React.useState(false);

  useEffect(() => {
    (async () => {
      let privilegeData = await getPrivilegeByUserGroup(selectedUserGroupItem?.id);

      if (privilegeData) {
        setGroupPrivilegeDataList(privilegeData);

        let data;
        if (Object.values(privilegeList.get()).length <= 0) {
          data = await getPrivilegeTypeList(appSettings.get().language);
        } else {
          const tempUserGroupList: any = privilegeList.attach(Downgraded).get();

          setPrivilegeTypeList(tempUserGroupList);
          setIsLoading(false);
        }

        if (data) {
          privilegeList.set(data);
          setPrivilegeTypeList(data);
          setIsLoading(false);
        } else {
          setErrorMessage("Something went Wrong!");
        }
      } else {
        setErrorMessage("Something went Wrong!");
      }
    })();
  }, [privilegeTypeList?.length, appSettings.get().language, groupPrivilegeDataList?.length]);

  const changePrivilegeArr = ({ priObj, value, list = false, groupPrivilegeDataList }) => {
    let tempPrivilegeTypeChanges = privilegeTypeChanges;

    if (list) {
      let tempGroupPrivilegeDataList = groupPrivilegeDataList;
      priObj?.map((item) => {
        tempPrivilegeTypeChanges = { ...tempPrivilegeTypeChanges, ...{ [item?.id]: value ? 1 : 0 } };
        tempGroupPrivilegeDataList[item?.id] = value ? 1 : 0;
      });

      setGroupPrivilegeDataList(tempGroupPrivilegeDataList);
    } else {
      tempPrivilegeTypeChanges = { ...privilegeTypeChanges, ...{ [priObj?.id]: value ? 1 : 0 } };
    }

    setPrivilegeTypeChanges(tempPrivilegeTypeChanges);
  };

  const savePrivilegeData = async () => {
    setItemUpdateUploading(true);

    const result = await updatePrivilegeByUserGroup(selectedUserGroupItem?.id, privilegeTypeChanges);

    if (result) {
      addToaster({
        status: "success",
        title: translate("updated"),
        message: "Privilege Updated",
      });
    }

    setItemUpdateUploading(false);
  };

  return (
    <>
      <Stack>
        <Stack direction={"row"} flex={1}>
          <Stack flex={1}>
            <Typography variant="body1">{selectedUserGroupItem?.name}</Typography>
            <Typography variant="caption">{selectedUserGroupItem?.desc} ({selectedUserGroupItem?.id})</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Stack>
              <ButtonComponent
                startIcon={<SaveIcon />}
                title={"SAVE CHANGES"}
                variant="contained"
                onClick={() => {
                  savePrivilegeData();
                }}
                disabled={false || itemUpdateUploading}
                loadingbtn={true}
                loading={itemUpdateUploading}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box m={2}>
        {isLoading ? errorMessage ? <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} /> : <CircularProgressComponent size={30} sx={{ top: "50%", left: "30%", position: "absolute", zIndex: 100 }} /> : <PrivilegeSectionView privilegeTypeList={privilegeTypeList} isLoading={isLoading} groupPrivilegeDataList={groupPrivilegeDataList} changePrivilegeArr={changePrivilegeArr} loggedFrom={loggedFrom} />}
      </Box>
      <Box m={2}>
        <Grid container mt={2} columns={24}>
          <Grid container item xs={24} sm={24} direction="column"></Grid>
        </Grid>
      </Box>
    </>
  );
};
