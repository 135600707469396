import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { masterDataDetails } from "../../../../../configs/mainStore";
import { figureFormat } from "../../../../../utility/fomatters";
import { jointBorrowerOwnershipList, ownershipList, securityDataOwnershipList } from "../../../../../utility/util";
import MandatoryFilterComponent from "../../../../DataValidationComponents/MandatoryFilterComponent";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";

const AssetsDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  let assetsData: any = data?.formData?.assetsData;
  let label = "Assets Details";

  try {
    const { primaryApplicant = undefined } = data?.formStructure;
    label = primaryApplicant?.[innerComponentData?.id]?.label;
  } catch {
  }

  return (
    <MandatoryFilterComponent
      items={assetsData}
      mandatory={["assetsCategory", "assetType"] as never[]}
      options={{
        isMainContactDetails: isMainContactDetails,
        formData: data?.formData,
        requestId: data?.requestId,
        applicationId: applicationId,
        title: label ? label : "Assets Details",
        isAppraisal: mainTabData?.isAppraisal,
        isRawVersion: isRawVersion,
        disableAccordingToTab: false,
        printMode: printMode,
      }}
    >
      <div className={` mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
        {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Asset Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}

        {!isMainContactDetails && (
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            {label ? label : "Asset Details"}
          </Grid>
        )}
        <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
          <Grid container>
            <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
              <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
              <Grid container p={1}>
                {assetsData?.length > 0 &&
                  assetsData
                    ?.filter((obj) => obj)
                    ?.filter((obj) => !obj.removedItem)
                    ?.map((asset, index) => {
                      const assetsCategory = masterData?.ASSETS_CATEGORY?.get()?.find((t) => t.id == asset?.assetsCategory);
                      const assetsType = masterData?.ASSET_TYPE?.get()?.find((t) => t.id == asset?.assetType);
                      const ownershipFilterList = isGuarantor ? securityDataOwnershipList : isJointBurrower ? jointBorrowerOwnershipList : ownershipList;
                      const ownerShip = ownershipFilterList?.find((t) => t.id == asset?.ownership);

                      return (
                        <Grid container m={1} p={1} spacing={1} className="data-box" key={index}>
                          <Grid container item spacing={1}>
                            <Grid item xs={3}>
                              <DetailItem title="Assets Category" value={assetsCategory?.name ? assetsCategory?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Assets Type" value={assetsType?.name ? assetsType?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Ownership" value={ownerShip?.name ? ownerShip?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Extent/ Registration No. / Other" value={asset.extentOrRegNoOrOther ? asset.extentOrRegNoOrOther : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Force Sales Value" value={asset.assetGrossValue ? figureFormat(asset.assetGrossValue) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Market Value" value={asset.assetMarketValue ? figureFormat(asset.assetMarketValue) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Mortgage Value" value={asset.mortgageValue ? figureFormat(asset.mortgageValue) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem title="Asset Description" value={asset.assetDescription ? asset.assetDescription : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MandatoryFilterComponent>
  );
};

export default AssetsDetails;
