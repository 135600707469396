import { useHookstate } from '@hookstate/core';
import { Grid } from '@mui/material';
import { calculateAnnualValue, incomeExpenseFrequency } from 'los-util';
import { useTranslation } from 'react-i18next';
import { masterDataDetails } from '../../../../../../configs/mainStore';
import { formatCurrency } from '../../../../../../services/currencyFormater';
import { LengthItemValidate } from '../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn';
import DetailItem from '../../DetailItem';

const ExpensesDataChild = ({ expenseDataList, expenseData }) => {
    const { t: translate } = useTranslation();
    const masterData: any = useHookstate(masterDataDetails);
    const presenceOfRegulatedFinSystem = masterData?.PRE_REG_FIN_SYS?.get()?.find((t) => t.id == expenseData?.presenceOfRegulatedFinSystem);
    const filteredExpenseDataList: any[] = LengthItemValidate.validatedArray(expenseDataList) || [];

    return (
        <Grid container pl={1} className="data-box">
            <Grid container p={1} spacing={1}>
                {filteredExpenseDataList?.length > 0 &&
                    filteredExpenseDataList?.filter((obj) => obj)
                        ?.filter((obj) => !obj.removedItem)?.map((expenseData, index) => {
                            const expenseType = masterData?.EXPENSES_TYPE?.get()?.find((t) => t.id == expenseData?.expenseType);
                            const expenseFrequency: any = incomeExpenseFrequency?.find((t) => t.id == expenseData?.expenseFrequency);
                            const frequencyName: any = expenseFrequency?.name ? translate(expenseFrequency.name) : "";
                            const annual = calculateAnnualValue(expenseData?.expenseAmount, expenseData.frequencyForMonth, expenseData.frequencyForAnnual);
                            return (
                                <Grid container p={1} key={index} className="data-box" item mt={1} spacing={1}>
                                    <Grid item xs={3}>
                                        <DetailItem title={translate("expensesType")} value={expenseType?.name ? expenseType.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DetailItem title={translate("expenseFrequency")} value={frequencyName ? frequencyName : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                    </Grid>
                                    {expenseData.frequencyForMonth && (
                                        <Grid item xs={3}>
                                            <DetailItem title={translate("frequencyForMonth")} value={expenseData.frequencyForMonth ? expenseData.frequencyForMonth : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                        </Grid>
                                    )}
                                    {expenseData.frequencyForAnnual && (
                                        <Grid item xs={3}>
                                            <DetailItem title={translate("frequencyForAnnual")} value={expenseData.frequencyForAnnual ? expenseData.frequencyForAnnual : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                        </Grid>
                                    )}

                                    <Grid item xs={3}>
                                        <DetailItem title={translate("expenses")} value={expenseData?.expenseAmount ? formatCurrency(expenseData?.expenseAmount) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DetailItem title="Annual Expenses" value={annual ? formatCurrency(annual) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                    </Grid>
                                </Grid>
                            );
                        })}
            </Grid>
            <Grid container p={1} spacing={1}>
                {presenceOfRegulatedFinSystem != undefined && (
                    <Grid item xs={6}>
                        <DetailItem title={translate("presenceOfRegulatedFinSystem")} value={presenceOfRegulatedFinSystem?.name ? presenceOfRegulatedFinSystem.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <DetailItem title={translate("numberOfDepends")} value={expenseData?.numberOfDepends ? expenseData?.numberOfDepends : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ExpensesDataChild