import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { propertyDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { Property } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Immovable/Property/Property";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditProperty = ({ data, applicationId, innerComponentData }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...propertyDefault },
      mainTitle: "Property", parentTitle: 'Immovable',
      parentDataKey: "immovable", dataKey: "property"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={Property} />
  )
};
