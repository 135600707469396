import { Downgraded, useState } from "@hookstate/core";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { creditData } from "../../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { getIsDisbursed } from "../../../../../../../../utility/helpers/getIsDisbursed";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent, FormLabelComponentResponsive } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { ApprovedBuildingplan, LegalOpinionObtainedSection, PropertyOrLandIsHavingBuildingSection, SpecialConditionOfApprovedBulidingPlanSection, TitleInsuranceSection } from "./PropertySubComponent";

export const Property = (props) => {
  const { t: translate } = useTranslation();
  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const masterDataStore: any = MasterDataProvider.provideMasterData();

  const lawyersData: any[] = [
    { id: 1, name: "Lawyer1" },
    { id: 2, name: "Lawyer2" },
  ];

  const MasterDateValueState = {
    typeofMortgageMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TYPE_OF_MORTGAGE),
    districtMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT),
    provinceMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.PROVINCE),
    propLocationCodetMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LOCATION_CODE),
    propLocalAuthorityMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LOCAL_AUTHORITY),
    taxTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE),
    landAndMeasureTypeData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LAND_MEASUREMENT_TYPE),
    propTypeofRoadMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TYPE_OF_ROAD),
    lawyersMasterData: lawyersData,
  };

  const {
    propValueOfSecurity,
    // propSecuredValue,
    propDescription,
    propTypeOfMortagage,
    propDetailsOfPrimary, //0
    propMortagageName,
    propLandValuationRate,
    propDateOfMortagage,
    propAddressOfMortgage,
    propDistric,
    propCity,
    propAddressOfTheLocationOfTheLandOrBuilding,
    propLocationCode,
    propSurveyPlanDate,
    propSuveryPlanNo,
    propLotNo,
    propDeedOrTitleCertificateNumber,
    propLocalAuthority,
    propLandMeasurementType,
    propExtentOfLand,
    propRegistrationNumber, //0
    propRegistratonDate, //0
    propBuildingValue, //0
    propLandValue, //0
    propRoadWidth,
    propTypeofRoad,
    propRemarks, //0
    propInsuranceValue,
    propMortgageDeedNumber,
    propDatePreparedMortgageDeed,
    propDateRegistrationMortgageDeed,
    propOfficeRegistrationMortgageDeed,
    propJurisdictionRegistrationMortgageDeed,
    propFinalRegisteredFolioNumber,
    propDateFinalRegisteredFolio,
    propIssuedDateTitleReport,
    proplawyerIssuedTitleReport,
    propTitleDeedNumber,
    propNameNotaryPreparedDeed,
    propDateNotaryPreparedDeed,
    propDateRegistrationDeed,
    propNameSurveyorMeasuredPlan,
    propDateRegisteredPlan,
    propDateIssueStreetLineNonVestingCertificate,
    propNameAuthorityCertifiedBuildingPlan,
    propDateCertifiedBuildingPlan,
    propDateValuation,
    propNamePreparedBOQ,
    propPositionOfficerPreparedBOQ,
    propDatePreparedBOQ,
    propProvince,
    propVillage,
    propGramNiladariDivision,
    propDivisionalSecretaries,
    propPradeshiyaSabhaLimists,
    propValuationOfficerName,
    propForceSaleValue,
    insuranceCompanyName,
    insurancePolicyNumber,
    insuredAmount,
    policyExpiryDate,
    policyNextRenewalDate,
    insurancePrimium,
    benificiaryName,
    dateCommencementInsurance,
  } = props.fields || {};

  return (
    <div className="full-width full-height">
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    Property
                    {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={propValueOfSecurity.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.propValueOfSecurity.get())}
                    onChange={(e) => props?.doc?.propValueOfSecurity.set(e.target.value)}
                    label={translate("Market Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propValueOfSecurity.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                {/* <InputGrid visibility={propSecuredValue.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.propSecuredValue.get())}
                    onChange={(e) => {
                      props?.doc?.propSecuredValue.set(e.target.value);
                    }}
                    label={translate("Secured Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propSecuredValue.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid> */}
                <InputGrid visibility={propDescription.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propDescription.get()}
                    onChange={(e) => {
                      props?.doc?.propDescription.set(e.target.value);
                    }}
                    label={translate("Description")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propDescription.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propForceSaleValue.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.propForceSaleValue.get())}
                    onChange={(e) => props?.doc?.propForceSaleValue.set(e.target.value)}
                    label={translate("Force Sale Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propForceSaleValue.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propInsuranceValue.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.propInsuranceValue.get())} onChange={(e) => props?.doc?.propInsuranceValue.set(e.target.value)} label={translate("Insuarance Value")} required={propInsuranceValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
                </InputGrid>
                <InputGrid visibility={propTypeOfMortagage.visible}>
                  <SelectComponent
                    defaultValue={undefined}
                    label={translate("Type of Mortagage")}
                    value={props?.doc?.propTypeOfMortagage.get()}
                    values={MasterDateValueState.typeofMortgageMasterData}
                    required={propTypeOfMortagage.mandatory === 1 ? true : false}
                    onChange={(e) => {
                      props?.doc?.propTypeOfMortagage.set(e.target.value);
                    }}
                    defaultPlaceholder={true}
                  />
                </InputGrid>
                <InputGrid visibility={propDetailsOfPrimary.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propDetailsOfPrimary.get()}
                    onChange={(e) => {
                      props?.doc?.propDetailsOfPrimary.set(e.target.value);
                    }}
                    label={translate("Details of Primary")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propDetailsOfPrimary.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propMortagageName.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propMortagageName.get()}
                    onChange={(e) => {
                      props?.doc?.propMortagageName.set(e.target.value);
                    }}
                    label={translate("Mortagage's Name")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propMortagageName.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateOfMortagage.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateOfMortagage.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateOfMortagage.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date Of Mortagage")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateOfMortagage.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propAddressOfMortgage.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propAddressOfMortgage.get()}
                    onChange={(e) => {
                      props?.doc?.propAddressOfMortgage.set(e.target.value);
                    }}
                    label={translate("Address Of Mortgage")}
                    onInput={OnDemandValueFormatter.any}
                    required={propAddressOfMortgage.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDistric.visible}>
                  <AutoCompleteProxyComponent
                    options={MasterDateValueState.districtMasterData}
                    fullWidth={true}
                    onChange={(e) => {
                      return props?.doc?.propDistric.set(e ? e.id : null);
                    }}
                    label={translate("District")}
                    value={props?.doc?.propDistric.get()}
                    cls="select_basic"
                    required={propDistric.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propProvince.visible}>
                  <AutoCompleteProxyComponent
                    options={MasterDateValueState.provinceMasterData}
                    fullWidth={true}
                    onChange={(e) => {
                      return props?.doc?.propProvince.set(e ? e.id : null);
                    }}
                    label={translate("Province")}
                    value={props?.doc?.propProvince.get()}
                    cls="select_basic"
                    required={propProvince.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propCity.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propCity.get()}
                    onChange={(e) => {
                      props?.doc?.propCity.set(e.target.value);
                    }}
                    label={translate("City")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propCity.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propMortgageDeedNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propMortgageDeedNumber.get()}
                    onChange={(e) => {
                      props?.doc?.propMortgageDeedNumber.set(e.target.value);
                    }}
                    label={translate("Mortgage Bond Number")}
                    onInput={OnDemandValueFormatter.alphaNumeric}
                    required={propMortgageDeedNumber.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDatePreparedMortgageDeed.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDatePreparedMortgageDeed.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDatePreparedMortgageDeed.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of prepared and Certified Mortgage Deed")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDatePreparedMortgageDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateRegistrationMortgageDeed.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateRegistrationMortgageDeed.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateRegistrationMortgageDeed.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of Registration of Mortgage Deed")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateRegistrationMortgageDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propOfficeRegistrationMortgageDeed.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propOfficeRegistrationMortgageDeed.get()}
                    onChange={(e) => {
                      props?.doc?.propOfficeRegistrationMortgageDeed.set(e.target.value);
                    }}
                    label={translate("Office Name of Registration of Mortgage Deed")}
                    onInput={OnDemandValueFormatter.any}
                    required={propOfficeRegistrationMortgageDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propJurisdictionRegistrationMortgageDeed.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propJurisdictionRegistrationMortgageDeed.get()}
                    onChange={(e) => {
                      props?.doc?.propJurisdictionRegistrationMortgageDeed.set(e.target.value);
                    }}
                    label={translate("Jurisdiction of Registration of Mortgage Deed")}
                    onInput={OnDemandValueFormatter.any}
                    required={propJurisdictionRegistrationMortgageDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propFinalRegisteredFolioNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propFinalRegisteredFolioNumber.get()}
                    onChange={(e) => {
                      props?.doc?.propFinalRegisteredFolioNumber.set(e.target.value);
                    }}
                    label={translate("Final Registered Folio Number")}
                    onInput={OnDemandValueFormatter.any}
                    required={propFinalRegisteredFolioNumber.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateFinalRegisteredFolio.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateFinalRegisteredFolio.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateFinalRegisteredFolio.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of Final Registered Folio")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateFinalRegisteredFolio.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propIssuedDateTitleReport.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propIssuedDateTitleReport.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propIssuedDateTitleReport.set(preparedDate);
                      }
                    }}
                    label={translate("Issued Date of Title Report")}
                    enableFuture={false}
                    disablePast={false}
                    required={propIssuedDateTitleReport.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={proplawyerIssuedTitleReport.visible}>
                  <TextBoxComponent label={translate("The lawyer who issued the Title Report")} value={props?.doc?.proplawyerIssuedTitleReport.get()} required={proplawyerIssuedTitleReport.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.proplawyerIssuedTitleReport.set(e.target.value)} onInput={OnDemandValueFormatter.alphabeticalSpaces} />
                </InputGrid>
                <InputGrid visibility={propAddressOfTheLocationOfTheLandOrBuilding.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propAddressOfTheLocationOfTheLandOrBuilding.get()}
                    onChange={(e) => {
                      props?.doc?.propAddressOfTheLocationOfTheLandOrBuilding.set(e.target.value);
                    }}
                    label={"Address of the Location of the Land/Building"}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propAddressOfTheLocationOfTheLandOrBuilding.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propLocationCode.visible}>
                  <TextBoxComponent
                    label={translate("Location Code")}
                    value={props?.doc?.propLocationCode.get()}
                    required={propLocationCode.mandatory === 1 ? true : false}
                    onChange={(e) => {
                      props?.doc?.propLocationCode.set(e.target.value);
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={propVillage.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propVillage.get()}
                    onChange={(e) => {
                      props?.doc?.propVillage.set(e.target.value);
                    }}
                    label={translate("Village")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propVillage.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propGramNiladariDivision.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propGramNiladariDivision.get()}
                    onChange={(e) => {
                      props?.doc?.propGramNiladariDivision.set(e.target.value);
                    }}
                    label={translate("Grama Niladari Division")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propGramNiladariDivision.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propDivisionalSecretaries.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propDivisionalSecretaries.get()}
                    onChange={(e) => {
                      props?.doc?.propDivisionalSecretaries.set(e.target.value);
                    }}
                    label={"Divisional Secretariat"}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propDivisionalSecretaries.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propPradeshiyaSabhaLimists.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propPradeshiyaSabhaLimists.get()}
                    onChange={(e) => {
                      props?.doc?.propPradeshiyaSabhaLimists.set(e.target.value);
                    }}
                    label={translate("Pradeshiya Sabha Limits")}
                    onInput={OnDemandValueFormatter.any}
                    required={propPradeshiyaSabhaLimists.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propSurveyPlanDate.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propSurveyPlanDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propSurveyPlanDate.set(preparedDate);
                      }
                    }}
                    label={`${translate("Survey Plan Date")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propSurveyPlanDate.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propSuveryPlanNo.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propSuveryPlanNo.get()}
                    onChange={(e) => {
                      props?.doc?.propSuveryPlanNo.set(e.target.value);
                    }}
                    label={translate("Suvery Plan  No")}
                    onInput={OnDemandValueFormatter.alphaNumeric}
                    required={propSuveryPlanNo.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propLotNo.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propLotNo.get()}
                    onChange={(e) => {
                      props?.doc?.propLotNo.set(e.target.value);
                    }}
                    label={translate("LOT No")}
                    onInput={OnDemandValueFormatter.alphaNumeric}
                    required={propLotNo.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDeedOrTitleCertificateNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propDeedOrTitleCertificateNumber.get()}
                    onChange={(e) => {
                      props?.doc?.propDeedOrTitleCertificateNumber.set(e.target.value);
                    }}
                    label={"Deed / Title Certificate Number"}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propDeedOrTitleCertificateNumber.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propLocalAuthority.visible}>
                  <TextBoxComponent
                    label={translate("Local Authority")}
                    value={props?.doc?.propLocalAuthority.get()}
                    required={propLocalAuthority.mandatory === 1 ? true : false}
                    onChange={(e) => {
                      props?.doc?.propLocalAuthority.set(e.target.value);
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={propTitleDeedNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propTitleDeedNumber.get()}
                    onChange={(e) => {
                      props?.doc?.propTitleDeedNumber.set(e.target.value);
                    }}
                    label={translate("Title Deed Number")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propTitleDeedNumber.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propNameNotaryPreparedDeed.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propNameNotaryPreparedDeed.get()}
                    onChange={(e) => {
                      props?.doc?.propNameNotaryPreparedDeed.set(e.target.value);
                    }}
                    label={"Name of Notary who prepared the Deed"}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propNameNotaryPreparedDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateRegistrationDeed.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateRegistrationDeed.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateRegistrationDeed.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of Registration of the Deed")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateRegistrationDeed.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propNameSurveyorMeasuredPlan.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propNameSurveyorMeasuredPlan.get()}
                    onChange={(e) => {
                      props?.doc?.propNameSurveyorMeasuredPlan.set(e.target.value);
                    }}
                    label={"Name of the Surveyor who measured and prepared the Plan"}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propNameSurveyorMeasuredPlan.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateRegisteredPlan.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateRegisteredPlan.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateRegisteredPlan.set(preparedDate);
                      }
                    }}
                    label={"Date of Registered the Plan (DD/MM/YYYY)"}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateRegisteredPlan.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateIssueStreetLineNonVestingCertificate.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateIssueStreetLineNonVestingCertificate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateIssueStreetLineNonVestingCertificate.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date Issue Street Line Non Vesting Certificate")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateIssueStreetLineNonVestingCertificate.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateNotaryPreparedDeed.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateNotaryPreparedDeed.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateNotaryPreparedDeed.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of Notary who prepared the Deed")} (DD/MM/YYYY)`}
                    enableFuture={true}
                    disablePast={false}
                    required={propDateNotaryPreparedDeed.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propLandMeasurementType.visible}>
                  <SelectComponent
                    defaultValue={undefined}
                    label={translate("Land Measurement Type")}
                    value={props?.doc?.propLandMeasurementType.get()}
                    values={MasterDateValueState.landAndMeasureTypeData}
                    required={propLandMeasurementType.mandatory === 1 ? true : false}
                    onChange={(e) => {
                      props?.doc?.propLandMeasurementType.set(e.target.value);
                    }}
                    defaultPlaceholder={true}
                  />
                </InputGrid>
                <InputGrid visibility={propExtentOfLand.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propExtentOfLand.get()}
                    onChange={(e) => {
                      props?.doc?.propExtentOfLand.set(e.target.value);
                    }}
                    label={translate("Extent Of Land")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propExtentOfLand.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <PropertyOrLandIsHavingBuildingSection props={props} />
              <InputFieldsStack>
                <InputGrid visibility={propLandValuationRate.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propLandValuationRate.get()}
                    onChange={(e) => {
                      props?.doc?.propLandValuationRate.set(e.target.value);
                    }}
                    label={translate("Land Valuation Rate")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propLandValuationRate.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propRegistrationNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propRegistrationNumber.get()}
                    onChange={(e) => {
                      props?.doc?.propRegistrationNumber.set(e.target.value);
                    }}
                    label={translate("Registration Number (Land Registry)")}
                    onInput={OnDemandValueFormatter.alphaNumeric}
                    required={propRegistrationNumber.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propRegistratonDate.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propRegistratonDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propRegistratonDate.set(preparedDate);
                      }
                    }}
                    label={`${translate("Registraton Date")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propRegistratonDate.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propBuildingValue.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.propBuildingValue.get())}
                    onChange={(e) => {
                      props?.doc?.propBuildingValue.set(e.target.value);
                    }}
                    label={translate("Building Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propBuildingValue.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <ApprovedBuildingplan props={props} />
                <SpecialConditionOfApprovedBulidingPlanSection props={props} />
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={propLandValue.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.propLandValue.get())}
                    onChange={(e) => {
                      props?.doc?.propLandValue.set(e.target.value);
                    }}
                    label={translate("Land Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propLandValue.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propRoadWidth.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propRoadWidth.get()}
                    onChange={(e) => {
                      props?.doc?.propRoadWidth.set(e.target.value);
                    }}
                    label={translate("Road Width")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={propRoadWidth.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propTypeofRoad.visible}>
                  <SelectComponent
                    defaultValue={undefined}
                    label={translate("Type Of Road")}
                    value={props?.doc?.propTypeofRoad.get()}
                    values={MasterDateValueState.propTypeofRoadMasterData}
                    required={propTypeofRoad.mandatory === 1 ? true : false}
                    onChange={(e) => {
                      props?.doc?.propTypeofRoad.set(e.target.value);
                    }}
                    defaultPlaceholder={true}
                    disabled={true}
                  />
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={propRemarks.visible} variant={VARIANT?.extraLongGrid}>
                  <TextBoxComponent
                    multiline
                    rows={5}
                    value={props?.doc?.propRemarks.get()}
                    onChange={(e) => {
                      props?.doc?.propRemarks.set(e.target.value);
                    }}
                    label={translate("Description of Title Deed / Property & Remarks")}
                    onInput={OnDemandValueFormatter.any}
                    required={propRemarks.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={propNameAuthorityCertifiedBuildingPlan.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propNameAuthorityCertifiedBuildingPlan.get()}
                    onChange={(e) => {
                      props?.doc?.propNameAuthorityCertifiedBuildingPlan.set(e.target.value);
                    }}
                    label={translate("Name Local Authority Certified Building Plan")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propNameAuthorityCertifiedBuildingPlan.mandatory === 1 ? true : false}
                    disabled={getIsDisbursed(null, disbursed)}
                  />
                </InputGrid>
                <InputGrid visibility={propDateCertifiedBuildingPlan.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateCertifiedBuildingPlan.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateCertifiedBuildingPlan.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date Certified Building Plan")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateCertifiedBuildingPlan.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propValuationOfficerName.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propValuationOfficerName.get()}
                    onChange={(e) => {
                      props?.doc?.propValuationOfficerName.set(e.target.value);
                    }}
                    label={translate("Name of the Valuation Officer")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={propValuationOfficerName.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propDateValuation.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDateValuation.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDateValuation.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date of Valuation")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDateValuation.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propNamePreparedBOQ.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propNamePreparedBOQ.get()}
                    onChange={(e) => {
                      props?.doc?.propNamePreparedBOQ.set(e.target.value);
                    }}
                    label={translate("Name Prepared BOQ")}
                    onInput={OnDemandValueFormatter.any}
                    required={propNamePreparedBOQ.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propPositionOfficerPreparedBOQ.visible}>
                  <TextBoxComponent
                    value={props?.doc?.propPositionOfficerPreparedBOQ.get()}
                    onChange={(e) => {
                      props?.doc?.propPositionOfficerPreparedBOQ.set(e.target.value);
                    }}
                    label={translate("Position Officer Prepared BOQ")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={propPositionOfficerPreparedBOQ.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={propDatePreparedBOQ.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.propDatePreparedBOQ.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.propDatePreparedBOQ.set(preparedDate);
                      }
                    }}
                    label={`${translate("Date Prepared BOQ")} (DD/MM/YYYY)`}
                    enableFuture={false}
                    disablePast={false}
                    required={propDatePreparedBOQ.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <LegalOpinionObtainedSection props={props} />
              <TitleInsuranceSection props={props} />
              <InputFieldsStack>
                <InputGrid visibility={1}>
                  <FormLabelComponentResponsive>Insurance Details</FormLabelComponentResponsive>
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={insuranceCompanyName.visible}>
                  <TextBoxComponent
                    value={props?.doc?.insuranceCompanyName.get()}
                    onChange={(e) => {
                      props?.doc?.insuranceCompanyName.set(e.target.value);
                    }}
                    label={translate("Insurance Company Name")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={insuranceCompanyName.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={insurancePolicyNumber.visible}>
                  <TextBoxComponent
                    value={props?.doc?.insurancePolicyNumber.get()}
                    onChange={(e) => {
                      props?.doc?.insurancePolicyNumber.set(e.target.value);
                    }}
                    label={translate("Insurance Policy Number")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={insurancePolicyNumber.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={insuredAmount.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.insuredAmount.get())}
                    onChange={(e) => {
                      props?.doc?.insuredAmount.set(e.target.value);
                    }}
                    label={translate("Insured Amount")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={insuredAmount.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={policyExpiryDate.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.policyExpiryDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.policyExpiryDate.set(preparedDate);
                      }
                    }}
                    label={translate("Policy Expiry Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={policyExpiryDate.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={policyNextRenewalDate.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.policyNextRenewalDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.policyNextRenewalDate.set(preparedDate);
                      }
                    }}
                    label={translate("Policy Next Renewal Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={policyNextRenewalDate.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={insurancePrimium.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.insurancePrimium.get())}
                    onChange={(e) => {
                      props?.doc?.insurancePrimium.set(e.target.value);
                    }}
                    label={translate("Insurance Primium")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={insurancePrimium.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={benificiaryName.visible}>
                  <TextBoxComponent
                    value={props?.doc?.benificiaryName.get()}
                    onChange={(e) => {
                      props?.doc?.benificiaryName.set(e.target.value);
                    }}
                    label={translate("Benificiary Name")}
                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                    required={benificiaryName.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={dateCommencementInsurance.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.dateCommencementInsurance.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.dateCommencementInsurance.set(preparedDate);
                      }
                    }}
                    label={translate("Date of commencement of insurance (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={dateCommencementInsurance.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={benificiaryName.visible}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={props?.doc?.coveredPolicy.get()} required={false} color="primary" onChange={(e) => props?.doc?.coveredPolicy.set(!props?.doc?.coveredPolicy.get())} />} label={<FormLabelComponent>Covered Policy</FormLabelComponent>} />
                  </FormGroup>
                </InputGrid>
              </InputFieldsStack>
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
