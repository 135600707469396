import { Box, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { disSelectButtonStyles, nextBtnStyles } from '../../../configs/constants/styleValues'
import { langDictionary } from '../../../configs/languageDictionaryEn'
import { StyledButton } from '../../InputComponents/ButtonComponent/StyledButton'
import { FormLabelComponent } from '../../InputHelperComponents/FormLabelComponent/FormLabelComponent'
import { RequiredFieldIdentifier } from '../../InputHelperComponents/RequiredFieldIdentifierComponent/RequiredFieldIdentifierComponent'

const ToggleButtonLabelComponent = ({ label, visibility, value, yesBtnOnClick, noBtnOnClick, mandatory }) => {
    const { t: translate } = useTranslation();

    if (!visibility) {
        return <></>;
    }

    return (
        <Grid visibility={visibility} flexDirection={"row"} mt={1}>
            <Box mb={3}>
                <FormLabelComponent>
                    {label}
                    {mandatory ? <RequiredFieldIdentifier /> : ""}
                </FormLabelComponent>
            </Box>
            <Stack direction="row" spacing={1}>
                <StyledButton
                    styles={value === 1 ? nextBtnStyles : disSelectButtonStyles}
                    title={translate(langDictionary?.yes)}
                    onClick={yesBtnOnClick} />

                <StyledButton
                    styles={value === 0 ? nextBtnStyles : disSelectButtonStyles}
                    title={translate(langDictionary?.no)}
                    onClick={noBtnOnClick}
                />
            </Stack>
        </Grid>
    )
}

export default ToggleButtonLabelComponent



export const RowToggleButtonLabelComponent = ({ label, visibility, value, yesBtnOnClick, noBtnOnClick, mandatory }) => {
    const { t: translate } = useTranslation();

    return (
        <Stack direction={'row'} alignItems={'center'} gap={4}>
            <Box>
                <FormLabelComponent>
                    {label}
                    {mandatory ? <RequiredFieldIdentifier /> : ""}
                </FormLabelComponent>
            </Box>
            <Stack direction="row" spacing={1}>
                <StyledButton
                    styles={value === 1 ? nextBtnStyles : disSelectButtonStyles}
                    title={translate(langDictionary?.yes)}
                    onClick={() => yesBtnOnClick(1)} />

                <StyledButton
                    styles={value === 0 ? nextBtnStyles : disSelectButtonStyles}
                    title={translate(langDictionary?.no)}
                    onClick={() => noBtnOnClick(0)}
                />
            </Stack>
        </Stack>
    )
}