import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { standardDateFormatter } from "los-util";
import moment from "moment";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../utility/fomatters";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import { AddressViewComponent } from "../AddressInformation";
import { RenderingHelper } from "./Components";
import { ContactDetails } from "./ContactDetails";

export const PreviousEmployeements = (props) => {
  const deprecatedDateFormatterToggle = moment(formattedDate("1/2/2024")).isAfter(props?.applicationLastUpdatedDate);
  const previousEmployments = props?.previousEmployeements || [];
  const masterDataStore = MasterDataProvider.provideMasterData();
  const masterData: any = useState(masterDataDetails);

  // Helper function to get typed data from master data
  const getTypedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);

  // Helper function to get employment status
  const getEmploymentStatus = (key) => [
    { id: 1, name: "Retired" },
    { id: 2, name: "Resigned" },
  ].find((t) => t.id === key);

  // Helper function to render title
  const renderTitle = (item: { employmentStatus?: number | string; employerName?: number | string; companyName?: number | string }) => {
    let title = "Previous Employment -";
    let companyName = "";

    let value = item?.employerName === 1 ? item?.companyName : item?.employerName === "OTHER" ? item?.companyName : item?.employerName;

    if (value) {
      if (value.toString().length > 3) {
        companyName = value.toString();
      } else {
        const companies = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies.find((c) => c?.id?.get() == value);
        companyName = company?.name?.get() ?? "";
      }
    }

    return `${title} ${companyName}`;
  };

  // Helper function to get employer name
  const getEmployerName = (obj: { employmentStatus?: number | string; employerName?: number | string; companyName?: number | string }) => {
    let value = obj?.employerName === 1 ? obj?.companyName : obj?.employerName === "OTHER" ? obj?.companyName : obj?.employerName;
    let companyName = "";

    if (value) {
      if (value.toString().length > 3) {
        companyName = typeof value === "string" ? value : value.toString();
      } else {
        const companies = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies.find((c) => Number(typeof c?.id?.get() === "string" ? c?.id?.get() : c?.id?.get().toString()) === Number(value));
        companyName = company?.name?.get() || "";
      }
    }

    return companyName;
  };

  // Helper function to get valid date
  const getValidDate = (date) => {
    if (deprecatedDateFormatterToggle) return standardDateFormatter(date);
    if (moment("10/07/2024").isAfter(props?.applicationLastUpdatedDate)) return moment(date, "MM/DD/YYYY").format("DD/MM/YYYY");
    return date;
  };

  return (
    <div className="full-width margin-xs-b">
      <Grid container>
        {previousEmployments
          .filter((obj) => obj && !obj.removedItem)
          .map((employment, index) => {
            const employmentType = getTypedData("EMPLOYMENT_TYPE", employment?.employmentType);
            const occupation = getTypedData("OCCUPATION", employment?.occupation);
            const employmentStatus = getEmploymentStatus(parseInt(employment?.employmentStatus));
            const natureOfEmployment = [
              { id: 1, name: "Contract" },
              { id: 2, name: "Apprenticeship" },
              { id: 3, name: "Traineeship" },
              { id: 4, name: "Employment on commission" },
              { id: 5, name: "Probation" },
            ].find((t) => t?.id === employment?.natureOfEmployment);
            const industryType = getTypedData("INDUSTRY_TYPE", employment?.industryType);

            return (
              <div key={index}>
                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                  {renderTitle(employment)}
                </Grid>
                <MandatoryFilterComponent items={previousEmployments} mandatory={["employmentType", "occupation", "employmentStatus", "industryType", "endDate", "startDate", "permenantEmployment", "permanentDate"]} options={{ title: "Current Employment" }}>
                  <div className="full-width">
                    <Grid container item p={1} spacing={1} className="data-box">
                      {RenderingHelper.addItem(employment, "employmentType", employmentType?.name)}
                      {RenderingHelper.addItem(employment, "occupation", occupation?.name)}
                      {RenderingHelper.addItem(employment, "designation")}
                      {RenderingHelper.addItem(employment, "startDate", employment?.startDate ? getValidDate(employment?.startDate) : "-", true)}
                      {RenderingHelper.addItem(employment, "endDate", employment?.endDate ? getValidDate(employment?.endDate) : "-", true)}
                      {RenderingHelper.addItem(employment, "companyName", !isNaN(employment?.employmentStatus) ? getEmployerName(employment) : "-")}
                      {RenderingHelper.addItem(employment, "employmentStatus", employmentStatus?.name)}
                      {employment?.permenantEmployment > 0 && employment?.permanentDate ? RenderingHelper.addItem(employment, "permanentDate", getValidDate(employment?.permanentDate)) : ""}
                      {employment?.permenantEmployment < 1 ? RenderingHelper.addItem(employment, "natureOfEmployment", natureOfEmployment?.name) : ""}
                      {RenderingHelper.addItem(employment, "employmentTimePeriod")}
                      {RenderingHelper.addItem(employment, "industryType", industryType?.name)}
                      <AddressViewComponent addressData={employment?.address} title="Address Information" viewType="tab" />
                    </Grid>
                    {employment?.contactTypes
                      ?.filter((obj) => obj && !obj.removedItem)
                      ?.map((type, index) => (
                        <ContactDetails contactDetails={type} index={index} nohead={true} isBusinessDetails={false} key={index} />
                      ))}
                  </div>
                </MandatoryFilterComponent>
              </div>
            );
          })}
      </Grid>
    </div>
  );
};
