export const enDictionary = {
  language: "Language",
  back: "Back",
  published: "Published",
  unknown: "Unknown",
  guarantor: "Guarantor",
  unpublished: "Unpublished",
  saveChanges: "Save Changes",
  updated: "Updated",
  updateFail: "Update Failed",
  jointBorrower: "Joint Borrower",
  description: "Description",
  reset: "Reset",
  name: "Name",
  error: "Error",
  cancel: "Cancel",
  next: "Next",
  male: "Male",
  date: "Date",
  time: "Time",
  female: "Female",
  details: "Details",
  amount: "Amount",
  addSecurity: "Add Security",
  amountLKR: "Amount (LKR)",
  type: "Type",
  sector: "Sector",
  scheme: "Scheme",
  branch: "Branch",
  status: "Status",
  applicationId: "Application ID",
  workflowStage: "Workflow Stage",
  customerType: "Customer Type",
  collateralType: "Collateral Type",
  submittedBy: "Submitted By",
  actionedBy: "Last Actioned By",
  actionedAt: "Last Actioned On",
  submittedAt: "Submitted At",

  submittedOn: "Submitted On",
  assignedBy: "Assignee",
  authModule: {
    login: "Login",
    loginSubTxt: "Enter username and password to continue",
    username: "Username",
    password: "Password",
    enterUsername: "Enter username",
    enterPassword: "Enter password",
    continue: "Continue",
    pleaseFill: "Please fill required fields!",
  },
  smsModule: {
    head: "SMS Templates",
    createSms: "Create SMS Template",
    form: {
      createSmsTitle: "Create New Placeholder",
      published: "Published",
      enName: "English Name",
      enDesc: "English Description",
      siName: "Sinhala Name",
      siDesc: "Sinhala Description",
      tmName: "Tamil Name",
      tmDesc: "Tamil Description",
      createBtn: "Add New Template",
      editSmsTitle: "Change SMS Template",
      editBtn: "Change Template",
      updated: "Template Updated",
      newAdded: "New Template Inserted",
    },
    placeholders: "Placeholders",
    message: "Message",
    placeholdersIns: "Select Your Preferred Placeholders",
    tmpUpdated: "Template Updated",
  },
  errorMsg: {
    required: "Required!",
    requiredAlert: "Fill Required Fields",
    updateError: "Update Error",
    someError: "Something went Wrong",
    notFoundTmp: "Template Id Not Found",
    noData: "No data available",
    emptyTmp: "Empty Template List",
    emptyTypeMappings: "Empty Type Sector Mapping List",
    emptySchemeMappings: "Empty Scheme Mapping List",
    noDataFound: "No Data Found",
    emptyApplicationsList: "Empty Application List",
    emptyLeadsList: "Empty Leads List",
    emptyCollaterlData: "Empty Collateral Data",
    emptyReferralList: "Empty Referral List",
    emptyTaskList: "Empty Task List",
    noInformationAvailable: "No Information Available",
  },
  questionMsg: {
    publicEmpLoanActCovered: "Is the customer covered by the Public Employee's Loan Act or any other Act?",
    legalWarrantAgainstCustomer: "Has there been any judgements, other legal warrants or court proceedings against the customer?",
    addJointBorrower: "Does the customer wants to include additional/Joint Borrowers ?",
    cashDeposited: "Consistently deposited cash for at least 90 days?",
    doesTheApplicantHasCloseRelation: "Does the Applicant has close relation who work at RDB ?",
  },
  createApplicationTitle: "Add New Application Form",
  SubmittedSuccessfully: "Submitted successfully",
  saved: "Saved",
  weSavedYourData: "We saved your data !",
  primaryApplicant: "Primary Applicant",
  joinBorrower: "Join Borrower",
  submitted: "Submitted",
  pendingSubmission: "Pending Submission",
  "JudgmentsOtherLegalWarrantsCourtProceedings": "Judgments, other legal warrants or court proceedings",

  pendingLeads: "Pending Lead",
  pendingApplications: "Pending Application",
  submittedApplications: "Submitted Application",

  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  bimonthly: "Bimonthly",
  trimester: "Trimester",
  biannual: "Biannual",
  yearly: "Yearly",
  annually: "Annually",
  quarterly: "Quarterly",
  creditDetails: "Credit Details",

  // presenceOfRegulatedFinSystem: 'Percentage of the regulated financial System',
  expensesHolder: "Expenses Holder",
  noOfDependents: "No of Dependents",
  search: "Search",
  clear: "Clear",

  //personal Data
  oldNic: "Old NIC",
  newNic: "New NIC",
  accountOpenDate: "Account Open Date",
  maximumBalance: "Maximum Balance",
  asAtDate: "As at Date",
  balanceAsAtDate: "Balance as at Date",
  averageDepositLastSixMonths: "Average Deposits of Last Six Months ",
  averageWithdrawalLastSixMonths: "Average Withdrawals of Last Six Months",
  monthlyDataEntryAfterDeposits: "Monthly Data Entry after Average Deposits of Last Six Months",
  monthlyDataEntryAfterWithdrawals: "Monthly Data Entry after Average Withdrawals of Last Six Months",
  relationshipWithRDB: "Relationship with RDB",
  taxDetails: "Tax Details",
  taxFileNumber: "Tax File Number ",
  office: "Office",
  indexNumber: "Index Number",
  yearOfAssessment: "Year of Assessment",
  // yearOfAssessment: 'Year',
  statutoryIncome: "Statutory Income",
  estimatedIncome: "Estimated Income",
  valueSubjectsToTax: "Value Subject to Tax",
  valuePayable: "Value Payable",
  taxPaid: "Tax Paid",
  analysisOfLoanRequirement: "Analysis of the Loan Requirement",
  analysisOfNeed: "Analysis of Need",
  risksConditionsAndGeneralFacts: "Risks, Conditions and General Facts",
  risks: "Risk Of the Proposed Facility And Actions To Be Taken To Minimize It",
  conditions: "Conditions",
  factsAndRecommendations: "General facts and Recommendation",
  bankInstName: "Bank/Institution Name",
  obligationType: "Obligation Type",
  debtee: "Debtee",
  loanAmount: "Loan Amount",
  grantedDate: "Granted Date",
  balance: "Balance",
  installment: "Installment",
  year: "Year",
  numberOfChecksReturns: "Number of Check Returns",
  valueOfReturnedCheques: "Value of Returned Cheques",
  inquiryOfObligations: "Inquiry of Obligations",
  obligations: "Obligations",
  chequeReturns: "Cheque Returns",
  bank: "Bank",
  loanScheme: "Loan Scheme",
  loanPurpose: "Loan Purpose",
  settledDate: "Settled Date",
  securityAvailability: "Security Availability",
  securityType: "Security Type",
  currentArrears: "Current Arrears",
  moreInfoAbtRepayment: "More Information on Repayment Loans",
  reportsPreviousBorrowings: "Reports on Previous Borrowings RDB or Other",
  yes: "Yes",
  no: "No",
  editApplicationTitle: "Edit Application",

  //self employed data
  businessStartDate: "Business Start Date",
  individualTaxNo: "Individual Tax Identification Number",
  industryType: "Industry Type",

  //security data
  // securityData: "Customer Security Details Identification",
  securityData: "Collaterals",

  //expenses
  frequency: "Frequency",
  expenseFrequency: "Expense Frequency",
  incomeFrequency: "Income Frequency",
  frequencyForMonth: "Frequency for Monthly Expenses",
  frequencyForAnnual: "Frequency for Annual Expenses",
  expenses: "Expenses",
  income: "Income",
  annualExpenses: "Annual Expenses",
  numberOfDepends: "Number of Financial Dependents",
  presenceOfRegulatedFinSystem: "Presence of the Regulated Financial System",
  totalAnnualExpenses: "Total Annual Expense",
  expensesType: "Expenses Type",

  //income
  addPIncome: "Add Personal Income",
  addBIncome: "Add Business Income",
  addBExpense: "Add Business Expense",
  addCExpense: "Add Cultivation Expense",
  addCIncome: "Add Cultivation Income",
  incomeDetails: "Income Details",
  incomeCategory: "Income Category",
  incomeHolder: "Income Holder",
  salaryAssignment: "Salary Assignment",
  incomeSector: "Income Sector",
  incomeSubSector: "Income Sub Sector",
  sourceOfIncome: "Source Of Income",
  salarySlipBasedOn: "Salary Slip Based On",
  basicSalary: "Salary/Pension Amount",
  fixedAllowance: "Fixed Allowance",
  grossIncome: "Gross Income",
  netIncome: "Net Income",
  annualIncome: "Annual Income",
  netAnnualIncome: "Net Annual Income ",
  incomeType: "Income Type",
  seasonalIncome: "Seasonal Income",
  seasonalExpense: "Seasonal Expense",
  netAnnualProfit: "Net Annual Profit",
  totalAnnualIncome: "Total Annual Income",
  totalAnnualExpense: "Total Annual Expense",
  removeBusinessIncome: "Remove Business Income",
  removeCultivationIncome: "Remove Cultivation Income",
  removePersonalIncome: "Remove Personal Income",
  incomeFrequencyForMonth: "Occurrences for Month",
  incomeFrequencyForAnnual: "Frequency for Annual",
  frequencyForMonthIncome: "Frequency for Monthly Income",
  frequencyForAnnualIncome: "Frequency for Annual Income",
  //obligations
  liabilityCategory: "Liability Category",
  liabilityType: "Liability Type",
  bankNameInstitute: "Bank Name / Institute",
  loanLeaseAmount: "Loan / Lease Amount",
  installmentFrequency: "Installment Frequency",
  installmentAmount: "Installment Amount",
  noOfInstallmentLeft: "No of Installment Left",
  currentOutstanding: "Current Outstanding",
  securityCategory: "Security Category",
  valuation: "Valuation",
  statusOfLoan: "Status of Loan",
  moreInfoOnRepaymentLoan: "More Information on Repayment Loans",
  totalAnnualLiabilityAmount: "Total Annual Liability Amount",
  typeOfCard: "Type of Card",
  limit: "Limit",
  originalAmount: "Original Amount",
  amountOutstanding: "Amount Outstanding",
  annualOutstanding: "Annual Outstanding",
  outStanding: "Outstanding",
  matureDate: "Mature Date",
  annualInterest: "Annual Interest",
  limitOfOverDraft: "Limit of Overdraft",
  expiryDate: "Expiry Date",
  ofTheLimit: "of the Limit",
  creditCards: "Credit Cards",
  pawning: "Pawning",
  overdraft: "Overdraft",
  loansLeases: "Loans / Leases",
  annualAmount: "Annual Amount",
  removeCreditCards: "Remove Credit Card Section",
  removeLoanLease: "Remove Loan Lease Section",
  removePawing: "Remove Pawing Section",
  removeOverDraft: "Remove Over Draft Section",
  // tax
  taxIdentificationNumber: "Individual Tax Identification No.",
  somethingWentWrongMandVal: "Something went wrong in validating mandatory",
  reminderHistory: "Reminder History",
  action: "Action",

  //SECURITY DETAILS
  paperDocs: "Paper Docs Details",
  loanProtection: "Loan Protection",
  lifeInsurance: "Life Insurance",
  shares: "Shares",
  treasuryBond: "Treasury Bond",
  warehouseReceipt: "Warehouse Receipt",
  fdAgainstLoans: "FD Against Loans",

  movableDetails: "Movable Details",
  vehicle: "Vehicle",
  pledge: "Pledge",
  stockAsGold: "Stock As Gold",
  machineryEquipment: "Machinery Equipment",

  immovable: "Immovable Details",
  property: "Property",
  immovableMachineryEquipement: "Immovable Machinery Equipment",

  lpInsuranceCompanyCode: "Insurance Company Code",
  lpIssuingAuthority: "Issuing Authority",
  lpInsuranceDetailsDescription: "Insurance Details Description",
  lpInsuranceAgreementPolicy: "Insurance Agreement Policy",
  lpPolicyNumber: "Policy Number",
  lpDocumentNumber: "Document Number",
  lpNoOfDocuments: "No Of Documents",
  lpFaceValue: "Face Value",
  lpDueDate: "Due Date",
  lpDescription: "Description",
  lpPolicyDate: "Policy Date",

  shareValueOfSecurity: "Value Of Security",
  shareSecuredValue: "Secured Value",
  shareDescription: "Description",
  shareCertificateNo: "Certificate No",
  shareIssuingDate: "Issuing Date",
  shareNumberOfShares: "Number Of Shares",
  shareNominalValueOfShare: "Nominal Value Of Share",
  shareMarketValueOfShare: "Market Value Of Share",

  fdValueOfSecurity: " Value Of Security",
  fdSecuredValue: " Secured Value",
  fdDescription: " Description",
  fdLoanDetailsAccountNumber: " Loan Details Account Number",
  fdCustomerNumber: " Customer Number",
  fdLoanDetailsAccountOpenedOn: "Loan Details Account Opened On",
  fdPurposeCode: " Purpose Code",
  fdFixedDepositAccountNumber: " Fixed Deposit Account Number",
  fdContractNumber: " Contract Number",
  fdDepositDate: " Deposit Date",
  fdCurrency: " Currency",
  fdPrincipalAmount: " Principal Amount",
  fdInterestAccrued: " Interest Accrued",
  fdMaturityDate: " Maturity Date",
  fdDepositInterestRateCbs: " Deposit Interest Rate CBS",
  fdDepositAmount: " Deposit Amount",
  fdMargin: " Margin",
  fdAmountAvailableForLoan: " Amount Available For Loan",
  fdTotalFdAmount: " Total FD Amount",
  fdTotalOfMaximumGrantingLadAmount: " Total Of Maximum Granting Lad Amount",
  fdSanctionedRefNumber: " Sanctioned Reference Number",
  fdSanctionedAmount: " Sanctioned Amount",
  fdSanctionedBy: " Sanctioned By",
  fdExpiryDate: " Expiry Date",
  fdDepositInterestRateFd: " Deposit Interest Rate FD",
  fdWeightedAverageDepositRate: " Weighted Average Deposit Rate",
  fdIncrementalInterestAboveTheDepositRate: " Incremental Interest Above The Deposit Rate",
  fdEffectiveInterestRate: " Effective Interest Rate",
  fdRemarksInterestDetails: " Remarks Interest Details",
  fdCreditAmount: " Credit Amount",
  fdSourceOfFundType: " Source Of Fund Type",
  fdTransactionCurrency: " Transaction Currency",
  fdTransactionAmount: " Transaction Amount",
  fdSavingAccountAvailableBalance: " Saving Account Available Balance",
  fdAmountByCash: " Amount By Cash",
  fdAmountByTransfer: " Amount By Transfer",
  fdAccountNumberSavings: " Account Number Savings",
  fdRemarksSettlementsDetails: " Remarks Settlements Details",
  fdGLAccessCode: " GL Access Code",
  fdLoanAutoRecoveryAccountNumber: "Recovery Account Number",
  fdEffectiveDate: " Effective Date",
  fdProductCode: " Product Code",
  fdAccountCurrency: " Account Currency",
  fdLoanAutoRecoveryAccountOpenedOn: "Loan Auto Recovery Account Opened On",
  fdRecoveryAccountNumber: " Recovery Account Number",
  autoInstallmentRecoveryRequired: " Auto Installment Recovery Required",
  fdAccountName: " Account Name",
  fdAmountAvaibleForLoan: "Amount Avaible For Loan",
  fdTenorFixedDeposit: " Tenor Fixed Deposit",
  actionAutoInstallmentRecoveryRequired: "Auto Installment Recovery Required",
  actionIsCash: "Cash",
  actionIsBankTransfer: "BankTransfer",
  fdSavingAccountAvaibleBalanace: "Saving Account Avaible Balanace",
  fdamountByTransfer: "Amount By Transfer",
  fdLoanAccountNumber: "Loan Account Number",
  fdSactionedRefNumber: "Sactioned Ref Number",
  fdSactionedBy: "Sactioned By",
  fdInterestAccured: "Interest Accured",
  fdAutRProductCode: "Auto Recovery ProductCode",

  liValueOfSecurity: "Value Of Security",
  liSecuredValue: "Secured Value",
  liDescription: "Description",
  liInsuranceCompanyCode: "Issuing Authority",
  liRegistrationDate: "Registration Date",
  liPolicyNumber: "Policy Number",
  liDocumentNumber: "Document Number",
  liNoOfDocuments: "Number of Documents",
  liFaceValue: "Face Value",
  liMarketValue: "Market Value",
  liDueDate: "Due Date",
  liSurrendableValue: "Surrendable Value",
  dateCommencementInsurance: "Commencement Insurance Date",
  liDateInsurancePolicyCertified: "Insurance Policy Certification Date",
  liDateConfirmationLetterInsurance: "Confirmation Letter Insurance Date",
  liDateConfirmationLetterAgeAccepted: "Confirmation Letter Age Accepted Date",
  liDateLetterSignedThirdParty: "Letter Signed by Third Party Date",
  liDateSignedLetterBeneficiaries: "Letter Signed by Beneficiaries Date",
  liDateCommencementInsurance: "Date Commencement Insurance",
  actionWhetherInsurancePolicySampledThirdParty: "Whether Insurance Policy Sampled Third Party",

  mmValueOfSecurity: "Value Of Security",
  mmSecuredValue: "Secured Value",
  mmDescription: "Description",
  mmMachineType: "Machine Type",
  mmChasisNumber: "Chasis Number",
  mmEngineNumber: "Engine Number",
  mmHPOrLienNotedOn: "HP or Lien Noted On",
  mmmMakeHpOrCc: "Make HP or CC",
  mmAddress: "Address",
  mmDistrict: "District",
  mmCity: "City",
  mmForceSaleValue: "Force Sale Value",
  mmManufacturerName: "Manufacturer Name",
  mmManufactureCode: "Manufacture Code",
  mmYearOfManufacture: "Year of Manufacture",
  mmRegistrationNumber: "Registration Number",
  mmExpiryDate: "Expiry Date",
  mmPurchasedYear: "Purchased Year",
  mmCondition: "Condition",
  mmSourceOfPower: "Source of Power",
  mmSupplierOrDealerName: "Supplier or Dealer Name",
  mmSupplierAddress: "Supplier Address",
  mmSuppliersDistrict: "Supplier's District",
  mmSuppliersCity: "Supplier's City",
  mmThirdPartyDealerCode: "Third-Party Dealer Code",
  mmInvoiceNumber: "Invoice Number",
  mmInvoiceDate: "Invoice Date",
  mmInvoicePurchaseValue: "Invoice Purchase Value",
  mmTaxType: "Tax Type",
  mmTaxNumber: "Tax Number",
  mmTaxAmount: "Tax Amount",
  duplicateKeyNumber: "Duplicate Key Number",
  mmLifespanNumberOfYears: "Lifespan Number of Years",
  mmLifespanNumberOfUnits: "Lifespan Number of Units",
  mmLifespanDepretiationRate: "Lifespan Depreciation Rate",
  natureOfWarrenty: "Nature of Warranty",
  dealersName: "Dealer's Name",
  dealersContactNumber: "Dealer's Contact Number",
  warrantyStartDate: "Warranty Start Date",
  warrantyEndDate: "Warranty End Date",
  insuranceCompanyName: "Insurance Company Name",
  insurancePolicyNumber: "Insurance Policy Number",
  insuredAmount: "Insured Amount",
  policyExpiryDate: "Policy Expiry Date",
  policyNextRenewalDate: "Policy Next Renewal Date",
  insurancePremium: "Insurance Premium",
  benificiaryName: "Beneficiary Name",
  mmMarketValue: "Market Value",
  mmCountryOfManufacture: "Country of Manufacture",
  mmUsedPeriodAfterPurchased: "Used Period After Purchased",
  mmMovablePropertyMortgageDeedNo: "Movable Property Mortgage Deed No",
  mmLawyerPreparedMortgageDeed: "The lawyer who issued the Title Report",
  mmDatePreparedMortgageDeed: "Date Prepared Mortgage Deed",
  mmDateRegistrationMortgageDeed: "Date Registration Mortgage Deed",
  mmOfficeNameRegistrationMortgageDeed: "Office Name Registration Mortgage Deed",
  mmFinalRegisteredFolioNumber: "Final Registered Folio Number",
  mmDateFinalRegisteredFolio: "Date Final Registered Folio",
  actionDuplicateKeyReceived: "Duplicate Key Received",
  actionWarrantyAvailable: "Warranty Available",
  actionInsuranceAvailable: "Insurance Available",
  mmModel: "Model",
  mmCapacity: "Capacity",

  pledgeValueOfSecurity: "Value Of Security",
  pledgeSecuredValue: "Secured Value",
  pledgeDescription: "Description",
  pledgeInsuaranceValue: "Insurance Value",
  pledgeLoanToValueRatio: "Loan To Value Ratio",
  pledgeOwnership: "Ownership",
  pledgeOwarehousep: "Warehouse Rented in Bank's/ Client Name",
  pledgeCropType: "Crop Type",
  pledgeWeightOfPledge: "Weight Of Pledge",
  pledgeTaxPeriod: "Tax Period",
  pledgeLeaseAgreementNumber: "Lease Agreement Number",
  pledgeDateOfLeaseAgreement: "Date Of Lease Agreement",
  pledgeCustomerType: "Customer Type",
  pledgeRegistrationNumber: "Registration Number",
  pledgeWarehouseAddress: "Warehouse Address",
  pledgeDistrict: "District",
  pledgeCity: "City",
  pledgeConditionOfTheWarehouse: "Condition Of The Warehouse",
  pledgeApproximateDistanceFromTheBranch: "Approximate Distance From The Branch",
  pledgeKeyNumber: "Key Number",
  pledgeDateBanksLockKey: "Date of under Banks Lock Key",
  pledgeStatementTemplate: "Statement Template",
  pledgeStatementFreq: "Statement Frequency",
  pledgeStockStatementValidUpToDate: "Stock Statement Valid UpTo Date",
  pledgeToBeSubmittedBeforeGraceDays: "To Be Submitted Before Grace Days",
  coveredPolicy: "Covered Policy",
  pledgeDateCommencementOfInsurance: "Date Commencement Of Insurance",
  pledgeValueOfStock: "Value Of Stock",
  pledgeAgreementNumber: "Agreement Number",
  pledgeDatePreparePledgeAgreement: "Date Prepare Pledge Agreement",
  pledgeNameLawyerPreparedPledgeAgreement: "Name Lawyer Prepared Pledge Agreement",
  pledgeInventoryNumber: "Inventory Number",
  pledgeDateOfInventory: "Date Of Inventory",
  pledgeCumulativeWeight: "Cumulative Weight",
  pledgeAccumulatedValue: "Accumulated Value",
  pledgeInventoryDetailsPricePerUnit: "Price Per Unit",
  pledgePretPricePerUnit: "Pre Price Per Unit",
  pledgePreQuantityOfUnitsTested: "Pre Quantity Of Units Tested",
  pledgePreTotalValueChecked: "Pre Total Value Checked",
  pledgePreStockQualityDetails: "Pre Stock Quality Details",
  pledgePostNameOfBankOfficer: "Post Name Of Bank Officer",
  pledgePostDateInspection: "Post Date Inspection",
  pledgePostPricePerUnit: "Post Price Per Unit",
  pledgePostStockQualityDetails: "Post Stock Quality Details",
  pledgeInspectoionDate: "Inspectoion Date",
  pledgeInspectionTime: "Inspection Time",
  pledgeQntyofUnitTested: "Quantity of Unit Tested",
  pledgeTiotValueChecked: "Total Value Checked",
  pledgeStockQualityDetais: "Stock Quality Details",

  actionWarehouseDetailsRequired: "Warehouse Details Required",
  actionStocksUnderBankLock: "Are stock under Bank's lock key",
  actionOwnershipOfWarehouses: "Whether the ownership of the warehouses belongs to the loan applicant ?",
  pledgeNameOfTheBankOfficer: "Name Of The Bank Officer",
  pledgeDateOfInspection: "Date Of Inspection",

  vhValueOfSecurity: "Value Of Security",
  vhSecuredValue: "Secured Value",
  vhDescription: "Description",
  vhVehicleCategory: "Vehicle Category",
  vhVehicleMake: "Vehicle Make",
  vhVehicleModel: "Vehicle Model",
  vhEngineNo: "Engine Number",
  vhChassisNo: "Chassis Number",
  vhMarketValue: "Market Value",
  vhForceSaleValue: "Force Sale Value",
  vhCReleasedDate: "CReleased Date",
  vhFuelType: "Fuel Type",
  vhIntendedUse: "Intended Use",
  vhRegistrationNumber: "Registration Number",
  vhYearOfRegistration: "Year Of Registration",
  vhValuerName: "Valuer Name",
  vhValuerRegNumber: "Valuer Registration Number",
  vhValuerDate: "The valuation date",
  vhManufacturerName: "Manufacturer Name",
  vhManufacturerCode: "Manufacturer Code",
  vhYearOfManufacture: "Year Of Manufacture",
  vhCountryOfOrigin: "Country Of Origin",
  vhYearOfVehiclePurchase: "Year Of Vehicle Purchase",
  vhSupplierOrDealerName: "Supplier or Dealer Name",
  vhSupplierAddress: "Supplier Address",
  vhThirdPartyDelaerCode: "Third-Party Dealer Code",
  vhInvoiceNumber: "Invoice Number",
  vhInvoiceDate: "Invoice Date",
  vhInvoiceOrPurchaseValue: "Invoice or Purchase Value",
  vhTaxType: "TaxType",
  vhTaxNumber: "Tax Number",
  vhTaxAmount: "Tax Amount",
  beneficiaryName: "Beneficiary Name",
  vhInsuranceValue: "Insurance Value",
  vhAssetClass: "Asset Class",
  vhUseAsset: "Use of the Asset",
  vhColour: "Color",
  vhNameValuationOfficer: "Valuation Officer Name",
  vhAddressValuationOfficer: "Valuation Officer Address",
  vhContactNumberValuationOfficer: "Valuation Officer Contact Number",
  vhContactNumberSupplier: "Supplier Contact Number",
  vhQuotationNumber: "Quotation Number",
  vhDateQuotation: "Date Quotation",
  vhValueQuotation: "Value Quotation",
  vhKeptAtAddress: "Kept At Address",
  actionBrandNewRecondition: "Brand New Recondition",
  actionSecondHand: "Second Hand",
  vhVehicleCatergory: "Vehicle Catergory",
  vhDateQuatation: "Date Quatation",
  vhRegitrationNumber: "RegitrationNumber",
  vhValueQuatation: "Value Quatation",

  sagValueOfSecurity: "Value Of Security",
  sagSecuredValue: "Secured Value",
  sagDescription: "Description",
  sagNoOfJewelsOrPieces: "Number of Jewels or Pieces",
  sagArticleType: "Article Type",
  sagDescriptionOfTheOrnamentsOrArticle: "Description of the Ornaments or Article",
  sagKaratageOfArticle: "Karatage Of Article",
  sagGrossWeight: "Gross Weight",
  sagNetWeight: "Net Weight",
  sagMarketRatePerGram: "Market Rate Per Gram",
  sagMarketValue: "Market Value",
  sagPermissibleRatePerGram: "Permissible Rate Per Gram",
  sagPermissibleValue: "Permissible Value",

  propValueOfSecurity: "Value Of Security",
  propSecuredValue: "Secured Value",
  propDescription: "Description",
  propTypeOfMortgage: "Type of Mortgage",
  propDetailsOfPrimary: "Details of Primary",
  propMortgageName: "Mortgage Name",
  propDateOfMortgage: "Date of Mortgage",
  propAddressOfMortgage: "Address of Mortgage",
  propDistrict: "District",
  propCity: "City",
  propAddressOfTheLocationOfTheLandOrBuilding: "Address of the Location of the Land or Building",
  propLocationCode: "Location Code",
  propSurveyPlanDate: "Survey Plan Date",
  propSurveyPlanNo: "Survey Plan Number",
  propLotNo: "Lot Number",
  propDeedOrTitleCertificateNumber: "Deed or Title Certificate Number",
  propLocalAuthority: "Local Authority",
  propLandMeasurementType: "Land Measurement Type",
  propExtentOfLand: "Extent of Land",
  propBuildUpAreaOfBuildings: "Build-up Area of Buildings",
  propLandValuationRate: "Land Valuation Rate",
  propRegistrationNumber: "Registration Number",
  propRegistratonDate: "Registration Date",
  propBuildingValue: "Building Value",
  propLandValue: "Land Value",
  propRoadWidth: "Road Width",
  propTypeofRoad: "Type of Road",
  propRemarks: "Remarks",
  propObtainedDate: "Obtained Date",
  propProvince: "Province",
  propVillage: "Village",
  propGramNiladariDivision: "Gram Niladari Division",
  propDivisionalSecretaries: "Divisional Secretaries",
  propPradeshiyaSabhaLimits: "Pradeshiya Sabha Limits",
  propValuationOfficerName: "Valuation Officer Name",
  propMortgageDeedNumber: "Mortgage Deed Number",
  propDatePreparedMortgageDeed: "Date of prepared and Certified Mortgage Deed",
  propDateRegistrationMortgageDeed: "Date Registration Mortgage Deed",
  propOfficeRegistrationMortgageDeed: "Office Name of Registration of Mortgage Deed",
  propJurisdictionRegistrationMortgageDeed: "Jurisdiction of Registration of Mortgage Deed",
  propFinalRegisteredFolioNumber: "Final Registered Folio Number",
  propDateFinalRegisteredFolio: "Date Final Registered Folio",
  propIssuedDateTitleReport: "Issued Date Title Report",
  propLawyerIssuedTitleReport: "Lawyer Issued Title Report",
  propTitleDeedNumber: "Title Deed Number",
  propNameNotaryPreparedDeed: "Name Notary Prepared Deed",
  propDateNotaryPreparedDeed: "Date Notary Prepared Deed",
  propDateRegistrationDeed: "Date Registration Deed",
  propNameSurveyorMeasuredPlan: "Name Surveyor Measured Plan",
  propDateRegisteredPlan: "Date Registered Plan",
  propDateIssueStreetLineNonVestingCertificate: "Date Issue Street Line Non-Vesting Certificate",
  propNameAuthorityCertifiedBuildingPlan: "Name Authority Certified Building Plan",
  propDateCertifiedBuildingPlan: "Date Certified Building Plan",
  propDateValuation: "Date Valuation",
  propNamePreparedBOQ: "Name Prepared BOQ",
  propPositionOfficerPreparedBOQ: "Position Officer Prepared BOQ",
  propDatePreparedBOQ: "Date Prepared BOQ",
  propertyOrLandIsHavingBuilding: "Property Or Land Is Having Building",
  approvedBuildingPlan: "Approved Building Plan",
  specialConditionOfApprovedBuildingPlan: "special Condition Of Approved Building Plan",
  legalOpinionObtained: "Legal Opinion Obtained",
  titleInsuranceRequired: "Insured Value",
  actionPropertyOrLandIsHavingBuilding: "Action Property Or Land Is Having Building",
  actionApprovedBuildingPlan: "Action Approved Building Plan",
  actionSpecialConditionOfApprovedBuildingPlan: "Action Special Condition Of Approved Building Plan",
  actionLegalOpinionObtained: "Action Legal Opinion Obtained",
  proplawyerIssuedTitleReport: "The lawyer who issues the title report",
  propForceSaleValue: "Force Sale Value",
  propMortagageName: "MortagageName",
  propDateOfMortagage: "Date Of Mortagage",
  propSuveryPlanNo: "Suvery Plan No",
  actionTitleInsurance: "Title Insurance",
  titleInsuranceCompanyName: "Title Insurance Company Name",
  titleInsurancePolicyNumber: "Title Insurance Policy Number",
  titleInsuredAmount: "Title Insured Amount",
  insurancePrimium: "Insurance Premium",

  immValueOfSecurity: "Value Of Security",
  immSecuredValue: "Secured Value",
  immDescription: "Description",
  immMachineType: "Machine Type",
  immChasisNumber: "Chassis Number",
  immEngineNumber: "Engine Number",
  immHPOrLienNotedOn: "HP or Lien Noted On",
  immMakeHpOrCc: "Make HP or CC",
  immAddress: "Address",
  immDistrict: "District",
  immCity: "City",
  immManufacturerName: "Manufacturer Name",
  immManufactureCode: "Manufacture Code",
  immYearOfManufacture: "Year of Manufacture",
  immRegistrationNumber: "Registration Number",
  immExpiryDate: "Expiry Date",
  immForceSaleValue: "Force Sale Value",
  immLifespanDepretiationRate: "Lifespan Depreciation Rate",
  immPurchasedYear: "Purchased Year",
  immCondition: "Condition",
  immSourceOfPower: "Source of Power",
  immSupplierOrDealerName: "Supplier or Dealer Name",
  immSupplierAddress: "Supplier Address",
  immSuppliersDistrict: "Supplier's District",
  immSuppliersCity: "Supplier's City",
  immThirdPartyDealerCode: "Third Party Dealer Code",
  immInvoiceNumber: "Invoice Number",
  immInvoiceDate: "Invoice Date",
  immInvoicePurchaseValue: "Invoice Purchase Value",
  immTaxType: "Tax Type",
  immTaxNumber: "Tax Number",
  immTaxAmount: "Tax Amount",
  immLifespanNumberOfYears: "Lifespan Number of Years",
  immLifespanNumberOfUnits: "Lifespan Number of Units",
  immLifespanDepreciationRate: "Lifespan Depreciation Rate",
  natureWarranty: "Nature of Warranty",
  immMarketValue: "Market Value",
  immCountryOfManufacture: "Country of Manufacture",
  immUsedPeriodAfterPurchased: "Used Period After Purchased",
  immMovablePropertyMortgageDeedNo: "Movable Property Mortgage Deed Number",
  immLawyerPreparedMortgageDeed: "The lawyer who prepared Movable property Mortgage Deed",
  immDatePreparedMortgageDeed: "Date Prepared Mortgage Deed",
  immDateRegistrationMortgageDeed: "Date Registration Mortgage Deed",
  immOfficeNameRegistrationMortgageDeed: "Office Name Registration Mortgage Deed",
  immFinalRegisteredFolioNumber: "Final Registered Folio Number",
  immCapacity: "Capacity",
  immModel: "Model",
  immDateFinalRegisteredFolio: "Date Final Registered Folio",
  propTypeOfMortagage: "Type Of Mortagage",
  propDistric: "District",
  specialConditionOfApprovedBulidingPlan: "Special Condition Of Approved Buliding Plan",
  actionSpecialConditionOfApprovedBulidingPlan: "Action Special Condition Of Approved Buliding Plan",

  immTitleInsurance: "Title Insurance",

  whrValueOfSecurity: "Value Of Security",
  whrSecuredValue: "Secured Value",
  whrDescription: "Description",
  whrReceiptNo: "Receipt No",
  whrLocation: "Location",
  whrCropDepositorName: "Crop Depositor Name",
  whrNic: "New NIC",
  whrNicOld: "Old NIC",
  isNew: false,
  whrAddress: "Address",
  whrGrainDepositedInWarehouse: "Grain Deposited In Warehouse",
  whrDepositedDate: "Deposited Date",
  whrPhoneNumber: "Phone Number",
  whrQuantity: "Quantity",
  whrNoOfBags: "No Of Bags",
  mmMakeHpOrCc: "Make HP or CC",

  primaryApplicantInspection: "Primary Applicant Inspections",
  inspectionParticipation: "Inspection Participation",
  InspectionDetails: "Inspection Details",
  collateralInspections: "Collateral Inspections",
  businessInspection: "Business Inspection",
  otherQuestion: "Other Question",
  scoreCardQuestion: {
    agricultureModel: {
      individual: "Agriculture Model - Individual",
      jointB: "Agriculture Model - Joint Borrowers",
      business: "Agriculture Model - Business",
    },
    manufactureTradeAndService: {
      individual: "Manufacturing, Trade and Services (Business Loans) - Individual Model",
      jointB: "Manufacturing, Trade and Services (Business Loans) - Joint Borrowers",
      business: "Manufacturing, Trade and Services (Business Loans) - Business Model",
    },
    cultivationModal: "Micro Loan - Cultivation Modal",
    otherThanCultivationModal: "Micro Loan - Other Than Cultivation Modal",
  },

  proposed: "Proposed",
  proposedLoanAmount: "Proposed Loan Amount",
  interestRate: "Interest Rate",
  loanInstallmentAsAPercentageOfNetProfit: "Loan installment as a percentage of net profit",
  comments: "Comments",
  revenueIncome: "Revenue / Income",
  grossProfit: "Gross Profit",
  totalExpenses: "Total Expenses",
  netExpenses: "Total Expenses",
  netProfitBeforeTax: "Net Profit Before Tax",
  netAfterDepriciation: "Net Profit After Interest and Depreciation",
  netProfitBeforeInterestDepreciation: "Net Profit Before Interest and Depreciation",
  netProfitAfterTax: "Net Profit After Tax",
  doesTheApplicantHasEnoughFinancialInformation: "Does the applicant has enough financial information",
  profitAndLossAccounts: "Profit and Loss Accounts Data",
  fixedAssets: "Fixed Assets",
  debtors: "Debtors",
  stocks: "Stocks",
  currentAsset: "Current Asset (Without Debtors & Stocks",
  otherAssets: "Other Assets",
  totalAssets: "TotalAssets",
  tradeCreditors: "Trade Creditors",
  bankLoanLeaseOds: "Bank Loans/Lease/ODs",
  otherLiabilities: "Other Liabilities",
  totalLiabilities: "Total Liabilities",
  equity: "Equity",
  reductions: "Reductions",
  employeeExpenses: "Employee Expenses",
  professionalServiceExpense: "Professional Service Expense",
  bankingFinanceExpenses: "Banking and Finance Expenses",
  generalBusinessExpenses: "General Business Expenses",
  vehicleExpenses: "Vehicle Expenses",
  taxes: "Taxes",
  deductionAndCostGoods: "Deduction And Cost Goods",
  employeeAndLabourExpenses: "Employee And Labour Expenses",
  bankingAndFinanceExpences: "Banking And Finance Expences",
  gerneralBusinessExpenses: "Gerneral Business Expenses",
  tax: "Taxes",
  depreciation: "Depreciation",
  interestExpenses: "Interest Expenses",
  financialPositionOfTheCompany: "Financial Position Of The Company",
  balanceSheet: "Balance Sheet",
  forecastedCashFlow: "Forecasted Cash Flow",
  abilityToRepayTheProposedLoan: "Ability To Repay The Proposed Loan",
  salesInformation: "Sales Information",
  purchaseInformation: "Purchase Information",
  salesandPurchaseInformation: "Sales and purchase information",
  sustainableEnvironmentalAndSafeguard: "Sustainable, Environmental and Safeguard",
  "primary-applicant-details": "Primary Identification Details",
  "credit-details": "Credit Details",
  "address-details": "Address Details",
  "contact-details": "Contact Details",
  "education-details": "Education Details",
  "employment-details": "Employment Details",
  "business-details": "Business Details",
  "income-details": "Income Details",
  "expenses-details": "Income Details",
  "tax-details": "Tax Details",
  "inquiry-of-obligations": "Inquiry of Obligations",
  "life-insurance-details": "Life Insurance Details",
  "bank-details": "Bank Details",
  "assets-details": "Asset Details",
  "joint-borrower-details": "JointBorrower Details",
  "security-details": "Security Details",
  "referralAssignee": "Referral Assignee",
  "exisistingFacilty": "Exisisting Facilty",
  "ifContinued": "If Continued",
  rdRDesignation: "Designation",
};
