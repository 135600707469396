import { Downgraded, useHookstate } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { BasicDataProps, BuildComponentProps } from "../../../../../../configs/interfaces";
import { creditData } from "../../../../../../configs/mainStore";
import { saveAppFormDataJson } from "../../../../../../services/apiCalls";
import CachingStorage from "../../../../../../services/cacheStorage";
import { resetInputItems } from "../../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { generateUniqueId, getCopy } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import GrowableArrayAccordian from "../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/CollateralDetailsArrayAccordian";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";
import { Toast } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "../collateral-apis/CollateralApplicationFormGetter";
import { collateralSectionDeletion } from "../collateral-utils/Utility";
import HeaderCollateral from "../helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "../validations/validations";

interface BuildCollateralDataProps {
    basicData: BasicDataProps;
    children: React.ElementType;
}

const BuildCollateralData: React.FC<BuildCollateralDataProps> = ({ basicData, children }: BuildCollateralDataProps) => {
    const tabsToEdit = CachingStorage.read("tabsToEdit");
    const [fullObj, setFullObj]: any = React.useState({});
    const { parentDataKey, dataKey, mainTitle, parentTitle, defaultObject, creditFileState: data, innerComponentData } = basicData;
    const [loading, setLoading]: any = React.useState(true);
    const requestId = data?.requestId;
    const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });
    const [fields, setFields]: any = React.useState({});
    const existingData = useHookstate<Array<any>>([]);
    let itemIndex = 0;
    const { formData } = useHookstate(creditData)
    const [errorState, setErrorState] = React.useState({
        status: false,
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    useEffect(() => {
        (async () => {
            try {
                // get field Data From Application form
                const response = await collateralApplicationFormGetter(dataKey);

                if (response) {
                    setFields(response);
                }

                let collateralParent = { [dataKey]: [] };

                if (data?.formData?.securityData?.[parentDataKey]) {
                    collateralParent = { ...collateralParent, ...data?.formData?.securityData?.[parentDataKey] };
                } else {
                    errorState.message = `No ${parentTitle} Data Found!`;
                }

                if (collateralParent?.[dataKey]) {
                    collateralParent = { ...collateralParent, [dataKey]: collateralParent?.[dataKey] };
                } else {
                    errorState.message = `No ${mainTitle} Data Found!`;
                }

                setFullObj(getCopy(collateralParent));
                existingData.set(getCopy(collateralParent?.[dataKey]));

                errorState.message = "";
            } catch (e) {
                console.error("Error Fetching Data!", e);
                errorState.message = "Error Fetching Data!";
            }
            finally {
                setErrorState(prev => ({ status: Boolean(errorState.message), message: errorState.message }));
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const MANDATORY_FIELDS = useMemo(() => {
        return [...getMandatoryFields(fields)]
    }, [fields]);

    const handleFieldsAdding = () => {
        let collateralData = {
            ...defaultObject,
            itemIndex: existingData.length,
            entryId: existingData.length,
            dataId: generateUniqueId(),
        };
        existingData.merge([collateralData]);
    };

    const handleSave = async (props) => {
        setIsSubmitting(true);
        let mandatoryRes: any[] = [];
        mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), MANDATORY_FIELDS);
        const finalArr: any = [];
        const collateralItem = formData?.["securityData"][parentDataKey][dataKey];

        if (dataKey in fullObj) {
            delete fullObj[dataKey];
        }

        for (let index = 0; index < existingData.get().length; index++) {
            const element = existingData[index].get();

            if (!element || element === null) continue;

            let obj = {
                type: `${parentDataKey}_${dataKey}`,
                itemIndex: index,
                entryId: index,
                ...element,
            };
            finalArr[finalArr.length] = obj;
        }

        let finalObj = {
            [parentDataKey]: {
                [dataKey]: finalArr,
                ...fullObj,
            },
        };

        let errorArray = collateralValidationErrorArray(parentDataKey, dataKey, finalObj);

        let lastValidation: Array<any> = [];

        if (Object.values(props).length > 0) {
            lastValidation = [...errorArray, ...mandatoryRes];
        }

        if (mandatoryRes?.length > 0) {
            const errorMessage: string = lastValidation.join("\n");
            Toast.error(`${errorMessage}`, "Mandatory Validation Error",);
        } else {
            let res = await saveAppFormDataJson(requestId, "securityData", finalObj);

            if (res?.status === 1) {
                collateralItem.set(finalArr);
                Toast.success("Updated", "Security Details Updated");
            } else if (res?.error) {
                Toast.warning("Not Updated", "Update Error");
            } else {
                Toast.error("Error", "Something went Wrong!");
            }
        }
        setIsSubmitting(false);
    };

    return (
        <div className="" style={{ position: "relative" }}>
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            <HeaderCollateral loading={isSubmitting} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={mainTitle} />
            <BuildComponent {...{
                errorState,
                existingData,
                loading,
                itemIndex,
                fields,
                basicData,
                ChildrenComponent: children
            }} />
        </div>
    );
}

export default BuildCollateralData

const BuildComponent: React.FC<BuildComponentProps> = React.memo(({ basicData, errorState, existingData, loading, itemIndex, fields, ChildrenComponent }: BuildComponentProps) => {
    const isSectionExpand = useHookstate("");
    const { mainTitle, parentDataKey, dataKey, applicationId } = basicData;

    if (errorState.status || !existingData.get()?.length) {
        return <ErrorMessageComponent headMessage={"Error"} errorMessage={!existingData.get()?.length ? `No ${mainTitle}s Data Found!` : errorState.message} />
    }

    if (loading) {
        return <Stack justifyContent={'center'} alignItems={'center'} style={{ height: "100vh", width: "100vw" }}>
            <CircularProgressComponent size={30} />
        </Stack>
    }

    return <Box className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
            {existingData?.map((doc, index) => {

                if (!doc.get()) return <></>;

                const elementKeys = Object.keys(doc);

                if (elementKeys.includes("removedItem")) {
                    return <></>
                }

                itemIndex = itemIndex + 1;

                const props = {
                    doc,
                    fields,
                    itemIndex,
                    isNotNeedComponentTitle: true
                };

                return (
                    <GrowableArrayAccordian
                        mainTitle={`${(index + 1) < 10 ? "0" + (index + 1) : index + 1})-${mainTitle}`}
                        key={`GrowableArrayAccordian_${doc?.['dataId']?.get() || index}`}
                        itemArray={[doc]} isectionExpand={isSectionExpand} dataKey={doc?.dataId?.get() || index}>
                        <Box display="flex" alignItems="center" mb={1} key={`${mainTitle}_${doc?.['dataId']?.get() || index}`}>
                            <Box style={{ width: "100%" }}>
                                {React.createElement(ChildrenComponent as React.ElementType, props)}
                            </Box>
                            <Box>
                                <Box pb={3}>
                                    <Tooltip title={"Reset"}>
                                        <Box>
                                            <ButtonComponent
                                                iconbtn={true}
                                                onClick={() => {
                                                    resetInputItems(doc, parentDataKey, dataKey);
                                                }}
                                                style={{ border: "1px solid gray" }}
                                                size="small"
                                            >
                                                <RestartAltIcon />
                                            </ButtonComponent>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box pt={3}>
                                    <Tooltip title={"Delete"}>
                                        <Box>
                                            <ButtonComponent iconbtn onClick={() => {
                                                collateralSectionDeletion({ applicationId, collateralDataState: existingData, itemIndex: index, collateralKeyString: `${parentDataKey}:${dataKey}:${mainTitle}` })
                                            }}>
                                                <DeleteIcon />
                                            </ButtonComponent>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </GrowableArrayAccordian>
                );
            })}
        </Stack>
    </Box>
})