import { contactDataState } from "../../../configs/stores/applicationForm";
import { messageMappings } from "./messageMapping";
import { handleArray, validateWithStructure } from "./structureValidation";

export const contactDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let finalKeyArr: any = [];
  let errorArray = validateWithStructure(structure, state);

  let errArrInArrays = handleArray(structure, state?.socialContactTypes, contactDataState?.socialContactTypes);
  let errArrInArraysContactTypes = handleArray(structure, state?.contactTypes, contactDataState?.contactTypes);
  let errArrInArraysEmailTypes = handleArray(structure, state?.emailTypes, contactDataState?.emailTypes);

  if (errorArray?.length > 0) {
    finalKeyArr = errorArray;
  }

  if (errArrInArrays?.length > 0) {
    finalKeyArr = errorArray.concat(errArrInArrays);
  }

  if (errArrInArraysContactTypes?.length > 0) {
    finalKeyArr = errorArray.concat(errArrInArraysContactTypes);
  }

  if (errArrInArraysEmailTypes?.length > 0) {
    finalKeyArr = errorArray.concat(errArrInArraysEmailTypes);
  }

  // Contact Details Selective Mandotory Validations
  // Phone
  state?.contactTypes?.get().map((obj) => {
    if (obj.contactType != "" && obj.contactType != null) {
      if (obj.contactNumber == "") {
        finalKeyArr.push("contactNumber");
      }
    }
  });

  // Email
  state?.emailTypes?.get().map((obj) => {
    if (obj.emailType != "") {
      if (obj.emailAddress == "") {
        finalKeyArr.push("emailAddress");
      }
    }
  });

  // Social Media
  state?.socialContactTypes?.get().map((obj) => {
    if (obj.type != "" && obj.type != null) {
      if (obj.url == "") {
        finalKeyArr.push("url");
      }
    }
  });

  if (state?.relationship?.get() === 8) {
    if (!state?.otherRelationship?.get() || state?.otherRelationship?.get() === "") {
      finalKeyArr.push("otherRelationship");
    }
  }

  // remove Guarantor Relation Contacts from error array
  if (isGuarantor) {
    finalKeyArr = finalKeyArr.filter((item) => item !== "relationName");
    finalKeyArr = finalKeyArr.filter((item) => item !== "relationLandNumber");
    finalKeyArr = finalKeyArr.filter((item) => item !== "relationship");
  }

  const preparedErrorMessages = messageMappings(finalKeyArr);
  return preparedErrorMessages;
};
