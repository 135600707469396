import { enDictionary } from "../../../configs/languageFiles/english";

export const messageMappings = (arr) => {
  let suffix = "field is mandatory";
  let suffixIgnoreElementList: any = ["futureDate_oldNicExp", "futureDate_newNicExp", "futureDate_dob", "futureDate_startDate", "futureDate_permanentDate", "pastDate_pensionableDate", "previous_futureDate_startDate", "previous_futureDate_endDate", "previous_futureDate_pensionableDate", "futureDate_brDate", "futureDate_accountOpenDate", "futureDate_asAtDate", "futureDate_grantedDate", "pastDate_expiryDate", "pastDate_matureDate"];
  let finalArr: any = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    let msg: any = "";
    if (element == "current_employmentSection" || element == "previous_employmentSection") {
      msg = `${keyMapping[element]} is mandatory`;
    } else if (suffixIgnoreElementList.includes(element)) {
      msg = keyMapping[element];
    } else {
      msg = keyMapping[element] + " " + suffix;
    }

    finalArr[finalArr.length] = msg;
  }
  return finalArr;
};

export const keyMapping = {
  ...enDictionary,
  // Personal Details
  primaryTitle: "Title",
  primaryLastName: "Last Name",
  civilState: "Civil State",
  gender: "Gender",
  dob: "Date of birth",
  taxPayer: "Tax payer",
  initialsInFull: "Initials in full",
  usedName: "Used name",
  initials: "Initials",
  race: "Race",
  district: "District",
  branch: "Branch",
  sriLankanCitizenshipStatus: "Citizenship by",
  addJointBorrower: "Add Joint Borrower",
  legalWarrantAgainstCustomer: "Legal warrant against customer",
  specify: "Legal warrant specification",
  preferredCommunicationLanguage: "Preferred Communication Language",
  spouseFullName: "Spouse Full Name",
  spouseOldNic: "Spouse Old Nic",
  spouseNewNic: "Spouse New Nic",
  spouseOldOrNewNic: "Spouse Old or New Nic",
  spouseNic: "Spouse NIC",
  empStatus: "Spouse Employment Status",
  individualTaxNo: "Individual Tax No.",
  idDetails: "Other details ID type",
  idExp: "Other details ID Expiry",
  publicEmpLoanActCovered: "Covered by the Public Employee's Loan Act",
  relationshipToMainApplicant: "Relationship to Main Applicant",
  spouse_occupation: "Spouse Occupation",
  sameOldNic: "NIC cannot be same as the Main Applicant Old NIC",
  sameNewNic: "NIC cannot be same as the Main Applicant New NIC",
  emptyNics: "Primary Applicant Old NIC",
  emptyOldNicIssuedDate: "Primary Applicant Old NIC Issue Date",
  emptyNewNicIssuedDate: "Primary Applicant New NIC Issue Date",
  rdRName: "RDB Relation Name",
  rdRRelationShip: "RelationShip with the RDB Relation",
  rdREmployeeNo: "Employee Number of the RDB Relation",
  rdRBranch: "Branch Of the RDB Relation",
  futureDate_oldNicExp: "Future dates not allowed for Old Nic Issued Date",
  futureDate_newNicExp: "Future dates not allowed for New Nic Issued Date",
  futureDate_dob: "Future dates not allowed for DOB",
  loanRequirement: "Loan Requirement",
  cashDeposited: "'Consistently deposited cash for at least 90 days?'",

  // Credit Details
  productType: "Product Type",
  sector: "Sector",
  scheme: "Scheme",
  loanAmount: "Loan amount",
  repaymentType: "Repayment type",
  interestRate: "Interest rate",
  loanPurpose: "Loan purpose",
  loanTerms: "Loan terms",
  loanCycles: "Loan cycles",
  loanFrequency: "Loan frequency",
  fundSource: "Fund source",
  gracePeriod: "Grace Period",
  // Single Customer View Details
  dorinDoraAccount: "Dorin Dora Account",

  // Address Details
  isMailingAddressSame: "Mailing address same as permanant",
  isCurrentAddressSame: "Current address same as permanant",
  currentResidenceTimePeriod: "Years at current residence",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  mailAddressLine1: "Mailing Address Line 1",
  mailAddressLine2: "Mailing Address Line 2",
  residentialState: "Residential State",
  currentAddressLine1: "current Address Line 1",
  currentAddressLine2: "current Address Line 2",

  // Contact Details
  primaryContact: "Primary contact No",
  relationName: "Relation Name",
  relationLandNumber: "Relation Contact Number",
  relationship: "Relationship",
  otherRelationship: "Other",
  contactNumber: "Phone Number",
  emailAddress: "Email Address",
  url: "Social URL",

  // Education Details
  primaryEducationGrade: "Highest Level of Education",
  // Employment Details
  employmentType: "Employment Type",
  employmentStatus: "Employment Status",
  startDate: "Start Date",
  pensionableDate: "Pensionable Date",
  occupation: "Occupation",
  employerName: "Employer Name",
  industryType: "Industry Type",
  permenantEmployment: "Permenant Employment",
  pensionableEmployment: "Pensionable Employment",
  permanentDate: "Date of Permanent",
  natureOfEmployment: "Nature of Employment",
  empContactNumber: "Phone Number",
  empContactName: "Contact Name",
  empEmailAddress: "Contact Email Address",
  assetValue: "Asset Value",
  "fullTimeOrPartTime": "Full Time Or Part Time",
  current_employmentSection: "Current Employment Section",
  futureDate_startDate: "Future dates not allowed for Start Date",
  futureDate_permanentDate: "Future dates not allowed for Permanent Date",
  pastDate_pensionableDate: "Past dates not allowed for Pensionable Date",
  previous_employmentSection: "Previous Employment Section",
  previous_employmentType: "Previous Employment Type",
  previous_employmentStatus: "Previous Employment Status",
  previous_startDate: "Previous Start Date",
  previous_pensionableDate: "Previous Pensionable Date",
  previous_occupation: "Previous Occupation",
  previous_employerName: "Previous Employer Name",
  previous_permenantEmployment: "Previous Permenant Employment",
  previous_industryType: "Previous Industry Type",
  previous_permanentDate: "Previous Date of Permanent",
  previous_natureOfEmployment: "Previous Nature of Employment",
  previous_empContactNumber: "Previous Phone Number",
  previous_empContactName: "Previous Contact Name",
  previous_empDateCompare: "Previous End Date field Should be Higher than Start Date",
  previous_empEmailAddress: "Previous Contact Email Address",
  previous_futureDate_startDate: "Previous Future dates not allowed for Start Date",
  previous_futureDate_endDate: "Previous Future dates not allowed for End Date",
  previous_futureDate_pensionableDate: "Previous Future dates not allowed for Pensionable Date",

  // Income Details
  sourceOfIncomeBusiness: "Source of Income Business",
  incomeFrequencyBusiness: "Business Income Frequency",
  expenseBusiness: "Business Expenses",
  deductionsCostsOfGoods: "Deduction Costs of Good",
  incomeBusiness: "Business Income",
  expenseFrequencyBusiness: "Business Expense Frequency",
  expenseTypeBusiness: "Expense Type",
  incomeTypeCultivation: "Cultivation Income Type",
  incomeFrequencyCultivation: "Cultivation Income Frequency",
  seasonalIncome: "Seasonal Income",
  expenseTypeCultivation: "Cultivation Expense Type",
  expenseFrequencyCultivation: "Cultivation Expense Frequency",
  seasonalExpense: "Seasonal Expense",
  incomeHolder: "Income Holder",
  sourceOfIncome: "Source of Income",
  salarySlipBasedOn: "Salary Slip Based on",
  incomeFrequency: "Income Frequency",
  basicSalary: "Salary/Pension Amount",
  incomeCategory: "Income Details",
  salaryAssignment: "Salary Assignment",
  frequencyForMonth: "Occurrences for Month",
  frequencyForYear: "Frequency for Annual",
  incomeFrequencyForMonthBusiness: "Frequency for Monthly Income",
  incomeFrequencyForYearBusiness: "Frequency for Annual Income",
  expenseFrequencyForMonthBusiness: "Frequency for Monthly Expenses",
  expenseFrequencyForYearBusiness: "Frequency for Annual Expenses",

  //ASSETS DETAILS
  assetsCategory: "Assets Category",
  assetType: "Asset Type",
  extentOrRegNoOrOther: "Extent Or Reg No Or Other",
  assetGrossValue: "Asset Force Sales Value",
  mortgageValue: "Mortgage Value",
  assetDescription: "Asset Description",
  assetMarketValue: "Asset Market Value",

  // Expenses Details
  numberOfDepends: "Number of Financial Dependents",
  expenseType: "Expense Type",
  expenseFrequency: "Expense Frequency",
  expenseAmount: "Expense Amount",
  presenceOfRegulatedFinSystem: "Presence of the regulated financial System",

  // Tax Details
  taxIdentificationNumber: "Individual Tax Identification Number",

  // Inquiry of Obligations
  liabilityType: "Liability Type",
  bank: "Bank",
  amount: "Amount",
  obligationType: "Obligation Type",
  installmentFrequency: "Installment Frequency",
  installmentAmount: "Installment Amount",
  numberofInstallmentsLeft: "Number of Installments Left",
  currentOutstanding: "Current Outstanding",
  originalAmount: "Original Amount",
  outstanding: "Outstanding",
  annualInterest: "Annual Interest",
  matureDate: "Mature Date",
  limitOfOverdraft: "Limit of Overdraft",
  limit: "Limit",
  typeOfCard: "Card Type",
  securityCategory: "Security Category",
  securityType: "Security Type",
  liabilityCategory: "Liability Category",
  lease_currentOutstanding: "Current Outstanding value should be greater than limit in Leasing",
  futureDate_grantedDate: "Future dates not allowed for Granted Date",
  pastDate_expiryDate: "Past dates not allowed for Expiry Date",
  pastDate_matureDate: "Past dates not allowed for Mature Date",

  // Bank Details
  bankName: "Bank Name",
  accountType: "Account Type",
  accountNo: "Account Number",
  asAtDate: "As at Date",
  balanceAsAtDate: "Balance as at Date",
  futureDate_accountOpenDate: "Future dates not allowed for Account Open Date",
  futureDate_asAtDate: "Future dates not allowed for As at Date",

  // Insurance Details
  insuranceCompany: "Insurance Company",

  // Business Details
  typeOfBusiness: "Type of Business",
  businessSector: "Business Sector",
  noOfYearsInOperation: "No. of Years in Operation",
  noOfMonthsInOperation: "No. of Month in Operation",
  isCertificationHave: "Has the business obtained any certificates",
  certification: "Authority or Certification",
  partnershipD_name: "Particpant's Name",
  partnershipD_newNic: "Partner's New Nic",
  partnershipD_oldNic: "Partner's Old Nic",
  partnershipD_bothNic: "Partner's New or Old Nic",
  partnershipD_relationshipWithtThePrimaryApplicant: "Partner Relationship With the Primary Applicant",
  partnershipD_addressLine1: "Particpant's Address Line 1",
  partnershipD_addressLine2: "Particpant's Address Line 2",
  individualD_name: "Individual's Name",
  individualD_newNic: "Individual's New Nic",
  individualD_oldNic: "Individual's Old Nic",
  individualD_bothNic: "Individual's New or Old Nic",
  individualD_relationshipWithtThePrimaryApplicant: "Individual Relationship With the Primary Applicant",
  individualD_addressLine1: "Individual's Address Line 1",
  individualD_addressLine2: "Individual's Address Line 2",
  directorD_name: "Director's Name",
  directorD_newNic: "Director's New Nic",
  directorD_oldNic: "Director's Old Nic",
  directorD_bothNic: "Director's New or Old Nic",
  directorD_relationshipWithtThePrimaryApplicant: "Director Relationship With the Primary Applicant",
  directorD_addressLine1: "Director's Address Line 1",
  directorD_addressLine2: "Director's Address Line 2",
  shareholderD_name: "Shareholder's Name",
  shareholderD_newNic: "Shareholder's New Nic",
  shareholderD_oldNic: "Shareholder's Old Nic",
  shareholderD_bothNic: "Shareholder's New or Old Nic",
  shareholderD_relationshipWithtThePrimaryApplicant: "Shareholder Relationship With the Primary Applicant",
  shareholderD_percentageOfShares: "Shareholder's Percentage of Shares",
  shareholderD_shareSum: "Sum of all the percentages of shareholders should be 100",
  shareholderD_addressLine1: "Shareholder's Address Line 1",
  shareholderD_addressLine2: "Shareholder's Address Line 2",
  businessIsRunWithA: "Ownership of Business Property",
  otherName: "Other Name",
  relationshipWithtThePrimaryApplicant: "Other - Relationship With the Applicant",
  leaseAgreementUnder: "Lease Agreement is under",
  agreementPeriod: "Agreement Period",
  propertyIsUnder: "Property is under",
  vulnerabilityClimaticAndEnvironmentalFactors: "Vulnerability to Climatic and Environmental Factors",
  currentYearSales: "Current Year Sales",
  previousYearSales: "Previous Year Sales",
  futureDate_brDate: "Future dates not allowed for Business Register Date",
  fromDate: "From Date",
  toDate: "To Date",

  //security Details
  purposeCode: "Purpose Code",
  depositDate: "Deposit Date",
  currency: "Currency",
  principalAmount: "Principal Amount",
  interestAccured: "Interest Accured",
  maturityDate: "Maturity Date",
  depositInterestRateCbs: "Deposit Interest RateCbs",
  depositAmount: "Deposit Amount",
  margin: "Margin",
  totalFdAmount: "Total FdAmount",
  sactionedRefNumber: "Sactioned Ref Number",
  sanctionedAmount: "Sanctioned Amount",
  sactionedBy: "Sactioned By",
  creditAmount: "Credit Amount",
  sourceOfFundType: "Source Of FundType",
  transactionCurrency: "Transaction Currency",
  transactionAmount: "Transaction Amount",
  loanTransactionMode: "Loan Transaction Mode",
  amountByCash: "Amount By Cash",
  effectiveDate: "Effective Date",
  productCode: "Product Code",
  accountCurrency: "Account Currency",
  autoInstallmentRecoveryRequired: "Auto Installment Recovery Required",
  accountName: "Account Name",
  insuranceAgreementPolicy: "Insurance Agreement Policy",
  certificateNo: "Certificate No",
  issuingDate: "Issuing Date",
  numberOfShares: "Number Of Shares",
  nominalValueOfShare: "Nominal Value Of Share",
  marketValueOfShare: "Market Value Of Share",
  bondAmount: "Bond Amount",
  coupounRate: "Coupoun Rate",
  specialConditions: "Special Conditions",
  receiptNo: "Receipt No",
  cropDepositorName: "Crop Depositor Name",
  nic: "NIC",
  grainDepositedInWarehouse: "Grain Deposited In Warehouse",
  depositedDate: "Deposited Date",
  surrendableValue: "Surrendable Value",
  typeOfMortagage: "Type Of Mortagage",
  detailsOfPrimary: "Details Of Primary",
  mortagageName: "Mortagage Name",
  dateOfMortagage: "Date Of Mortagage",
  addressOfMortgage: "Address Of Mortgage",
  distric: "Distric",
  locationCode: "Location Code",
  surveyPlanDate: "Survey Plan Date",
  suveryPlanNo: "Suvery Plan No",
  lotNo: "Lot No",
  localAuthority: "Local Authority",
  landMeasurementType: "Land Measurement Type",
  extentOfLand: "Extent Of Land",
  buildUpAreaOfBuildings: "Build Up Area Of Buildings",
  landValuationRate: "Land Valuation Rate",
  registratonDate: "Registraton Date",
  buildingValue: "Building Value",
  landValue: "Land Value",
  roadWidth: "Road Width",
  typeofRoad: "Type of Road",
  remarks: "Remarks",
  obtainedDate: "Obtained Date",
  coveredPolicy: "Covered Policy",
  machineType: "Machine Type",
  hPOrLienNotedOn: "HP Or Lien NotedOn",
  makeHpOrCc: "Make Hp Or Cc",
  city: "City",
  manufactureCode: "Manufacture Code",
  registrationNumber: "Registration Number",
  expiryDate: "Expiry Date",
  purchasedYear: "Purchased Year",
  condition: "Condition",
  sourceOfPower: "Source Of Power",
  suppliersDistrict: "Suppliers District",
  suppliersCity: "Suppliers City",
  thirdPartyDealerCode: "Third Party Dealer Code",
  invoicePurchaseValue: "Invoice Purchase Value",
  lifespanNumberOfYears: "Life span Number Of Years",
  lifespanNumberOfUnits: "Life span Number Of Units",
  lifespanDepretiationRate: "Life span Depretiation Rate",
  natureWarranty: "Nature Warranty",
  warrantyStartDate: "Warranty Start Date",
  warrantyEndDate: "Warranty End Date",
  insurancePrimium: "Insurance Premium",
  insuranceCompanyCode: "Insurance Company Code",
  registrationDate: "Policy Date",
  policyNumber: "Policy Number",
  documentNumber: "Document Number",
  noOfDocuments: "No Of Documents",
  address: "Address",
  location: "Location",
  benificiaryName: "Benificiary Name",
  chassisNo: "Chassis No",
  countryOfOrigin: "Country Of Origin",
  crReleasedDate: "CR Released Date",
  dealersContactNumber: "Dealers Contact Number",
  dealersName: "Dealers Name",
  duplicateKeyNumber: "Duplicate Key Number",
  engineNo: "Engine No",
  forceSaleValue: "Force Sale Value",
  fuelType: "Fuel Type",
  insuranceCompanyName: "Insurance Company Name",
  insurancePolcyNumber: "Insurance Policy Number",
  insurancePremium: "Insurance Premium",
  insuredAmount: "Insured Value",
  intendedUse: "Intended Use",
  invoiceDate: "invoiceDate",
  invoiceNumber: "Invoice Number",
  invoiceOrPurchaseValue: "Invoice Or Purchase Value",
  manufacturerCode: "Manufacturer Code",
  manufacturerName: "Manufacturer Name",
  marketValue: "Market Value",
  natureOfWarrenty: "Nature Of Warrenty",
  policyExpiryDate: "Policy Expiry Date",
  policyNextRenewalDate: "Policy Next Renewal Date",
  regitrationNumber: "Regitration Number",
  supplierAddress: "Supplier Address",
  supplierOrDealerName: "Supplier Or Dealer Name",
  taxAmount: "Tax Amount",
  taxNumber: "Tax Number",
  taxType: "Tax Type",
  thirdPartyDelaerCode: "Third Party Delaer Code",
  valuerDate: "The valuation date",
  valuerName: "Valuer Name",
  valuerRegNumber: "Valuer Reg Number",
  vehicleCatergory: "Vehicle Catergory",
  vehicleMake: "Vehicle Make",
  vehicleModel: "Vehicle Model",
  warrentyEndDate: "Warrenty End Date",
  warrentyStartDate: "Warrenty Start Date",
  yearOfManufacture: "Year Of Manufacture",
  yearOfRegistration: "Year Of Registration",
  yearOfVehiclePurchase: "Year Of Vehicle Purchase",
  owarehousep: "Warehouse Rented in Bank's / Client Name",
  cropType: "Crop Type",
  weightOfPledge: "Weight Of Pledge",
  warehouseAddress: "Warehouse Address",
  valueOfSecurity: "Value Of Security",
  securedValue: "Secured Value",
  description: "Description",
  letterNumber: "Letter Number",
  issuingAuthority: "Issuing Authority",
  titleInsuranceCompanyName: "Title Insurance CompanyName",
  titleInsurancePolicyNumber: "Title Insurance Policy Number",
  titleInsuredAmount: "Title Insured Value",

  faceValue: "Face Value",
  dueDate: "Due Date",

  /*  Loan Protection Insurance  */
  lpIssuingAuthority: "Issuing Authority",
  lpInsuranceAgreementPolicy: "Insurance Policy Type",
  lpRegistrationDate: "Registration Date",
  lpPolicyNumber: "Policy Number",
  lpDocumentNumber: "Document Number",
  lpNoOfDocuments: "No Of Documents",
  lpFaceValue: "Face Value",
  lpDueDate: "Due Date",
  lpDescription: "Description",
  lpInsuranceDetailsDescription: "Insurance Details Description",
  lpPolicyDate: "Policy Date",

  /* Pledge */
  pledgeValueOfSecurity: "Value Of Security",
  pledgeSecuredValue: "Secured Value",
  pledgeDescription: "Description",
  pledgeOwnership: "Ownership",
  pledgeOwarehousep: "Warehouse Rented in Bank's / Client Name",
  pledgeCropType: "Crop Type",
  pledgeWeightOfPledge: "Weight Of Pledge",
  pledgeWarehouseAddress: "Warehouse Address",
  pledgeDistrict: "District",
  pledgeCity: "City",
  pledgeForceSaleValue: "Force Sale Value",
  pledgeConditionOfTheWarehouse: "Condition Of The Warehouse",
  pledgeApproximateDistanceFromTheBranch: "Approximate Distance From Branch",
  pledgeKeyNumber: "Key No",
  pledgeStatementTemplate: "Statement Template",
  pledgeStatementFreq: "Statement Freq",
  pledgeStockStatementValidUpToDate: "StockStatementValidUpToDate",
  pledgeToBeSubmittedBeforeGraceDays: "To BeSubmitted Before GraceDays",
  pledgeLeaseAgreementNumber: "Lease agreement number confirming ownership",
  pledgeDateOfLeaseAgreement: "Date of Lease agreement",
  pledgeCustomerType: "Customer Type",
  pledgeRegistrationNumber: "Registration Number",
  loanDetailsAccountNumber: "Loan Details AC No",
  customerNumber: "Customer No",
  loanProductCode: "Loan Product Code",
  loanDetailsAccountOpenedOn: "Loan Details AC Opened",
  fixedDepositAccountNumber: "Fixed Deposit AC No",
  contractNumber: "Contract No",
  amountAvaibleForLoan: "Amount Avaible Loan",
  totalOfMaximumGrantingLadAmount: "Total Max Granting Land Amount",
  depositInterestRateFd: "Deposit InterestRate Fd",
  weightedAverageDepositRate: "Weighted Average DepositRate",
  incrementalInterestAboveTheDepositRate: "Increment Interest: DepositRate",
  effectiveInterestRate: "Effective InterestRate",
  remarksInterestDetails: "Remarks Interest",
  savingAccountAvaibleBalanace: "Saving AC Avaible Balanace",
  gLAccessCode: "GL Access Code",
  remarksSettlementsDetails: "remarks Settlements",
  loanAutoRecoveryAccountOpenedOn: "Loan AutoRecovery AC No",
  recoveryAccountNumber: "Recovery AC No",
  amountByTransfer: "AmountBy Transfer",
  accountNumberSavings: "Account No Savings",
  addressOfTheLocationOfTheLandOrBuilding: "Location Of Land/Building",
  deedOrTitleCertificateNumber: "Title Certificate No",
  statementTemplate: "Statement Template",
  statementFreq: "Statement Freq",
  stockStatementValidUpToDate: "stock StatementValid UpToDate",
  toBeSubmittedBeforeGraceDays: "to BeSubmitted:Grace Days",
  pledgeInsuaranceValue: "Insuarance Value",
  pledgeLoanToValueRatio: "Loan to Value Ratio",
  pledgeTaxPeriod: "Tax Period",
  pledgeDateBanksLockKey: "Date of under Banks Lock key",
  pledgeValueOfStock: "Value of Stock",
  pledgeAgreementNumber: "Agreement Number",
  pledgeDatePreparePledgeAgreement: "Date of the Prepared Pledge Agreement",
  pledgeNameLawyerPreparedPledgeAgreement: "Name of the lawyer prepared the Pledge Agreement",

  /* Life Insurance */
  liValueOfSecurity: "Value Of Security",
  dateCommencementInsurance: "Date Commencement Insurance",
  liSecuredValue: "Secured Value",
  liDescription: "Description",
  // liInsuranceCompanyCode: "Insurance Company Code",
  liInsuranceCompanyCode: "Issuing Authority",
  liRegistrationDate: "Registration Date",
  liPolicyNumber: "Policy Number",
  liDocumentNumber: "Document Number",
  liNoOfDocuments: "No Of Documents",
  liFaceValue: "Face Value",
  liMarketValue: "Market Value",
  liDueDate: "Due Date",
  liSurrendableValue: "SurrendableValue",
  liDateCommencementInsurance: "Date Commencement Insurance",
  liDateLetterSignedThirdParty: "Date Letter Signed by Third Party",
  liDateSignedLetterBeneficiaries: "Date Signed Letter Beneficiaries Policy",

  /**Shares */

  shareValueOfSecurity: "Value Of Security",
  shareSecuredValue: "Secured Value",
  shareDescription: "Description",
  shareCertificateNo: "Certificate No",
  shareIssuingDate: "Issuing Date",
  shareNumberOfShares: "Number Of Shares",
  shareNominalValueOfShare: " Nominal Value Of Share",
  shareMarketValueOfShare: "Market Value Of Share",

  /** Tresury Bond */
  tbValueOfSecurity: "Value Of Security",
  tbSecuredValue: "Secured Value",
  tbDescription: "Description",
  tbLetterNumber: "Letter No",
  tbIssuingAuthority: "Issuing Authority",
  tbMatureDate: "Mature Date",
  tbBondAmount: "BondAmount",
  tbCoupounRate: "Coupoun Rate",
  tbSpecialConditions: "Special Conditions",

  /**WhareHouse Receipt */

  whrValueOfSecurity: "Value Of Security",
  whrSecuredValue: "Secured Value",
  whrDescription: "Description",
  whrReceiptNo: "Receipt No",
  whrLocation: "Location",
  whrCropDepositorName: "Crop Depositor Name",
  whrNic: "NIC",
  whrAddress: "Address",
  whrGrainDepositedInWarehouse: "Grain Deposited Warehouse",
  whrDepositedDate: "Deposited Date",
  "whrNic|whrNicOld": "New Or Old Nic",
  whrNicOld: "Old NIC",
  isNew: false,
  whrPhoneNumber: "Phone Number",
  whrQuantity: "Quantity",
  whrNoOfBags: "No Of Bags",

  /* Stock As A Gold */
  sagValueOfSecurity: "Value Of Security",
  sagSecuredValue: "Secured Value",
  sagDescription: "Description",
  sagNoOfJewelsOrPieces: "No Of Jewels Or Pieces",
  sagArticleType: "Article Type",
  sagDescriptionOfTheOrnamentsOrArticle: "Description Of The Ornaments/Article",
  sagKaratageOfArticle: "Karatage Of Article",
  sagGrossWeight: "Gross Weight",
  sagNetWeight: "Net Weight",
  sagMarketRatePerGram: "Market Rate PerGram",
  sagMarketValue: "Market Value",
  sagPermissibleRatePerGram: "Permissible Rate PerGram",
  sagPermissibleValue: "Permissible Value",

  /**
   * FD Against Loan
   */
  fdValueOfSecurity: "ValueOfSecurity",
  fdSecuredValue: "SecuredValue",
  fdDescription: "Description",
  fdLoanDetailsAccountNumber: "Loan Details Account Number",
  fdCustomerNumber: "Customer Number",
  fdLoanProductCode: "Loan Product Code",
  fdLoanDetailsAccountOpenedOn: "Loan Details Account Opened On",
  fdPurposeCode: "Purpose Code",
  fdFixedDepositAccountNumber: "Fixed Deposit Account Number",
  fdContractNumber: "Contract Number",
  fdDepositDate: "Deposit Date",
  fdCurrency: "Currency",
  fdPrincipalAmount: "Principal Amount",
  fdInterestAccured: "Interest Accured",
  fdMaturityDate: "Maturity Date",
  fdDepositInterestRateCbs: "Deposit InterestRate",
  fdDepositAmount: "Deposit Amount",
  fdMargin: "Margin",
  fdAmountAvaibleForLoan: "Amount Avaible For Loan",
  fdTotalFdAmount: "Total FdAmount",
  fdTotalOfMaximumGrantingLadAmount: "Total Max Granting Amount",
  fdSactionedRefNumber: "Sactioned Ref No",
  fdSanctionedAmount: "Sanctioned Amount",
  fdSactionedBy: "Sactioned By",
  fdExpiryDate: "Expiry Date",
  fdDepositInterestRateFd: "Deposit InterestRateFd",
  fdWeightedAverageDepositRate: "Weighted Average DepositRate",
  fdIncrementalInterestAboveTheDepositRate: "Incremental Interest Above DepositRate",
  fdEffectiveInterestRate: "Effective InterestRate",
  fdRemarksInterestDetails: "Remarks InterestDetails",
  fdCreditAmount: "CreditAmount",
  fdSourceOfFundType: "Source Of Fund Type",
  fdTransactionCurrency: "Transaction Currency",
  fdTransactionAmount: "Transaction Amount",
  fdSavingAccountAvaibleBalanace: "Saving Acc Avaible Balanace",
  fdGLAccessCode: "GL Access Code",
  fdRemarksSettlementsDetails: "Remarks Settlements Details",
  fdLoanAutoRecoveryAccountNumber: "Loan Auto Recovery Account Number",
  fdEffectiveDate: "Effective Date",
  fdProductCode: "Loan Product Code",
  fdAutRProductCode: "Loan Auto Recovery Product Code",
  fdAccountCurrency: "Account Currency",
  fdLoanAutoRecoveryAccountOpenedOn: "Loan Auto Recovery AccountOpenedOn",
  fdAmountByCash: "Amount By Cash",
  fdamountByTransfer: "Amount By Transfer",
  fdAccountNumberSavings: "Acc No Savings",
  fdRecoveryAccountNumber: "Recovery Acc Number",
  fdAccountName: "Account Name",
  fdTenorFixedDeposit: "Tenor Fixed Deposit",
  fdLoanAccountNumber: "Loan Account Number",

  /**Vehicle */
  vhValueOfSecurity: "Value Of Security",
  vhSecuredValue: "Secured Value",
  vhDescription: "Description",
  vhVehicleCatergory: "Vehicle Catergory",
  vhVehicleMake: "Vehicle Make",
  vhVehicleModel: "Vehicle Mode l",
  vhEngineNo: "Engine No",
  vhChassisNo: "Chassis No",
  vhMarketValue: "Market Value",
  vhForceSaleValue: "Force Sale Value",
  vhCReleasedDate: "CR Released Date",
  vhFuelType: "Fuel Type",
  vhIntendedUse: "Intended Use",
  vhRegitrationNumber: "Regitration Number",
  vhYearOfRegistration: "Year Of Registration",
  vhValuerName: "Valuer Name",
  vhValuerRegNumber: "Valuer RegNumber",
  vhValuerDate: "The valuation date",
  vhManufacturerName: "Manufacturer Name",
  vhManufacturerCode: "Manufacturer Code",
  vhYearOfManufacture: "Year Of Manufacture",
  vhCountryOfOrigin: "Country Of Origin",
  vhYearOfVehiclePurchase: "Year Of Vehicle Purchase",
  vhSupplierOrDealerName: "Supplier/Dealer Name",
  vhSupplierAddress: "Supplier Address",
  vhThirdPartyDelaerCode: "Third Party Delaer Code",
  vhInvoiceNumber: "Invoice Number",
  vhInvoiceDate: "Invoice Date",
  vhInvoiceOrPurchaseValue: "Invoice/Purchase Value",
  vhNameValuationOfficer: "Name of the Valuation Officer",

  /**Mashinery Equipmenta */
  immValueOfSecurity: "Value Of Security",
  immSecuredValue: "Secured Value",
  immForceSaleValue: "Force Sale Value",
  immDescription: "Description",
  immMachineType: "Machine Type",
  immChasisNumber: "Chasis Number",
  immEngineNumber: "Engine Number",
  immHPOrLienNotedOn: "HP/LienNoted On",
  immMakeHpOrCc: "Make Hp/Cc",
  immAddress: "Address",
  immDistrict: "District",
  immCity: "City",
  immModel: "Model",
  immCapacity: "Capacity",
  immManufacturerName: "Manufacturer Name",
  immManufactureCode: "Manufacture Code",
  immYearOfManufacture: "Year Of Manufacture",
  immRegistrationNumber: "Registration Number",
  immExpiryDate: "Expiry Date",
  immPurchasedYear: "Purchased Year",
  immCondition: "Condition",
  immSourceOfPower: "Source Of Power",
  immSupplierOrDealerName: "Supplier/Dealer Name",
  immSupplierAddress: "Supplier Address",
  immSuppliersDistrict: "Suppliers District",
  immSuppliersCity: "Suppliers City",
  immThirdPartyDealerCode: "Third Party Dealer Code",
  immInvoiceNumber: "Invoice Number",
  immInvoiceDate: "Invoice Date",
  immInvoicePurchaseValue: "Invoice Purchase Value",
  immLifespanNumberOfYears: "Life span Number Of Years",
  immLifespanNumberOfUnits: "Life Span Number Of Units",
  immLifespanDepretiationRate: "Life span Depretiation Rate",
  immMarketValue: "Market Value",
  immCountryOfManufacture: "Country of Manufacture",
  immMovablePropertyMortgageDeedNo: "Property Mortgage Deed No",
  immLawyerPreparedMortgageDeed: "The lawyer who prepared Movable property Mortgage Deed",
  immDatePreparedMortgageDeed: "Date Prepared Mortgage Deed",
  immDateRegistrationMortgageDeed: "Date of Registration of Mortgage Deed",
  immOfficeNameRegistrationMortgageDeed: "Office Name of Registration of Mortgage Deed",

  /**ImmovableMashinery Equipmenta */
  mmValueOfSecurity: "Value Of Security",
  mmSecuredValue: "Secured Value",
  mmDescription: "Description",
  mmMachineType: "Machine Type",
  mmForceSaleValue: "Force Sale Value",
  mmChasisNumber: "Chasis Number",
  mmEngineNumber: "Engine Number",
  mmHPOrLienNotedOn: "HP/LienNoted On",
  mmMakeHpOrCc: "Make Hp/Cc",
  mmAddress: "Address",
  mmDistrict: "District",
  mmCity: "City",
  mmManufacturerName: "Manufacturer Name",
  mmManufactureCode: "Manufacture Code",
  mmYearOfManufacture: "Year Of Manufacture",
  mmRegistrationNumber: "Registration Number",
  mmExpiryDate: "Expiry Date",
  mmPurchasedYear: "Purchased Year",
  mmCondition: "Condition",
  mmSourceOfPower: "Source Of Power",
  mmSupplierOrDealerName: "Supplier/Dealer Name",
  mmSupplierAddress: "Supplier Address",
  mmSuppliersDistrict: "Suppliers District",
  mmSuppliersCity: "Suppliers City",
  mmThirdPartyDealerCode: "Third Party Dealer Code",
  mmInvoiceNumber: "Invoice Number",
  mmInvoiceDate: "Invoice Date",
  mmInvoicePurchaseValue: "Invoice Purchase Value",
  mmLifespanNumberOfYears: "Life span Number Of Years",
  mmLifespanNumberOfUnits: "Life Span Number Of Units",
  mmLifespanDepretiationRate: "Life span Depretiation Rate",
  mmMarketValue: "Market Value",
  mmCountryOfManufacture: "Country of Manufacture",
  mmMovablePropertyMortgageDeedNo: "Property Mortgage Deed No",
  mmLawyerPreparedMortgageDeed: "Lawyer Prepared Mortgage Deed",
  mmDatePreparedMortgageDeed: "Date Prepared Mortgage Deed",
  mmDateRegistrationMortgageDeed: "Date of Registration of Mortgage Deed",
  mmOfficeNameRegistrationMortgageDeed: "Office Name of Registration of Mortgage Deed",
  mmModel: "Model",
  mmCapacity: "Capacity",

  /**Property */

  propValueOfSecurity: "Value Of Security",
  propSecuredValue: "Secured Value",
  propDescription: "Description",
  propTypeOfMortagage: "Type Of Mortagage",
  propDetailsOfPrimary: "Details Of Primary",
  propMortagageName: "Mortagage Name",
  propNoOfDocuments: "No Of Documents",
  propDateOfMortagage: "Date Of Mortagage",
  propAddressOfMortgage: "Address Of Mortgage",
  propDistric: "Distric",
  propDity: "City",
  propAddressOfTheLocationOfTheLandOrBuilding: "Address Of The Location Of The Land/Building",
  propLocationCode: "Location Code",
  propSurveyPlanDate: "Survey Plan Date",
  propSuveryPlanNo: "Suvery Plan No",
  propLotNo: "Lot No",
  propDeedOrTitleCertificateNumber: "Deed/Title Certificate Number",
  propLocalAuthority: "Local Authority",
  propLandMeasurementType: "Land Measurement Type",
  propExtentOfLand: "Extent Of Land",
  propBuildUpAreaOfBuildings: "Build Up Area Of Buildings",
  propLandValuationRate: "Land Valuation Rate",
  propRegistrationNumber: "Registration Number",
  propRegistratonDate: "Registraton Date",
  propBuildingValue: "Building Value",
  propLandValue: "Land Value",
  propRoadWidth: "Road Width",
  propTypeofRoad: "Type of Road",
  propRemarks: "Memarks",
  propObtainedDate: "Obtained Date",
  propInsuranceValue: "Insurance Value",
  propMortgageDeedNumber: "Mortgage Deed Number",
  propDatePreparedMortgageDeed: "Date Prepared Mortgage Deed",
  propDateRegistrationMortgageDeed: "Date of Registration of Mortgage Deed",
  propFinalRegisteredFolioNumber: "Final Registered Folio Number",
  propDateFinalRegisteredFolio: "Date Final Registered Folio",
  propIssuedDateTitleReport: "Issued Date Title Report",
  proplawyerIssuedTitleReport: "Lawyer Issued Title Report",
  propTitleDeedNumber: "Title Deed Number",
  propNameNotaryPreparedDeed: "Name Notary Prepared Deed",
  propDateNotaryPreparedDeed: "Date Notary Prepared Deed",
  propDateRegistrationDeed: "Date Registration Deed",
  propNameSurveyorMeasuredPlan: "Name Surveyor Measured Plan",
  propDateRegisteredPlan: "Date Registered Plan",
  propDateIssueStreetLineNonVestingCertificate: "Date Issue Street Line Non Vesting Certificate",

  propTypeOfMortgage: "Type of Mortgage",
  propMortgageName: "Mortgage Name",
  propDateOfMortgage: "Date of Mortgage",
  propDistrict: "District",
  propCity: "City",
  propSurveyPlanNo: "Survey Plan Number",
  propRegistrationDate: "Registration Date",
  propTypeOfRoad: "Type of Road",
  propProvince: "Province",
  propVillage: "Village",
  propGramNiladariDivision: "Gram Niladari Division",
  propDivisionalSecretaries: "Divisional Secretaries",
  propPradeshiyaSabhaLimits: "Pradeshiya Sabha Limits",
  propValuationOfficerName: "Valuation Officer Name",
  propOfficeRegistrationMortgageDeed: "Office Name of Registration of Mortgage Deed",
  propJurisdictionRegistrationMortgageDeed: "Jurisdiction of Registration of Mortgage Deed",
  propLawyerIssuedTitleReport: "Lawyer Issued Title Report",
  propNameAuthorityCertifiedBuildingPlan: "Name Authority Certified Building Plan",
  propDateCertifiedBuildingPlan: "Date Certified Building Plan",
  propDateValuation: "Date Valuation",
  propNamePreparedBOQ: "Name Prepared BOQ",
  propPositionOfficerPreparedBOQ: "Position Officer Prepared BOQ",
  propDatePreparedBOQ: "Date Prepared BOQ",
  propertyOrLandIsHavingBuilding: "Property Or Land Is Having Building",
  approvedBuildingPlan: "Approved Building Plan",
  specialConditionOfApprovedBuildingPlan: "special Condition Of Approved Building Plan",
  legalOpinionObtained: "Legal Opinion Obtained",
  titleInsuranceRequired: "Insured Value",
  actionPropertyOrLandIsHavingBuilding: "Action Property Or Land Is Having Building",
  actionApprovedBuildingPlan: "Action Approved Building Plan",
  actionSpecialConditionOfApprovedBuildingPlan: "Action Special Condition Of Approved Building Plan",
  actionLegalOpinionObtained: "Action Legal Opinion Obtained",
  propForceSaleValue: "Force Sale Value",
  propPradeshiyaSabhaLimists: "Pradeshiya Sabha Limists",
  insurancePolicyNumber: "Insurance Policy Number",
  vhKeptAtAddress: "kept Address",
  actionSpecialConditionOfApprovedBulidingPlan: "Special Condition Of Approved Buliding Plan",
  minLoanAmount: "Loan Amount Cannot Be Less Than Minimum Loan Amount",
  maxLoanAmount: "Loan Amount Cannot Be More Than Maximun Loan Amount",
  cantBeEmpty: "⚠️ Sections Data",
  NoYearsOperation: "No Years Operation",
  //Assets Details
  ownership: "Ownership",
};

export const removeUndefinedAndNull = (errorArray) => {
  let finalArr: any = [];
  for (let index = 0; index < errorArray.length; index++) {
    const element = errorArray[index];
    if (element !== undefined && element !== null) {
      finalArr[finalArr.length] = element;
    }
  }

  return finalArr;
};
