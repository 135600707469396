import { Downgraded, useState } from "@hookstate/core";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { documentUploadData } from "../../../../../../../configs/stores/document-upload-store/documentUploadStore";
import { updateApplicationDocumentStatus } from "../../../../../../../services/documentApis";
import { dateFormatWithTimeChange } from "../../../../../../../utility/fomatters";
import { ErrorMessageComponent } from "../../../../../ErrorMessageComponent";
import { MainSectionsPreviewWrapper } from "../../../../../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../../../../../ProgressComponent/ProgressComponent";
// import { DocumentPreviewRow } from "./DocumentPreviewRow";

import { grey } from "@mui/material/colors";
import { applicationDocumentStatus } from "../../../../../../../configs/constants/contants";
import { privilegeKeys } from "../../../../../../../configs/constants/privilegeKeys";
import { appSetting, multipleDisbursementState, userDetails } from "../../../../../../../configs/mainStore";
import { documentKeys } from "../../../../../../../configs/revokeDocumentKeys";
import AppConfig from "../../../../../../../utility/AppConfig";
import { disableAccess, multipleDisbursementDocApprovedPermitted, unclassifiedDisableAccess } from "../../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { readStaticDocumentPath, userName } from "../../../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../../GlobalModal/GlobalModal";
import { addToaster, Toast } from "../../../../../GlobalToast";


export const BusinessAuthCertificateDocumentPreviewComponent: FC = (params?: any) => {
  const appSettings: any = useState(appSetting);
  const { previewedDocument, documentList: documents }: any = documentUploadData.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();

  const [previewLoading, setPreviewLoading] = React.useState(true);
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<any>(null);
  const [documentMeta, setDocumentMeta] = React.useState<any>(null);

  const [documentHistoryList, setHistoryList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const postDisbursementTabKeys: any = ["post_disbursements_documents", "post_condition_documents", "post_disbursement_checklist"];
  const [fileDetails, setFileDetails] = React.useState(params?.uploadDataObject?.file || { ...params?.uploadDataObject });
  const [nextDocument, setNextDocument]: any = React.useState(null);
  const isRevokePrivilages = documentKeys.includes(params.documentKey);
  const multipleDisbursement: any = useState(multipleDisbursementState);
  const [progress, setProgress] = React.useState<number>(0);

  let isHavePermission = () => {
    const documentList = multipleDisbursement?.securityDocuments.attach(Downgraded).get();

    if (documentList != undefined && documentList != null) {
      if (multipleDisbursement.isHaveMultipleDisbursement && documentList?.includes(fileDetails?.documentKey)) {
        return multipleDisbursementDocApprovedPermitted(fileDetails?.disbStatus, privilegeKeys.SEC_DOC_APPROVE_REJECT_DOC, fileDetails?.documentKey);
      }
    }

    if (isRevokePrivilages) {
      return unclassifiedDisableAccess();
    }

    return disableAccess({
      privilegeKeyforSec: "SEC_DOC_APPROVE_REJECT_DOC",
      privilegeKeyforOther: "DOC_MANAGE_SYSTEM_APPROVE_REJECT_DOCUMENT",
      documentComponentId: postDisbursementTabKeys.includes(params.documentComponentId) ? params.documentComponentId : previewedDocument?.documentId,
    });
  };

  useEffect(() => {
    setPreviewLoading(false);
    launchDocument();
  }, [fileDetails]);

  useEffect(() => {
    return () => {
      params.callBackOnAction && params.callBackOnAction();
    }
  }, []);

  const launchDocument = async () => {
    setPreviewLoading(true);

    const { filePath, fileName } = fileDetails

    if (filePath && fileName) {
      const blobUrl = readStaticDocumentPath(`${filePath}/${fileName}`);

      setDocumentBlobUrl(blobUrl);
      // setDocumentMeta(fileMeta);
      setErrorMessage("");
    } else {
      setErrorMessage("No associated file found.");
    }

    // if (fileMeta?.history) {
    //   setHistoryList(fileMeta.history);
    // }

    setPreviewLoading(false);

    readNextDocument();
  }

  const previewClicked = async (blobUrl, e = null) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const loadNextDocument = () => {
    const currentIndex = documents?.findIndex((doc: any) => doc.documentId === fileDetails?.documentId);
    const nextIndex = currentIndex + 1;
    const nextDocument = documents?.[nextIndex];
    if (!nextDocument) {
      return;
    }
    nextDocument.variant = null;
    nextDocument.fileDetails = null;
    if (typeof nextDocument?.EN === 'string') {
      nextDocument.individualId = nextDocument.individualId;
      nextDocument.EN = JSON.parse(nextDocument?.EN || "{}");
    }
    setFileDetails(nextDocument);
  }

  const readNextDocument = () => {
    const currentIndex = documents?.findIndex((doc: any) => doc.documentId === fileDetails?.documentId);
    const nextIndex = currentIndex + 1;
    const nextDocument = documents?.[nextIndex];
    if (typeof nextDocument?.EN === 'string') {
      nextDocument.individualId = nextDocument.individualId;
      nextDocument.EN = JSON.parse(nextDocument?.EN || "{}");
    }
    setNextDocument(nextDocument);
  }

  const statusUpdateClicked = async (status) => {
    try {
      setUpdateLoading(true);
      const result = await updateApplicationDocumentStatus({
        applicationId: previewedDocument?.applicationId,
        documentId: fileDetails?.documentId || previewedDocument?.documentId,
        participantId: fileDetails?.individualId || previewedDocument?.participantId || previewedDocument?.individualId,
        isPrimary: previewedDocument?.isPrimary,
        applicantType: previewedDocument?.applicantType,
        status: status,
        verifiedBy: userId,
        fileName: fileDetails?.fileName,
      });

      if (result?.updatedTime) {
        Toast.success("Status updated successfully.");
        setDocumentMeta({ ...documentMeta, status: status, verifiedBy: result?.VERIFIED_BY, verifiedDate: result?.VERIFIED_DATE });
      } else {
        throw new Error("Update error");
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Update Error",
        message: "Something went Wrong",
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const BuildProgress = (progress: number) => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={"500px"}>
        <CircularProgressComponent variant={progress < 10 ? undefined : "determinate"} size={28} value={progress} />
      </Box>
    );
  };

  return (
    <Stack>
      <Paper elevation={0}>
        {errorMessage ? (
          <Stack direction={"column"}>
            <ErrorMessageComponent headMessage={fileDetails?.EN?.name + (AppConfig.control ? " (" + fileDetails?.documentId + ")" : "")} errorMessage={errorMessage} />
            <Stack direction={"row"} p={2} alignItems={"center"} justifyContent={"center"}>
              <ButtonComponent
                title={"Skip"}
                variant="outlined"
                size={"small"}
                onClick={() => {
                  loadNextDocument();
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction={"row"} p={2}>
            <Stack m={2} flex={1}>
              {previewLoading ? BuildProgress(progress) : documentBlobUrl && <Stack flex={1} height={"100%"}><iframe onContextMenu={(e) => e.preventDefault()} style={{ border: "none" }} src={documentBlobUrl} height={"100%"} width={"100%"} title="Document Preview" ></iframe></Stack>}
              {<Stack mt={2} direction={"row"} justifyContent={"space-between"}>
                {documentBlobUrl && <ButtonComponent
                  title={"Preview"}
                  variant="outlined"
                  size={"small"}
                  onClick={() => {
                    previewClicked(documentBlobUrl);
                  }}
                />}
                {fileDetails?.variant !== "crib" && nextDocument && <ButtonComponent
                  title={nextDocument?.EN?.name}
                  variant="outlined"
                  size={"small"}
                  onClick={() => {
                    loadNextDocument()
                  }}
                />}
              </Stack>}
            </Stack>
            <Stack width={"1px"} borderRight={1} borderColor={grey[300]} mr={1}></Stack>
            <Stack flex={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <h4 className="basic-font" style={{ marginBottom: 0 }}>
                    {fileDetails?.EN?.name || "Current File"} Details
                  </h4>
                  <Typography variant="caption" color={"gray"}>{fileDetails?.EN?.desc || ""} {AppConfig.control && fileDetails?.documentId}</Typography>
                </Stack>
                {previewLoading && (
                  <Stack pt={2}>
                    <CircularProgressComponent size={20} />
                  </Stack>
                )}
              </Stack>
              <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
              <Box sx={{ boxShadow: 1 }}>
                <Stack direction={"row"} justifyContent={"space-between"} p={2} alignItems={"center"}>
                  {/* <DocumentPreviewRow Icon={CreditCardIcon} subTxt={"Status"} mainTxt={FILE_STATUS[documentMeta?.status]} status={documentMeta?.status} /> */}
                  {documentMeta?.status === applicationDocumentStatus.pending && (
                    <Stack>
                      {previewLoading || updateLoading &&
                        <Stack pt={2} direction={"row-reverse"} flex={1}>
                          <CircularProgressComponent size={20} />
                        </Stack>}
                      {!previewLoading && !updateLoading && <Stack direction={"row"} spacing={2}>
                        <div
                          className={`basic-font font-size-14 approve-chip ${isHavePermission() ? "privilege-disable" : ""}`}
                          onClick={() => {
                            statusUpdateClicked(applicationDocumentStatus.approved);
                          }}
                        >
                          Approve
                        </div>
                        <div
                          className={`basic-font font-size-14 reject-chip ${isHavePermission() ? "privilege-disable" : ""}`}
                          onClick={() => {
                            statusUpdateClicked(applicationDocumentStatus.rejected);
                          }}
                        >
                          Reject
                        </div>
                      </Stack>
                      }
                    </Stack>
                  )}
                </Stack>

                <Divider />

                <Stack p={2} direction={"row"} flexWrap={"wrap"} gap={2}>
                  {/* <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={documentMeta?.uploadedBy ? getUserDetailsById(documentMeta?.uploadedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                  <DocumentPreviewRow Icon={PersonIcon} subTxt={"Verified By"} mainTxt={documentMeta?.verifiedBy ? getUserDetailsById(documentMeta?.verifiedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                  <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={documentMeta?.uploadedDate ? dateFormatWithTimeChange(documentMeta?.uploadedDate) : "--"} status={documentMeta?.status} />
                  <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Verified Date"} mainTxt={documentMeta?.verifiedDate ? dateFormatWithTimeChange(documentMeta?.verifiedDate) : "--"} status={documentMeta?.status} /> */}
                </Stack>
              </Box>
              <Stack>
                <Stack direction={"row"} justifyContent={"space-between"} mb={1}>
                  <h4 className="basic-font" style={{ marginBottom: 0 }}>
                    {fileDetails?.EN?.name || "Current File"} History
                  </h4>
                </Stack>
                <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                {!documentHistoryList.length ? (
                  <ErrorMessageComponent headMessage={""} errorMessage={"No history found."} showImg={false} />
                ) : (
                  <Stack style={{ overflowY: "auto", height: "200px" }}>
                    {documentHistoryList.map((item, index) => {
                      const archivedByInfo = `Archived by ${userName(item?.ARCHIVED_BY)}`;
                      return <MainSectionsPreviewWrapper title={item?.UPLOADED_DATE && dateFormatWithTimeChange(item?.UPLOADED_DATE)} subTitle={archivedByInfo} key={index} openFile={() => previewClicked(readStaticDocumentPath(item?.FILE_PATH))} code={index} status={item?.STATUS === 1 ? "success" : item?.STATUS === 2 ? "error" : "info"}>
                        <Grid container className="align-center row_list_item" mt={2} columns={24}>
                          {/* <DocumentPreviewHistoryRow subTxtOne="Uploaded By" mainTxtOne={item?.UPLOADED_BY && getUserDetailsById(item?.UPLOADED_BY)[appSettings.get().language]?.name} subTxtTwo="Uploaded Date" mainTxtTwo={item?.UPLOADED_DATE ? dateFormatWithDate(item?.UPLOADED_DATE) : "--"} />
                          <DocumentPreviewHistoryRow subTxtOne="Verified By" mainTxtOne={item?.VERIFIED_BY ? getUserDetailsById(item?.VERIFIED_BY)[appSettings.get().language]?.name : "--"} subTxtTwo="Verified Date" mainTxtTwo={item?.VERIFIED_DATE ? dateFormatWithDate(item?.VERIFIED_DATE) : "--"} />
                          <DocumentPreviewHistoryRow subTxtOne="Archive By" mainTxtOne={item?.ARCHIVED_BY && getUserDetailsById(item?.ARCHIVED_BY)[appSettings.get().language]?.name} subTxtTwo="Archive Date" mainTxtTwo={item?.ARCHIVED_DATE ? dateFormatWithDate(item?.ARCHIVED_DATE) : "--"} /> */}
                        </Grid>
                      </MainSectionsPreviewWrapper>;
                    })}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Paper>
      <Stack direction={"row-reverse"} borderTop={"1px solid lightgray"} pt={2}>
        <ButtonComponent
          title={"Close"}
          variant="outlined"
          onClick={() => {
            closeGlobalModal()
          }}
        />
      </Stack>
    </Stack>
  );
};
