import { Downgraded, useState } from "@hookstate/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Paper } from "@mui/material";
import React, { FC, useEffect } from "react";
import { bufferToBlobUrl } from "../../../configs/base64Conversion";
import { getDisbursementDocumentFileData, updateDisbursementDocumentStatus } from "../../../services/documentApis";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { FILE_STATUS } from "../../../configs/constants/documentUploadKeys";
import { getUserDetailsById } from "../../../services/userService";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../utility/fomatters";
import { ErrorMessageComponent } from "../ErrorMessageComponent";
import { MainSectionsWrapper } from "../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { DocumentPreviewHistoryRow, DocumentPreviewRow } from "./DocumentPreviewRow";

import { applicationDocumentStatus } from "../../../configs/constants/contants";
import { appSetting, userDetails } from "../../../configs/mainStore";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../GlobalToast";

export const DisbursementDocumentPreviewComponent: FC = (params?: any) => {
  const appSettings: any = useState(appSetting);

  const { userId }: any = userDetails.attach(Downgraded).get();

  const [previewLoading, setPreviewLoading] = React.useState(true);
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<any>(null);
  const [documentMeta, setDocumentMeta] = React.useState<any>(null);
  const [documentContentType, setDocumentContentType] = React.useState<any>(null);

  const [previewHistoryLoading, setPreviewHistoryLoading] = React.useState(false);
  const [documentHistoryList, setHistoryList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);

  useEffect(() => {
    (async () => {
      setPreviewLoading(true);

      const result = await getDisbursementDocumentFileData({
        applicationId: params.fileDetails?.APPLICATION_ID,
        productDocId: params.fileDetails?.PRODUCT_DOC_ID,
        disbIndex: params.fileDetails?.DISB_INDEX,
        docIndex: params.fileDetails?.DOC_INDEX,
      });

      if (result?.fileData && result?.contentType) {
        const fileBuffer = result?.fileData.data;
        const contentType = result?.contentType;

        const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

        setDocumentBlobUrl(blobUrl);
        setDocumentMeta(result?.fileMeta);
        setDocumentContentType(contentType.split("/")[0]);
      } else {
        setErrorMessage("File not Found!");
      }

      setPreviewLoading(false);
    })();
  }, [updateSuccess]);

  const previewClicked = (blobUrl) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const statusUpdateClicked = async (status) => {
    setUpdateLoading(true);
    const result = await updateDisbursementDocumentStatus({
      approvedBy: userId,
      status: status,
      docIndex: params.fileDetails.DOC_INDEX,
      disbIndex: params.fileDetails.DISB_INDEX,
      applicationId: params.fileDetails.APPLICATION_ID,
      productDocId: params.fileDetails.PRODUCT_DOC_ID,
    });

    if (result) {
      if (result?.data) {
        setUpdateSuccess(!updateSuccess);
        params.callBackOnAction();
      } else {
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: result,
        });
      }
    } else {
      addToaster({
        status: "error",
        title: "Update Error",
        message: "Something went Wrong",
      });
    }
    setUpdateLoading(false);
  };

  return (
    <Paper elevation={0} sx={{ minHeight: "70vh" }}>
      {previewLoading ? (
        <CircularProgressComponent size={30} sx={{ top: "50%", left: "50%", position: "absolute", zIndex: 100 }} />
      ) : errorMessage ? (
        <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
      ) : (
        <Grid container direction="column" sx={{ minHeight: "60vh" }}>
          <Grid container item spacing={2} direction="row" xs={12}>
            <Grid item xs={12} lg={5} style={{ borderRight: "1px solid #454c661a" }}>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ backgroundColor: "#ffffff", borderRadius: "5px", textAlign: "center" }}>
                <Grid item xs={6} justifyContent="center" style={{ padding: "30px 0" }}>
                  <ButtonComponent
                    startIcon={<VisibilityIcon />}
                    title={"Preview Document"}
                    variant="contained"
                    onClick={() => {
                      previewClicked(documentBlobUrl);
                    }}
                  />
                </Grid>
              </Grid>
              {documentContentType === "image" && <img src={documentBlobUrl} style={{ width: "100%" }}></img>}
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container item spacing={1} direction="row" xs={12}>
                <Grid item xs={12} lg={12}>
                  <h4 className="basic-font" style={{ marginBottom: 0 }}>
                    Current File Details
                  </h4>
                  <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                  <Box sx={{ boxShadow: 1 }}>
                    {updateLoading ? (
                      <Grid container className="align-center row_list_item align-center" mt={2} columns={12}>
                        <Grid container item xs={12} sm={12} direction="column" justifyContent="center">
                          <CircularProgressComponent size={30} sx={{ zIndex: 100, margin: "auto" }} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                        <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                          <DocumentPreviewRow Icon={CreditCardIcon} subTxt={"Status"} mainTxt={FILE_STATUS[documentMeta?.status]} status={documentMeta?.status} />
                        </Grid>
                        {documentMeta?.status === applicationDocumentStatus.pending ? (
                          <>
                            <Grid container item xs={12} sm={6} direction="column" justifyContent="center">
                              <div
                                className={`basic-font font-size-14 approve-chip`}
                                onClick={() => {
                                  statusUpdateClicked(applicationDocumentStatus.approved);
                                }}
                              >
                                Approve
                              </div>
                            </Grid>
                            <Grid container item xs={12} sm={6} direction="column" justifyContent="center">
                              <div
                                className={`basic-font font-size-14 reject-chip`}
                                onClick={() => {
                                  statusUpdateClicked(applicationDocumentStatus.rejected);
                                }}
                              >
                                Reject
                              </div>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    )}
                    <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={documentMeta?.uploadedBy ? getUserDetailsById(documentMeta?.uploadedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={documentMeta?.uploadedDate ? dateFormatWithTimeChange(documentMeta?.uploadedDate) : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Verified By"} mainTxt={documentMeta?.verifiedBy ? getUserDetailsById(documentMeta?.verifiedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Verified Date"} mainTxt={documentMeta?.verifiedDate ? dateFormatWithTimeChange(documentMeta?.verifiedDate) : "--"} status={documentMeta?.status} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item spacing={1} direction="row" xs={12}>
                {previewHistoryLoading ? (
                  <CircularProgressComponent size={30} sx={{ top: "50%", left: "50%", position: "absolute", zIndex: 100 }} />
                ) : (
                  <Grid item xs={12} lg={12}>
                    <h4 className="basic-font" style={{ marginBottom: 0 }}>
                      File History
                    </h4>
                    <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                    {documentHistoryList.length < 1 ? (
                      <ErrorMessageComponent headMessage={"No Data"} errorMessage={"No History data"} showImg={false} />
                    ) : (
                      <Box style={{ maxHeight: "35vh", overflowY: "auto" }}>
                        {documentHistoryList.map((item, index) => {
                          return (
                            <MainSectionsWrapper title={item?.uploadedDate && dateFormatWithTimeChange(item?.uploadedDate)} subTitle="Click to get file details" keyCode={index} noBtn={true} icon={false} status={item?.status === 1 ? "success" : item?.status === 2 ? "error" : "info"} file={item} isDocument={true}>
                              <Grid container className="align-center row_list_item" mt={2} columns={24}>
                                <DocumentPreviewHistoryRow subTxtOne="Uploaded By" mainTxtOne={item?.uploadedBy && getUserDetailsById(item?.uploadedBy)[appSettings.get().language]?.name} subTxtTwo="Uploaded Date" mainTxtTwo={item?.uploadedDate ? dateFormatWithDate(item?.uploadedDate) : "--"} />
                                <DocumentPreviewHistoryRow subTxtOne="Verified By" mainTxtOne={item?.verifiedBy ? getUserDetailsById(item?.verifiedBy)[appSettings.get().language]?.name : "--"} subTxtTwo="Verified Date" mainTxtTwo={item?.verifiedDate ? dateFormatWithDate(item?.verifiedDate) : "--"} />
                                <DocumentPreviewHistoryRow subTxtOne="Archive By" mainTxtOne={item?.archivedBy && getUserDetailsById(item?.archivedBy)[appSettings.get().language]?.name} subTxtTwo="Archive Date" mainTxtTwo={item?.archivedDate ? dateFormatWithDate(item?.archivedDate) : "--"} />
                              </Grid>
                            </MainSectionsWrapper>
                          );
                        })}
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
