import { months } from "../../../../../../SalesSupportCalculator/constant";


export const grossProfitDataKeys = [
  {
    title: "Revenue / Income / Sales",
    dataKey: "revenueIncome",
    type: "textBox",
  },

  {
    title: "Deductions/ Cost of Goods",
    dataKey: "reductions",
    type: "textBox",
  },
  {
    title: "Gross Profit",
    dataKey: "grossProfit",
    type: "autoPopulated",
  },

];

export const expensesDataKeys = [
  {
    title: "Employee Expenses",
    dataKey: "employeeeExpenses",
    type: "textBox",
  },
  {
    title: "Professional Service Expense",
    dataKey: "professionalServiceExpense",
    type: "textBox",
  },
  {
    title: "Banking and Finance Expenses",
    dataKey: "bankingAndFinanceExpenses",
    type: "textBox",
  },
  {
    title: "General Business Expenses",
    dataKey: "generalBusinessExpenses",
    type: "textBox",
  },
  {
    title: "Vehicle Expenses",
    dataKey: "vehicleExpenses",
    type: "textBox",
  },
  {
    title: "Other Financial Expenses",
    dataKey: "otherExpenses",
    type: "textBox",
  },
];

export const taxDataKeys = [
  {
    title: "Net Profit Before Tax",
    dataKey: "netProfitBeforeTax",
    type: "autoPopulated",
  },
  {
    title: "Taxes",
    dataKey: "taxes",
    type: "textBox",
  },
  {
    title: "Net Profit After Tax",
    dataKey: "netProfitAfterTax",
    type: "autoPopulated",
  },
];

export const profitAndLossAccountsDescriptions = [
  ...grossProfitDataKeys,
  ...expensesDataKeys,
  ...taxDataKeys,
];

type PAndLDescriptions = { title: string; dataKey: string; type: string };

export const pAndLDescriptions: PAndLDescriptions[] = [
  ...grossProfitDataKeys,
  {
    title: "Administrative Expenses",
    dataKey: "administrativeExpenses",
    type: "textBox",
  },
  {
    title: "Selling & Distribution Expenses",
    dataKey: "sellingDistributionExpenses",
    type: "textBox",
  },
  {
    title: "Net Profit Before Interest and Depreciation",
    dataKey: "netProfitBeforeInterestDepreciation",
    type: "autoPopulated",
  },
  {
    title: "Depreciation",
    dataKey: "depreciation",
    type: "textBox",
  },
  {
    title: "Net Profit After Interest and Depreciation",
    dataKey: "netAfterDepriciation",
    type: "autoPopulated",
  },
  {
    title: "Taxes",
    dataKey: "taxes",
    type: "textBox",
  },
  {
    title: "Net Profit After Tax",
    dataKey: "netProfitAfterTax",
    type: "autoPopulated",
  },
]

export const assetDataKeys = [
  {
    title: "Total Assets",
    dataKey: "totalAssets",
    type: "autoPopulated",
  },
];

export const liabilityDataKeys = [
  {
    title: "Total Liabilities",
    dataKey: "totalLiabilities",
    type: "autoPopulated",
  },
  {
    title: "Equity",
    dataKey: "equity",
    type: "autoPopulated",
  },
];

export const balanceSheetData = [...assetDataKeys, ...liabilityDataKeys];

export const proposedColumnData = {
  title: "Proposed",
  dataKey: "proposed",
};

export const salesAndPurchaseInformationContainer = {
  headerTitleMap: [{
    title: "Month",
    dataKey: "month",
    type: "select",
    options: months,
    dependancy: {
      visible: 1,
      mandatory: 0
    }

  },
  {
    title: "Cash Basis",
    dataKey: "cashBasis",
    type: "textBox",
    dependancy: {
      visible: 1,
      mandatory: 0
    }
  },

  {
    title: "Credit Basis",
    dataKey: "creditBasis",
    type: "textBox",
    dependancy: {
      visible: 1,
      mandatory: 0
    },
  },

  {
    title: "Total",
    dataKey: "total",
    type: "autoPopulated",
    calculation: {
      dataKeys: ["cashBasis", "creditBasis"],
      typeOfCal: "add"
    },
    dependancy: {
      visible: 1,
      mandatory: 0
    },
  }

  ],

  constantRowMap: [
    {
      title: "Total",
      dataKey: "lableTotla",
      type: "lable",
      styles: {},
      dependancy: {
        visible: 1,
        mandatory: 0
      }
    },

    {
      title: "",
      dataKey: "cashBasisTotal",
      type: "autoPopulated",
      styles: {},
      calculation: {
        dataKeys: ["cashBasis"],
        typeOfCal: "add"
      },
      dependancy: {
        visible: 1,
        mandatory: 0
      }
    },

    {
      title: "",
      dataKey: "creditBasisTotal",
      type: "autoPopulated",
      styles: {},
      calculation: {
        dataKeys: ["creditBasis"],
        typeOfCal: "add"
      },
      dependancy: {
        visible: 1,
        mandatory: 0
      }
    },

    {
      title: "",
      dataKey: "totalOfTotals",
      type: "autoPopulated",
      styles: {},
      calculation: {
        dataKeys: ["cashBasis", "creditBasis"],
        typeOfCal: "add"
      },
      dependancy: {
        visible: 1,
        mandatory: 0
      }
    },
  ]
}

export const abilityToRepayLoanDataKeys = [
  {
    title: "Revenue / Income",
    dataKey: "revenueIncome",
    type: "autoPopulated",
  },

  {
    title: "Gross Profit",
    dataKey: "grossProfit",
    type: "autoPopulated",
  },
  {

    title: "Net Expenses",
    dataKey: "netExpenses",
    type: "autoPopulated",
  },
  {
    title: "Net Profit Before Interest and Depreciation",
    dataKey: "netProfitBeforeInterestDepreciation",
    type: "autoPopulated",
  },
  {
    title: "Net Profit After Interest and Depreciation",
    dataKey: "netAfterDepriciation",
    type: "autoPopulated",
  },
  {
    title: "Net Profit After Tax",
    dataKey: "netProfitAfterTax",
    type: "autoPopulated",
  },
]