import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const AnalysisItemRowComponent = ({ title, viewAnalysis, columnKey, description, disableStatus }) => {

    return <>
        <Stack padding={2} direction={"row"} justifyContent={"space-between"} flex={2}>
            <Stack flex={1}>
                <div
                    className="basic-font basic-font-color-bold font-size-18"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {title}
                </div>
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} flex={1} spacing={3}>
                {description?.length > 0 ? <Typography variant="body2" color={grey[500]} >
                    <IconButton aria-label="view" onClick={() => viewAnalysis(columnKey, "view", title)} disabled={disableStatus}>
                        <ChromeReaderModeIcon />
                    </IconButton>
                </Typography> : <></>}
                <Typography variant="body2" color={grey[500]}>
                    <IconButton aria-label="edit" onClick={() => viewAnalysis(columnKey, "edit", title)} disabled={disableStatus}>
                        <EditIcon />
                    </IconButton>
                </Typography>
            </Stack>
        </Stack>
        <Stack pb={2}>
            <Stack ml={2} direction={"row"} spacing={2}>
                <div
                    className="basic-font basic-font-color opacity-7 font-size-14"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {description}
                </div>
            </Stack>
            {description.length === 150 ? <Stack pl={2}>
                <Box
                    onClick={() => viewAnalysis(columnKey, "view", title)}
                    className={`application-manager_application-id`}
                    style={{ textAlign: "left" }}
                >
                    view more
                </Box>
            </Stack> : <></>}
        </Stack>

    </>
}

export default AnalysisItemRowComponent;