import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../components/InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";
import { toast } from "../../../../components/OtherComponents/GlobalToast";
import { updateProductFormDependenciesData } from "../../../../services/productApis";
import { closeBtn } from "../../../../styles";

const ProductFormDependencies = (params) => {
    const initialData = { field: params?.item?.fieldDependency || "", section: params?.item?.sectionDependency || "" };
    const showErrors = useState(false);
    const taskState: any = useState(initialData);

    const saveTask = async (params) => {
        const formId = params?.singleSelection;
        const productId = params?.item?.productId;

        const payload = {
            formId: formId,
            productId: productId,
            dependencyData: taskState.get()
        }

        const response = await updateProductFormDependenciesData(payload)

        if (response?.status === -1) {
            toast({ message: "Failed to proceed!", status: "error" });
        } else {
            toast({ message: "Successfully updated." });
        }
        params?.callback()
    };

    const closeTask = () => {
        closeGlobalModal();
    };

    const validateSave = () => {
    };

    const resetError = () => {
        if (showErrors.get()) {
            showErrors.set(false);
        }
    };

    return (
        <Box>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <div className="basic-font font-size-18 basic-font-color-bold">Dependency</div>
            </Stack>

            <Grid container spacing={2} mt={1} className="modal-text-field">
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="Field Dependencies"
                        component={
                            <TextBoxComponent
                                value={taskState?.field?.get()}
                                onChange={(e) => {
                                    resetError();
                                    taskState.field.set(e.target.value);
                                }}
                                variant="outlined"
                                size="small"
                                multiline={true}
                                maxRows={4}
                                rows={4}
                                inputProps={{ maxLength: 4000 }}
                            />
                        }
                        isMandatory={false}
                        showErrors={showErrors.get()}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="Section Dependencies"
                        component={
                            <TextBoxComponent
                                value={taskState?.section?.get()}
                                onChange={(e) => {
                                    resetError();
                                    taskState.section.set(e.target.value);
                                }}
                                variant="outlined"
                                size="small"
                                multiline={true}
                                maxRows={4}
                                rows={4}
                                inputProps={{ maxLength: 4000 }}
                            />
                        }
                        isMandatory={false}
                        showErrors={showErrors.get()}
                    />
                </Grid>
            </Grid>

            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
                <ButtonComponent
                    startIcon={<DoneIcon />}
                    title="Save"
                    variant="contained"
                    onClick={() => saveTask(params)}
                    // disabled={disableSave()}
                    loadingbtn={true}
                    color="info"
                />
            </Stack>
        </Box>
    );
};

export default ProductFormDependencies;
