import {
  Grid,
  Typography
} from "@mui/material";
import { OnDemandValueFormatter } from "../../../../../services/onDemandValidators";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { InfoToolTip } from "../../../Info";
import { Controller } from "./Controller";

export const Summary = ({ data, credit, masterData, productMasterData }) => {
  const controller = new Controller(data, credit, masterData, productMasterData);

  const BuildRow = ({ data }) => {
    const value = data.round ? data.value : OnDemandValueFormatter.thousandSeparator(parseFloat(data.value).toFixed(2));
    return (
      <Grid container direction={"row"} p={1}>
        <Grid item sm={6} py={!data.key ? 1.5 : 0}>
          <InfoToolTip data={data.tooltip}>
            <Typography variant="subtitle2">{data?.key.trim().length === 0 ? "" : data.key + " 🛈"}</Typography>
          </InfoToolTip>
        </Grid>
        <Grid item sm={6} container direction={"row"} justifyContent="flex-end" alignItems="baseline">
          {value === "NaN" ? "" : value}
        </Grid>
      </Grid>
    );
  };

  const BuildHeader = ({ label }) => {
    return (
      <Grid
        container
        p={1}
        className="border-radius-5 basic-font basic-font-color-bold font-size-16"
        style={{ backgroundColor: "#e6f0fa", display: "flex" }}
      >
        <Typography>{label}</Typography>
      </Grid>
    );
  };

  const BuildSectionHeader = ({ label }) => {
    return (
      <Grid className="basic-font basic-font-color-bold font-size-16 align-left data-box" p={1}>
        {label}
      </Grid>
    );
  };

  const FilterSalaryPropFromSelectedProducts = () => {
    let loanSummaryArr: any = [];

    if (isProductOf()) {
      loanSummaryArr = controller.loanSummary.filter((obj) => obj["key"] !== "Salary Proportion");
    } else {
      loanSummaryArr = controller.loanSummary.filter((obj: any) => !["existingContinuedFacilityOutstandingTotalBalance", "totalExposureLimit"].includes(obj?.id || ""));
    }

    return (
      <>
        {loanSummaryArr.map((obj, index) => {
          return <BuildRow data={obj} key={index}></BuildRow>;
        })}
      </>
    );
  };

  return (
    <Grid className="data-box">
      <BuildHeader label={"Income and Expense Details"}></BuildHeader>
      <Grid container spacing={2} my={2} pl={4} justifyContent="start" alignItems="baseline">
        <Grid item xs={6} lg={4}>
          <BuildSectionHeader label={"Income"} />

          <Grid className={"data-box"}>
            {controller.incomeDetails.map((obj, index) => {
              return <BuildRow data={obj} key={index}></BuildRow>;
            })}
          </Grid>
        </Grid>
        <Grid item xs={6} lg={4}>
          <BuildSectionHeader label={"Expenses"} />

          <Grid className={"data-box"}>
            {controller.expenseDetails.map((obj, index) => {
              return <BuildRow data={obj} key={index}></BuildRow>;
            })}
          </Grid>
        </Grid>
      </Grid>

      <BuildHeader label={"Income and Expense Summary"}></BuildHeader>
      <Grid container spacing={2} my={2} pl={4} justifyContent="start" alignItems="baseline">
        <Grid item xs={6} lg={4}>
          <BuildSectionHeader label={"Income and Expense Summary"} />

          <Grid className={"data-box"}>
            {controller.incomeAndExpenseSummary.map((obj, index) => {
              return <BuildRow data={obj} key={index}></BuildRow>;
            })}
          </Grid>
        </Grid>

        <Grid item xs={6} lg={4}>
          <BuildSectionHeader label={"Loan Summary"} />

          <Grid className={"data-box"}>
            <FilterSalaryPropFromSelectedProducts />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
