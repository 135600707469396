import { useState } from "@hookstate/core";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { loadApplicationFrom } from "../../../../../services/applicationFormApis";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import AddressInformation from "./AddressInformation";
import ApplicationHeader from "./ApplicationHeader";
import AssetsDetails from "./AssetsDetails";
import BankDetails from "./BankDetails";
import BusinessDetails from "./BusinessDetails";
import ContactDetails from "./ContactDetails";
import EducationalDetails from "./EducationalDetails";
import EmploymentDetails from "./EmploymentDetails";
import ExpensesDetails from "./ExpensesData/ExpensesDetails";
import IdentificationDetails from "./IdentificationDetails";
import IncomeDetails from "./IncomeDetails";
import InquiryOfObligations from "./InquiryOfObligations";
import LifeInsuranceDetails from "./LifeInsuranceDetails";
import TaxDetails from "./TaxDetails";

const JointBorrower = ({
  data,
  applicationId,
  mainTabData = { isAppraisal: false },
  isRawVersion = false,
  printMode = false,
  innerComponentData,
  tabsToEdit,
}) => {
  const jointBorrowerArr = data.formData.jointBorrowerData;
  const expandState = useState(false);
  const formSections: any = useState([]);
  const [jointBorrowerDetails, setJointBorrowerDetails] = React.useState<any>({});

  useEffect(() => {
    (async () => {
      const type = data?.formData?.creditData?.type;
      const sector = data?.formData?.creditData?.sector;
      const scheme = data?.formData?.creditData?.scheme;
      const applicantType = data?.formData?.creditData?.applicantType;
      let participantType = 3;

      const structure: any = await loadApplicationFrom(
        type,
        sector,
        scheme,
        applicantType,
        participantType
      );

      formSections.set(structure);
    })();
  }, []);

  useEffect(() => {
    if (jointBorrowerArr?.length > 0) {
      const stateObj = jointBorrowerArr?.map((jointBorrowerData, i) => ({
        [i]: true,
      }));
      expandState.set(stateObj);

      const jointBorrowerDetailsArr = jointBorrowerArr.filter(
        (item?: any) => item?.personalData?.sectionErrorStatus === "success"
      );

      setJointBorrowerDetails(jointBorrowerDetailsArr);
    }
  }, [jointBorrowerArr]);

  const onClickIcon = (e, index) => {
    const prev = expandState[index].get();
    expandState[index].set(!prev);
  };

  const components: any = {
    "primary-applicant-details": IdentificationDetails,
    "contact-details": ContactDetails,
    "address-details": AddressInformation,
    "education-details": EducationalDetails,
    "employment-details": EmploymentDetails,
    "business-details": BusinessDetails,
    "income-details": IncomeDetails,
    "expenses-details": ExpensesDetails,
    "bank-details": BankDetails,
    "assets-details": AssetsDetails,
    "tax-details": TaxDetails,
    "life-insurance-details": LifeInsuranceDetails,
    "inquiry-of-obligations": InquiryOfObligations,
  };

  return (
    <div className="mainSectionWrapper full-width full-height">
      {(!isRawVersion || printMode) && (
        <ApplicationHeader
          formData={data?.formData}
          requestId={data?.requestId}
          applicationId={applicationId}
          title={"Joint Borrower Details"}
          isAppraisal={mainTabData?.isAppraisal}
          isRawVersion={isRawVersion}
          disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
        />
      )}
      <Grid container className="inner-component-height">
        <Grid container p={1} className="full-width full-height">
          {jointBorrowerDetails?.length > 0 &&
            jointBorrowerDetails?.filter(obj => obj)?.map((jointBorrowerData, index) => {
              const name = jointBorrowerData?.personalData?.initialsInFull
                ? jointBorrowerData?.personalData?.initialsInFull
                : "";
              const isExpand = expandState?.get()?.[index];

              if (
                jointBorrowerData?.hasOwnProperty("removedItem") &&
                jointBorrowerData?.["removedItem"] === true
              )
                return;

              return (
                <Grid container key={index} className={`${isExpand ? "data-box" : ""}`}>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ backgroundColor: "#e6f0fa", maxHeight: "55px" }}
                    className="border-radius-5 padding-xs">
                    <Grid item xs={11} className="align-center align-self-center">
                      <div className="basic-font basic-font-color-bold font-size-16 align-left align-self-center">{`Joint Borrower ${name ? "-" + name : ""
                        }`}</div>
                    </Grid>

                    {!isRawVersion && (
                      <Grid item xs={1} className="align-right">
                        <ButtonComponent
                          iconbtn
                          children={
                            isExpand ? (
                              <KeyboardDoubleArrowUpIcon className="basic-font-color" />
                            ) : (
                              <KeyboardDoubleArrowDownIcon className="basic-font-color" />
                            )
                          }
                          onClick={(e) => onClickIcon(e, index)}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} className="padding-xs">
                    <>
                      {isExpand && (
                        <>
                          {formSections.get()?.map((section: any, index: any) => {
                            const Component = components[section?.key];
                            if (section?.key && section.visible && Component) {
                              return (
                                <Component
                                  data={{
                                    formData: { ...jointBorrowerData },
                                  }}
                                  applicationId={applicationId}
                                  isMainContactDetails={false}
                                  key={index}
                                  isJointBurrower={true}
                                  guarantorJBSection={section?.key}
                                />
                              );
                            }
                          })}
                        </>
                      )}
                    </>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};

export default JointBorrower;
