import { Downgraded, useHookstate, useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { getCopy } from "../../../../../../../../../../utility/util";
import { CircularProgressComponent } from "../../../../../../../../ProgressComponent/ProgressComponent";
import {
  expensesDataKeys,
  grossProfitDataKeys,
  pAndLDescriptions,
  taxDataKeys
} from "../../../../Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs";
import { financialPositionOfTheCompanyFinalObject, generalizedProfitAndLoanAccountsData } from "../../../../Stores/MicroAppraisalStore";
import { filterAutoPopulatedKeys } from "../../../../Utilities/Util";
import { onChangeHandler } from "../../Calculations/GenaralizedDataSetter";
import { GeneralizedControllerForForecastedCash } from "../../Calculations/GeneralizedControllerForForecastedCash";
import { generalizedSetStateFromTheBusinessData } from "../../Helpers/SetStateFromBusinessData/Generalize";
import FinancialPositionOfCompanyGeneralizedTable from "./OtherComponents/FinancialPositionOfCompanyGeneralizedTable";

const Generalized = ({ data, typeOfViewMode = undefined, inspectionBusinessData }) => {
  const grossProfitData = filterAutoPopulatedKeys(grossProfitDataKeys);
  const expenses = filterAutoPopulatedKeys(expensesDataKeys);
  const taxes = filterAutoPopulatedKeys(taxDataKeys);
  const generalizedProfitAndLoanAccounts = useHookstate(generalizedProfitAndLoanAccountsData);
  const tots = useState(financialPositionOfTheCompanyFinalObject["totals"]);
  const isLoading = useState(true);
  const years = useMemo(() => generalizedProfitAndLoanAccounts["selectedYears"].get().map(({ title }) => title), [generalizedProfitAndLoanAccounts["selectedYears"].get()]);

  useEffect(() => {
    try {
      if (inspectionBusinessData) {
        const setFormData = (data) => {
          generalizedProfitAndLoanAccounts["data"].set(getCopy(data))
        }
        generalizedSetStateFromTheBusinessData({ state: setFormData, busineeData: inspectionBusinessData })
      }
    } catch (error) {

    } finally {
      isLoading.set(false);
    }
  }, [data]);

  const getAllCalculations = useMemo(() => {
    const { data, selectedYears } = generalizedProfitAndLoanAccounts.attach(Downgraded).get();

    const generalizedController = new GeneralizedControllerForForecastedCash(data,
      selectedYears,
      grossProfitData,
      expenses,
      taxes
    );

    const totals = {
      grossProfit: generalizedController.getTotalGrossProfit(),
      revenueIncome: generalizedController.totalRevenue,
      netAfterDepriciation: generalizedController.getNetAfterDepriciation(),
      netProfitAfterTax: generalizedController.getTotalNetProfitAfterTaxWithDepriciation(),
      netExpenses: generalizedController.getTotalExpensesOnly(),
      netProfitBeforeInterestDepreciation: generalizedController.getTotalNetProfitBeforeInterestDepreciation(),
    }

    tots.set(JSON.parse(JSON.stringify(totals)));

    return {
      ...totals
    };
  }, [generalizedProfitAndLoanAccounts.get()]);

  const onChange = useCallback((value: number, year, dataKey) => {
    let objectData = onChangeHandler({ value, yearDataKey: year, itemDataKey: dataKey, formData: generalizedProfitAndLoanAccounts["data"].get(), selectedYears: years })
    generalizedProfitAndLoanAccounts["data"].set((prevFormData) => ({ ...prevFormData, ...objectData }));
  }, [generalizedProfitAndLoanAccounts["data"].get()]);

  if (isLoading.get()) return <Stack width={"100%"} justifyContent={"center"} alignContent={"center"}> <CircularProgressComponent size={30} /> </Stack>

  return (
    <FinancialPositionOfCompanyGeneralizedTable
      label={"description"}
      onChange={onChange}
      defaultObject={generalizedProfitAndLoanAccounts["data"].get()}
      profitAndLossAccountsTitleArray={generalizedProfitAndLoanAccounts["selectedYears"].get()}
      descriptionList={pAndLDescriptions} getAllCalculations={getAllCalculations} />
  );
};

export default Generalized;
