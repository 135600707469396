import { FORMSTRUCTURE } from "../../../../../../configs/interfaces";
import { creditData } from "../../../../../../configs/mainStore";
import { messageMappings } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/messageMapping";


export const collateralApplicationFormGetter = (key: string) => {
  const formJson: FORMSTRUCTURE = creditData.get()?.['formStructure'];
  const getApplicationform = formJson?.primaryApplicant;
  let securityFields: Array<any> = [];

  try {
    if (!getApplicationform) {
      throw new Error("No Application Form Found");
    }
    securityFields = getSecuriyFields([getApplicationform?.['security-details']], key)
  } catch (e) {
    console.error(e);
  }

  return securityFields.length > 0 ? securityFields[0] : null;
};

const getSecuriyFields = (itemArray: Array<any>, key: string) => {
  let securityFields: Array<any> = [];

  itemArray.forEach((item) => {
    if (item.key === "security-details") {
      securityFields = securityFields.concat(getSecuriyFields(item.content, key));
    } else if (item.content) {
      securityFields = securityFields.concat(getSecuriyFields(item.content, key));
    } else if (item.key === key) {
      securityFields.push(item.fields);
    }
  });

  return securityFields;
};

export const getMandatoryFields = (fields: object): Array<string> => {
  let intermediateArray: Array<string> = [];

  for (const key in fields) {
    if (fields[key].mandatory === 1) {
      intermediateArray.push(key);
    }
  }
  return intermediateArray;
};

export const handleMandatory = (loanArray, mandatoryKeys): Array<any> => {
  const errorArray: Array<string> = [];
  for (const obj of loanArray) {
    if (obj !== null && !obj.hasOwnProperty("removedItem")) {
      mandatoryKeys.forEach((element: string) => {
        if (obj.hasOwnProperty(element)) {
          if (obj[element] === "" || obj[element] === null) errorArray.push(element);
          if (obj[element] === "Invalid date") errorArray.push(element);
        }
      });
    }
  }

  let uniqueErrorArray: Array<string> = errorArray.reduce(function (acc: Array<string>, curr: string): Array<string> {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);

  return messageMappings(uniqueErrorArray);
};
