import { Downgraded } from "@hookstate/core";
import { loanAmountRanges } from "../../../configs/constants/contants";
import { creditDetails as creditdDataDetails, productDetails } from "../../../configs/mainStore";
import { leadCreditInfoRevampValidationHandler } from "../../../configs/validations/leadvalidations-handlers/LeadCreditDetailsRevampValidationhandler";
import { errorMessageGenerator2 } from "../../../configs/validations/validate-message-generator/errorMessageGenerator";
import { applyProductLevelFieldMeta } from "../../AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { keyMapping, messageMappings } from "./messageMapping";
import { validateWithStructure } from "./structureValidation";

export const creditDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let creditData = creditdDataDetails.attach(Downgraded).get();
  let preparedErrorMessages: string[] = []

  let finalKeyArr: any = [];
  let errorArray = validateWithStructure(structure, state);
  if (errorArray?.length > 0) {
    finalKeyArr = errorArray;
  }

  if (creditData?.type === "") {
    if (!finalKeyArr.includes("productType")) {
      finalKeyArr.push("productType");
    }
  }

  if (creditData?.sector === "") {
    if (!finalKeyArr.includes("sector")) {
      finalKeyArr.push("sector");
    }
  }

  if (creditData?.scheme === "") {
    if (!finalKeyArr.includes("scheme")) {
      finalKeyArr.push("scheme");
    }
  }

  if (creditData?.type == 22) {
    return preparedErrorMessages.concat(messageMappings(finalKeyArr));
  }

  const productCatalog = [creditData?.type, creditData?.sector, creditData?.scheme].join("-");

  let errorArrays: Array<string[]> = loanAmountValidator(finalKeyArr, productCatalog, creditData?.loanAmount, preparedErrorMessages);

  finalKeyArr = errorArrays[0];
  preparedErrorMessages = errorArrays[1];

  if (creditData?.interestRate === "") {
    if (!finalKeyArr.includes("interestRate")) {
      finalKeyArr.push("interestRate");
    }
  }

  if (creditData?.loanRequirement === "" && applyProductLevelFieldMeta({ dataKey: "loanRequirement", productCatalog })) {
    if (!finalKeyArr.includes("loanRequirement")) {
      finalKeyArr.push("loanRequirement");
    }
  }

  if (creditData?.fixedFloatInterestRate === "" && applyProductLevelFieldMeta({ dataKey: "fixedFloatInterestRate", productCatalog }) ) {
    if (!finalKeyArr.includes("fixedFloatInterestRate")) {
      finalKeyArr.push("fixedFloatInterestRate");
    }
  }
  
  if (creditData?.repaymentType === "") {
    if (!finalKeyArr.includes("repaymentType")) {
      finalKeyArr.push("repaymentType");
    }
  }

  if (creditData?.loanCycles === "") {
    if (!finalKeyArr.includes("loanCycles")) {
      finalKeyArr.push("loanCycles");
    }
  }

  if (creditData?.loanTerms === "") {
    if (!finalKeyArr.includes("loanTerms")) {
      finalKeyArr.push("loanTerms");
    }
  }

  if (creditData?.loanFrequency === "") {
    if (!finalKeyArr.includes("loanFrequency")) {
      finalKeyArr.push("loanFrequency");
    }
  }

  if (creditData?.loanPurpose === "") {
    if (!finalKeyArr.includes("loanPurpose")) {
      finalKeyArr.push("loanPurpose");
    }
  }

  if (creditData?.fundSource === "") {
    if (!finalKeyArr.includes("fundSource")) {
      finalKeyArr.push("fundSource");
    }
  }

  const productCatelogKeyData = productDetails?.["productCatalogData"]?.allProductCatalogsWithKeys?.get();
  const errorResponse: Object | number = leadCreditInfoRevampValidationHandler(creditData, productCatelogKeyData);

  if (errorResponse !== 1) {
    preparedErrorMessages = [...preparedErrorMessages, ...errorMessageGenerator2(errorResponse)]
  }

  return preparedErrorMessages.concat(messageMappings(finalKeyArr));
};

const loanAmountValidator = (errorArray: string[], productCatalog: string, creditLoanAmount: string, preparedErrorMessagesArray: string[]) => {

  const productCatelogKeyData = productDetails?.["productCatalogData"]?.allProductCatalogsWithKeys?.attach(Downgraded).get();
  const { default: { max, min } } = loanAmountRanges
  const { MIN_LOAN_AMOUNT: minLoanAmount = min, MAX_LOAN_AMOUNT: maxLoanAmount = max } = productCatelogKeyData[productCatalog];

  if (creditLoanAmount === "") {
    if (!errorArray.includes("loanAmount")) {
      errorArray.push("loanAmount")
      return [errorArray, preparedErrorMessagesArray];
    }
  }

  let loanAmount = parseFloat(creditLoanAmount);

  if (loanAmount <= minLoanAmount) {
    preparedErrorMessagesArray.push(keyMapping["minLoanAmount"]);
    return [errorArray, preparedErrorMessagesArray]
  }

  if (loanAmount > maxLoanAmount) {
    preparedErrorMessagesArray.push(keyMapping["maxLoanAmount"])
    return [errorArray, preparedErrorMessagesArray]
  }

  return [errorArray, preparedErrorMessagesArray];
}
