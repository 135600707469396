import { Grid } from "@mui/material";
import DetailItem from "../../DetailItem";

export const Item = ({ label, value }) => (
  <Grid item xs={3}>
    <DetailItem title={label} value={value} valueFontClass="font-size-14" titleFontClass="font-size-12" />
  </Grid>
);

export const duration = (obj, yearKey, monthKey) => {
  let year = "";
  let month = "";

  if (obj[yearKey] > 0) {
    year = `${obj[yearKey] > 10 ? obj[yearKey] : "0" + obj[yearKey]} Year${obj[yearKey] > 1 ? "s" : ""}`;
  }

  if (obj[monthKey] > 0) {
    month = `${obj[monthKey] > 10 ? obj[monthKey] : "0" + obj[monthKey]} Month${obj[monthKey] > 1 ? "s" : ""}`;
  }

  return `${year} ${month}`;
};

export class RenderingHelper {
  static addItem = (contactDetails, key, val = "", isDateType = false) => {
    return <Item key={key} label={isDateType ? `${RenderingHelper?.LabelsMap?.[key]} (DD/MM/YYYY)` : RenderingHelper?.LabelsMap?.[key] || key} value={val || contactDetails?.[key] || "-"} />;
  };

  static renderingKeys = [];

  static LabelsMap = {
    companyName: "Company Name",
    // "contactTypes": "Contact Types",
    designation: "Designation",
    employerName: "Employer Name",
    employmentStatus: "Employment Status",
    natureOfEmployment: "Nature of Employment",
    employmentTimePeriod: "Number of Years at Employment",
    employmentType: "Employment Type",
    occupation: "Occupation",
    pensionableDate: "Retirement Date",
    duration: "No of Years Till Pension",
    permanentDate: "Permanent Date",
    startDate: "Start Date",
    endDate: "End Date",
    empContactName: "Contact Person",
    empEmailAddress: "Email Address",
    permenantEmployment: "Permanent Employment",
    industryType: "Industry Type",
    pensionableEmployment: "Pensionable Employment",
    noOfYearsToPension: "No of Years Till Pension",
    fullTimeOrPartTime: "Full Time/Part Time",
  };
}
