import CloseIcon from "@mui/icons-material/Close";
import ForwardIcon from "@mui/icons-material/Forward";
import { Grid, MenuItem, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../CommunicationService/CMTextInput";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { saveMasterDataType } from "../../../services/masterDataApis";
import { OnDemandValueFormatter } from "../../../services/onDemandValidators";
import { select_basic } from "../../../styles";
import { SelectComponent } from "../../InputComponents/SelectComponent/SelectComponent";
import { SwitchComponent } from "../../InputComponents/SwitchComponent/SwitchComponent";
import { addToaster } from "../GlobalToast";

interface FieldInterface {
  id: string;
  name: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MasterDataModal(params) {
  const [selectedFieldMapping, setSelectedFields] = React.useState<any[]>([]);
  const [fieldsMapping, setFeildsMapping] = React.useState<Array<FieldInterface>>([]);
  const [parentList, setParentList] = React.useState<any[]>([]);
  const [isJsonMasterData, setIsJsonMasterdata] = React.useState<any>(0);

  const [masterData, setMasterData] = React.useState<any>({
    name: "",
    description: "",
    tableName: "",
    fieldMapping: "",
    parentCode: "",
    isJsonMasterData: isJsonMasterData,
  });

  const [disabled, setDisabled] = React.useState<any>(false);

  useEffect(() => {
    const fieldsMappingArray = [{ id: "parent", name: "Parent" }];

    setFeildsMapping(fieldsMappingArray);

    let newParentList = [];

    if (params?.parentList?.length > 0) {
      newParentList = params?.parentList.map((obj) => {
        return {
          id: obj?.id,
          name: obj.title,
        };
      });
    }

    setParentList(newParentList);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedFieldMapping>) => {
    const {
      target: { value },
    } = event;

    setSelectedFields(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setMasterData((prevState) => {
      return {
        ...prevState,
        fieldMapping: typeof value === "string" ? value.split(",") : value,
      };
    });
  };

  const createMasterDataTypeFn = async () => {
    setDisabled(true);
    const checkMandatory = handleMandatory(masterData);

    if (checkMandatory?.length > 0) {
      addToaster({
        status: "error",
        title: "Please Fill Mandatory Fields !",
        message: checkMandatory.join("\n"),
        manualClosing: true,
      });
      setDisabled(false);
      return;
    }

    const repsonse = await saveMasterDataType(masterData);

    if (repsonse?.status === 1) {
      addToaster({
        status: "success",
        title: "Success",
        message: repsonse?.data,
        manualClosing: true,
      });
      closeGlobalModal();
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: repsonse?.data,
        manualClosing: true,
      });
    }
    setDisabled(false);
  };

  const handleMandatory = (items) => {
    let errors: any = [];

    const { tableName } = items;

    if (items?.name === "") {
      errors.push("Name cannot be empty");
    }

    if (items?.description === "") {
      errors.push("Description cannot be empty");
    }

    if (items?.tableName === "") {
      errors.push(isJsonMasterData ? "JSON file name cannot be empty" : "Table Name cannot be empty");
    }

    if (tableName !== "" && !isJsonMasterData) {
      const parts = tableName.split("_");
      if (parts[0] !== "TBL") {
        errors.push("Table Name format should be 'TBL_{NAME}'");
      }
    }

    return errors;
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  const callBackOnAction = () => {
    params.callBackOnAction();
    closeGlobalModal();
  };

  const enableOrDisableItem = (event) => {
    setIsJsonMasterdata(event);
    setMasterData((prevState) => {
      return {
        ...prevState,
        isJsonMasterData: event,
      };
    });
  }

  return (
    <div>
      <Stack direction={"column"} justifyContent={"space-between"}>
        <Grid
          item
          className="master-data-type-toggle"
          xs={3}
        >
          <div className="basic-font basic-font-color opacity-7 font-size-12">Is Json Maser Data?</div>
          <div>
            <SwitchComponent
              onChange={(e) => {
                enableOrDisableItem(e.target.checked);
              }}
              checked={isJsonMasterData}
            />
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CMTextInput
              desc={`Name`}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    setMasterData((prevState) => {
                      return {
                        ...prevState,
                        name: e.target.value,
                      };
                    });
                  }}
                  multiline={false}
                  value={masterData?.name}
                  disabled={disabled}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CMTextInput
              desc={`Description`}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    setMasterData((prevState) => {
                      return {
                        ...prevState,
                        description: e.target.value,
                      };
                    });
                  }}
                  multiline={false}
                  value={masterData?.description}
                  disabled={disabled}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CMTextInput
              desc={isJsonMasterData ? `JSON File Name` : `Table Name - TBL_{NAME}`}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    setMasterData((prevState) => {
                      return {
                        ...prevState,
                        tableName: e.target.value,
                      };
                    });
                  }}
                  multiline={false}
                  value={masterData?.tableName}
                  disabled={disabled}
                  onInput={OnDemandValueFormatter.alphabaticToUpperUnderscore}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CMTextInput
              desc={`Field Mapping`}
              component={
                <Stack>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedFieldMapping}
                    onChange={handleChange}
                    className="multiple-input-padding"
                    disabled={disabled}
                  >
                    {fieldsMapping.map((obj) => (
                      <MenuItem key={obj?.id} value={obj?.id}>
                        {obj?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CMTextInput
              desc={`Parent Code`}
              component={
                <Stack>
                  <SelectComponent
                    sx={select_basic}
                    value={masterData?.parentCode}
                    values={parentList}
                    onChange={(e) =>
                      setMasterData((prevState) => {
                        return {
                          ...prevState,
                          parentCode: e.target.value,
                        };
                      })
                    }
                    defaultPlaceholder={true}
                    disabled={disabled}
                  />
                </Stack>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={8} sm={8} textAlign="end" mt={1} spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
            <ButtonComponent
              title="Close"
              startIcon={<CloseIcon />}
              variant="contained"
              onClick={closeCondition}
              loadingbtn={true}
            />
            <ButtonComponent
              startIcon={<ForwardIcon />}
              title={"Create"}
              variant="contained"
              onClick={() => {
                createMasterDataTypeFn();
              }}
              disabled={disabled}
              loadingbtn={true}
              loading={disabled}
            />
          </Stack>
        </Grid>
      </Stack>
    </div>
  );
}

export default MasterDataModal;
