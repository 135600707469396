import { Grid } from "@mui/material";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import { LengthItemValidate } from "../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import CreditFileDocumentsViewSection from "../../CreditFileDocumentsViewSection";
import ApplicationHeader from "../ApplicationHeader";
import ExpensesDataChild from "./ExpensesDataChild";

const ExpensesDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const expenseData: any = data?.formData?.expenseData;
  const expenseDataList: any = expenseData?.expenses || [];
  const filteredExpenseDataList: any[] = LengthItemValidate.validatedArray(expenseDataList) || [];
  let label = "Expense Details";

  try {
    const { primaryApplicant = undefined } = data?.formStructure;
    label = primaryApplicant?.[innerComponentData?.id]?.label;
  } catch {
  }

  return (
    <MandatoryFilterComponent
      errorMessage={"No Expense Data"}
      items={filteredExpenseDataList}
      mandatory={["expenseType", "expenseFrequency", "expenseAmount"] as never[]}
      options={{
        isMainContactDetails: isMainContactDetails,
        formData: data?.formData,
        requestId: data?.requestId,
        applicationId: applicationId,
        title: label ? label : "Expense Details",
        isAppraisal: mainTabData?.isAppraisal,
        isRawVersion: isRawVersion,
        disableAccordingToTab: false,
        printMode: printMode,
      }}
    >
      <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
        {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Expense Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
        {!isMainContactDetails && (
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            {label ? label : "Expense Details"}
          </Grid>
        )}

        {filteredExpenseDataList.length > 0 ? (
          <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
            <Grid container>
              <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
                <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
                <ExpensesDataChild expenseDataList={expenseDataList} expenseData={expenseData} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </MandatoryFilterComponent>
  );
};

export default ExpensesDetails;
