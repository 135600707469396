import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { immovableMachineryEquipementDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { ImmovableMachineryEquipement } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Immovable/MachineryAndEquipment/MachineryAndEquipment";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditImmovableMachineryEquipement = ({ data, applicationId, innerComponentData }) => {

  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...immovableMachineryEquipementDefault },
      mainTitle: "Machinery Equipement", parentTitle: 'Immovable',
      parentDataKey: "immovable", dataKey: "immovableMachineryEquipement"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={ImmovableMachineryEquipement} />
  )
};
