import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { warehouseReceiptDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { WarehouseReceipt } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/WarehouseReceipt/WarehouseReceipt";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditWarehouseReceipt = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...warehouseReceiptDefault },
      mainTitle: "Warehouse Receipt", parentTitle: 'Paper Docs',
      parentDataKey: "paperDocs", dataKey: "warehouseReceipt"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={WarehouseReceipt} />
  )
};
