import { Downgraded } from "@hookstate/core";
import { LengthItemValidate } from "../../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { lifeInsuranceDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/life-insurance/lifeInsuranceDetailsValidationOnNext";
import isProductOf from "../../AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { otherValidationHandler } from "../../CustomHooks/useValidateSubmit";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler } from "./structureValidation";

export const lifeInsuranceDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let finalKeyArr: string[] = [];
  const isAthamaru = isProductOf();

  state = state.attach(Downgraded).get();

  state = isAthamaru === true ? state?.lifeInsuranceData : state;

  state = LengthItemValidate.validatedArray(state);

  if (!state.length) {
    return ["Life Insurance Details Section cannot be empty!"]
  }

  let errArrInArrays = formStateArrayTypeHandler({ structure, state });
  finalKeyArr = errArrInArrays?.length ? [...errArrInArrays, ...finalKeyArr] : [...finalKeyArr];

  let preparedErrorMessages = messageMappings(finalKeyArr);

  let otheErrorsResponse = lifeInsuranceDetailsValidationOnNext(state);

  if (otheErrorsResponse !== 1) {
    let responseArray: string[] = otherValidationHandler(otheErrorsResponse);
    preparedErrorMessages = [...preparedErrorMessages, ...responseArray];
  }

  return preparedErrorMessages;
};


