import { useHookstate } from "@hookstate/core";
import { Divider, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { CircularProgressComponent } from "../../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { SearchBox } from "../SearchBox";

export const ComponentMapper = (props) => {
    const [list, setList] = useState(props.items || []);
    const [disabled, setDisabled] = useState(props.disabled || false)
    const filtering = useHookstate(false);

    useEffect(() => {
        setList(props.items);
        setDisabled(props.disabled)
    }, [props.items, props.disabled])

    if (props.loading) {
        return <Stack flex={1} sx={{ height: props.height ?? "calc(100vh - 350px)", overflowY: "auto" }} alignItems={"center"} justifyContent={"center"}>
            <Paper elevation={0}><CircularProgressComponent size={30} /></Paper></Stack>;
    }


    return <Stack flex={1}>
        <Paper elevation={0}>
            <Stack px={2} spacing={2} pt={2}>
                {(props.title || props.children) && <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontWeight={400}>{props.title}</Typography>
                    {props.children}
                </Stack>}
                <SearchBox list={list} setList={setList} filtering={filtering}>
                    <></>
                </SearchBox>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }}></Divider>
                <List sx={{ height: props.height ?? "calc(100vh - 350px)", overflowY: "auto" }}>
                    {Array.isArray(list) ? list.map((item, index) => {
                        let _disabled = false;

                        if (item?.workflowType === 1 && disabled === true) {
                            _disabled = true;
                        } else if (item?.workflowType === 2 && item?.disabled === false) {
                            _disabled = false;
                        }

                        item = Object.keys(item).length && typeof item === 'object' ? item : { name: item }
                        const selected = (props.selections || []).includes(item.id) || props.singleSelection === item.id;
                        if (filtering.get() && !selected) {
                            return <span key={index}></span>;
                        }
                        return <Stack px={2} style={{ cursor: "pointer" }} key={index}>
                            <Stack direction={"row"} flex={1} height={"44px"} py={1} alignItems={"center"} sx={{ backgroundColor: selected ? 'rgba(25, 118, 210, 0.12)' : 'unset' }}>
                                {props.selections && <CheckBoxComponent checked={(props.selections || []).includes(item.id)} onChange={(e) => props.handleSelection(e.target.checked, item)} disabled={_disabled}></CheckBoxComponent>}
                                <ListItem onClick={() => props.singleSelection ? props.handleSingleSelection(item) : null}>
                                    <Stack flex={3} direction={"row"} justifyContent={"space-between"} spacing={2}>
                                        <Stack flex={2}>
                                            <Typography style={{ color: (props.color && props.color(item)) || 'unset' }}>{item.name || ''}</Typography>
                                            {item.desc && <Typography variant="caption" style={{ color: (props.color && props.color(item)) || 'gray' }}>{item.desc}</Typography>}
                                        </Stack>
                                        <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1} justifyContent={"flex-end"}>
                                            {item.id && <Typography color={(props.color && props.color(item)) || 'unset'} variant="caption">{item.id}</Typography>}
                                            {props.actions && <Stack>{props.actions(item)}</Stack>}
                                        </Stack>
                                    </Stack>
                                </ListItem>
                            </Stack>
                            <Divider></Divider>
                        </Stack>;
                    }) : <Typography>Invalid list provided</Typography>}
                </List>
            </Stack>
        </Paper>
    </Stack>;
}
