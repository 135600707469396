import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { vehicleDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { Vehicle } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Movable/Vehicle/Vehicle";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditVehicle = ({ data, applicationId, innerComponentData }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...vehicleDefault },
      mainTitle: "Vehicle", parentTitle: 'Movable',
      parentDataKey: "movable", dataKey: "vehicle"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={Vehicle} />
  )
};
