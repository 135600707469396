import { Downgraded } from "@hookstate/core";
import appStrings from "../../../configs/constants/appStrings";
import { getObjectChanges } from "../../../services/objectValueChanges";
import { isArray, isObject } from "../getObjectTypes";
import { sectionMapping } from "./mappings";

type FieldType = { id: number, visible: number, mandatory: number, key: string }

export const validateWithStructure = (structure, states) => {
  try {
    if (isObject(structure)) {
      structure = [structure];
    }

    let stateDowngraded: any;

    if (states?.get) {
      stateDowngraded = states ? states.attach(Downgraded).get() : [];
    } else {
      stateDowngraded = states ? states : [];
    }

    stateDowngraded = JSON.parse(JSON.stringify(stateDowngraded || {}));

    let errorKeyArr: any = [];
    let subContentErrorArray: any[] = [];

    for (let section in structure) {
      let fields: Record<string, FieldType> = {};

      if (structure[section]?.["visible"] === 0) {
        return;
      }

      let currentSectionData = stateDowngraded[sectionMapping[structure[section]?.["key"]]] ? sectionMapping[structure[section]?.["key"]] : stateDowngraded;

      currentSectionData = currentSectionData ? currentSectionData : {};

      if (structure[section]?.content) {
        structure[section]?.content?.forEach((subSection) => {
          fields = { ...fields, ...subSection?.["fields"] };
        });
      }
      else if (structure[section]?.fields) {
        fields = structure[section]?.fields;
      }

      if (fields?._subContent) {
        subContentErrorArray = validationSubContentHandler(fields?._subContent, currentSectionData);
      }

      fields = Object.entries(fields).reduce((fields = {}, [key, item], index: number) => (item.visible === 1 && item.mandatory === 1) ? { ...fields, [key]: item } : fields, {});

      if (isObject(currentSectionData)) {
        errorKeyArr = [...errorKeyArr, ...validatorHandler(fields, currentSectionData)];
      }

      errorKeyArr = errorKeyArr.concat(subContentErrorArray);
    }

    return errorKeyArr;
  } catch (error) {
    console.error("Validate with structure - structure validation => ", error);
  }
};

export const validatorHandler = (fields, currentSectionState) => {
  const mandatoryKey: string[] = Object.keys(fields);
  const stateKeys: string[] = Object.keys(currentSectionState);

  return mandatoryKey.reduce((errorArray: string[], key: string, number: number) => {

    if (stateKeys.includes(key)) {
      let result = validatedFieldKey(currentSectionState[key], key);
      return result ? [...errorArray, result] : errorArray;
    }

    return errorArray
  }, [])
};

export const comapareValues = (targetField, value, fieldKey, index) => {
  if (targetField?.["visible"] === 1 && targetField?.["mandatory"] === 1) {
    if (getTheFieldKeyIfNeeded(value, fieldKey)) return getTheFieldKeyIfNeeded(value, fieldKey)
  }
};

export const validatedFieldKey = (value, fieldKey) => {
  if (isArray(value) || isObject(value)) return null

  if (value === "" || value === null || value === undefined || value === "null") return fieldKey;

  return null;
}

export const getTheFieldKeyIfNeeded = (value, fieldKey) => {
  if (value === "" || value === null || value === undefined || value === "null") {
    return fieldKey;
  }
  return null;
}

export const identifyEmptyKeys = (targetItem) => {
  let errorArray: Array<any> = [];
  if (isObject(targetItem)) {
    Object.keys(targetItem).forEach((item) => {
      if (isObject(targetItem[item])) {
        const resArray: any = identifyEmptyKeys(item);
        errorArray = [...errorArray, ...resArray];
      } else {
        if (targetItem[item] === "" || targetItem[item] === null || targetItem[item] === undefined || targetItem[item] === "null") {
          errorArray.push(item.toString());
        }
      }
    });
    return errorArray;
  } else if (isArray(targetItem)) {
    for (const item of targetItem) {
      const resArray: any = identifyEmptyKeys(item);
      if (resArray && resArray.length > 0) {
        errorArray = [...errorArray, ...resArray];
        return errorArray;
      } else continue;
    }
  } else return errorArray;
};

export const handleArray = (structure, state, defaultObj, specialSenario = false, initialStateEmpty = false) => {
  let final: any = [];
  try {
    if (specialSenario) {
      final = formStateArrayTypeHandler({ structure, state });

    } else if (initialStateEmpty) {
      let stateDown = JSON.parse(JSON.stringify(state?.attach(Downgraded).get()));
      stateDown?.push(defaultObj);
      for (let i in stateDown) {
        let testData = validateWithStructure(structure, stateDown[i]);
        final = testData;
      }
    } else if (isArray(state) && state.length > 1) {
      for (let i in state) {
        let testData = validateWithStructure(structure, state[i]);
        final = testData;
      }
    } else {
      if (isArray(state) && state.length === 1) {
        let valueChange = getObjectChanges(state, defaultObj);
        if (valueChange?.length > 0) {
          for (let i in state) {
            let testData = validateWithStructure(structure, state[i]);
            final = testData;
          }
        }

        return final;
      }
    }
    return final;
  } catch (error) {
    console.error("Handle array - structure validation =>", error);
  }
};

export const handleObject = (structure, state, defaultObj) => {
  let final: any = [];
  if (isObject(state)) {
    let response = validateWithStructure(structure, state);
    final = response;
  }

  return final;
};

export const formStateArrayTypeHandler = ({ state, structure, sectionKey = "" }) => {
  let finalErrorDataKeyArray: any[] = [];

  try {
    state = state.attach(Downgraded).get();
  } catch (error) { }

  if (!state.length) return ['cantBeEmpty']

  structure = sectionKey ? structure.content.find((item) => appStrings.subSectionKeys?.[item.key] === sectionKey) : structure;

  for (let i in state) {
    let downgradedState = state[i];

    if (downgradedState?.["removedItem"]) continue;

    finalErrorDataKeyArray = validateWithStructure(structure, state[i]);
  }

  return finalErrorDataKeyArray
}

const validationSubContentHandler = (_subContent, state) => {
  try {
    if (Array.isArray(_subContent)) {
      return _subContent.reduce((acc, curr) => {
        let errorArray: any[] = [];

        if (!curr?.visible) return acc;

        const { key } = curr;
        const stateKey = sectionMapping[key];

        const stateData = state?.[stateKey] || {};

        if (Array.isArray(stateData)) {
          errorArray = formStateArrayTypeHandler({ structure: curr, state: stateData });
        } else if (isObject(stateData)) {
          errorArray = validateWithStructure(curr, stateData);

          Object.entries(stateData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              errorArray = [...errorArray, ...formStateArrayTypeHandler({ structure: curr, state: value })];
            }
          })
        }
        return [...acc, ...errorArray];
      }, [])
    }
  } catch (error) {
    console.error("Validation sub content handler - structure validation => ", error);
  }

  return [];
}
