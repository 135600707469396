import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import { endpoints } from "../configs/endpoints";
import Api from "./apiCallsHandler";
const api = Api.getApi();

/**
 *  Load master data for application form
 */
export const loadMasterData = async (language) => {
  try {
    const data = await api.get(endpoints.loadMasterData, {
      language,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getChannelData = async () => {
  try {
    const data = await api.get(endpoints.loadChannelData);
    return data?.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * save generated application from
 */
export const saveAppFormJson = async (formData) => {
  try {
    const data = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/save-app-form-json`, {
      formData,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update generated application from
 */
export const updateAppFromJson = async (formData) => {
  try {
    const data = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/save-app-form-json`, {
      formData,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * get productData
 */
export const getProductData = async (lang) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-product-data`, { lang: lang });
    return data;
  } catch (error) { }
};

/**
 * get product mapping data
 */
export const getProductMappingData = async () => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-product-mapping`, {});
    return data;
  } catch (error) { }
};

/**
 * get pending application
 */

export const getPendingForm = async (requestId, applicationId = "") => {
  try {
    if (!requestId && !applicationId) {
      return null;
    }

    const slug = requestId ? `?requestId=${requestId}` : `?applicationId=${applicationId}`;
    const response = await api.get(`${endpoints?.loadPendingApplication}${slug}`, {});

    return response;
  } catch (error) {
    return null;
  }
};

export const getUserUpdatedPrivilege = async () => {
  try {
    let userId = "";

    const payload = sessionStorage.getItem("session");
    if (!payload) {
      throw new Error("Failed read existing session");
    }

    userId = JSON.parse(payload)?.data?.user?.id;

    if (!userId) {
      throw new Error("Failed to fetch data");
    }
    const response = await api.get(`${endpoints?.getUserUpdatedPrivilege}${userId}`);

    return response;
  } catch (error) {
    return {
      status: -1,
    };
  }
};

/**
 * save application form json section vice
 */
export const saveAppFormDataJson = async (
  requestId,
  objectKey,
  data
  // participantType = null,
  // participantSectionIndex = null
) => {
  if (!requestId) {
    return new Error('Request Id not Found!')
  }

  try {
    const response = await api.post(endpoints?.saveApplicationFormJson, {
      requestId,
      objectKey,
      data: JSON.stringify(data),
      // participantType,
      // participantSectionIndex,
    });

    return response;
  } catch (error) {
    return null;
  }
};

export const deleteApplicationStructureNode = async (requestId, traverse, data) => {
  try {
    const response = await api.post(endpoints?.deleteApplicationsStructureNode, {
      requestId,
      traverse,
      data: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Submit application form
 */
export const submitApplication = async (requestId, sectionName, newJsonData, type, sector, scheme, applicantType, userId, submitMode) => {
  try {
    const response = await api.post(endpoints.submitApplication, {
      requestId,
      newJsonData,
      sectionName,
      type,
      sector,
      scheme,
      applicantType,
      userId,
      submitMode,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update Joint Burrower Individual Ids already added
 */

export const submitBurrowerUpdate = async (requestId, type, sector, scheme, applicantType, userId, submitMode, participant) => {
  try {
    const response = await api.post(endpoints.submitBurrowerUpdate, {
      requestId,
      type,
      sector,
      scheme,
      applicantType,
      userId,
      submitMode,
      participant,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 *  Save application from partially
 */
export const saveDraft = async (data: any) => {
  try {
    const response = await api.post(endpoints.partialSave, data);
    alert(response.data.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 *  Load partially saved application forms APPLICANT_ID numbers
 */
export const loadPartiallySavedForms = async () => {
  try {
    const response = await api.get(endpoints.loadPartiallySavedForms);
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 *  Load selected saved application forms data
 */
export const selectFrom = async (id: any) => {
  try {
    const response = await api.post(endpoints.loadSelectedPartialForms, {
      id,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Create new application form
 */
export const createNewForm = async (formDetails) => {
  try {
    const response = await api.post(endpoints.createApplicationForm, {
      ...formDetails,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Update  application form
 */
export const updateForm = async (formDetails, formId) => {
  try {
    const response = await api.put(endpoints.updateAppForm, {
      formDetails,
      formId,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get all application forms
 */
export const getAppFormList = async () => {
  try {
    const response = await api.get(endpoints.getAppForms, {});
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get all application forms
 */
export const deleteAppForm = async (formId) => {
  try {
    const response = await api.post(endpoints.deleteAppForm, { formId });
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * get productData Int
 */
export const getProductDataInt = async (lang) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-product-data-int`, { lang: lang });
    return data;
  } catch (error) { }
};

/**
 * get application form by id
 */
export const getFormById = async (id) => {
  try {
    const response = await api.get(endpoints?.getFormById, { id });
    return response;
  } catch (error) { }
};

export const getApplicationsFormHistories = async (params) => {
  try {
    const { data = [] } = await api.get(endpoints?.getApplicationsFormHistories, { ...params });
    return data;
  } catch (error) {
    return null;
  }
};

/**
 * get application form by participant type
 */
export const getFormByParticipantType = async (formId, participantId) => {
  try {
    const response = await api.get(endpoints?.getOtherParticipantForm, {
      formId,
      participantId,
    });
    return response;
  } catch (error) { }
};

/**
 * get application form product by id
 */
export const getOtherFormPrdData = async (formId, participantId) => {
  try {
    const response = await api.get(endpoints?.getOtherFormPrdData, {
      formId,
      participantId,
    });
    return response;
  } catch (error) { }
};

/**
 *application form validation service
 */
export const validateCustomerObject = async (data) => {
  try {
    const response = await api.post("http://localhost:8080/decision-engine/field-list/async", {
      ...data,
    });
    return response;
  } catch (error) { }
};

/**
 * get application form product by id
 */
export const applicationFormCount = async (from, to) => {
  try {
    const response = await api.get(endpoints?.applicationFormCounts, {
      from,
      to,
    });
    return response;
  } catch (error) { }
};

export const saveUIJobDataHandler = async (data) => {
  try {
    const response = await api.post(endpoints?.uiJobDataSave, {
      ...data,
    });
    return response;
  } catch (error) { }
};

export const getUnderWrittingPolicyConfigs = async () => {
  try {
    if (sessionStorage.getItem("getUnderWrittingPolicyConfigs")) {
      return JSON.parse(sessionStorage.getItem("getUnderWrittingPolicyConfigs") ?? "{}");
    }
    const response = await api.get(endpoints?.underWrittingPolicyConfigs, {});
    if (response?.status !== 1) {
      return {};
    }

    // sessionStorage.setItem("getUnderWrittingPolicyConfigs", JSON.stringify(response?.data ?? "{}"));
    return response?.data;
  } catch (error) {
    return {};
  }
};

export const getApplicationSummary = async (
  applicationId,
  type,
  productType,
  sector,
  scheme
) => {
  try {
    const response = await api.get(endpoints?.applicationSummary, {
      applicationId,
      type,
      productType,
      sector,
      scheme,
    });
    return response;
  } catch (error) { }
};

export const loadInspectionFormStructure = async (datakey: string) => {
  if (!datakey) {
    return {
      status: -1,
      data: undefined,
    };
  }
  try {
    const response = await api.get(`${endpoints?.getInspectionFormStructure}${datakey}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) { }
};

export const sendFilledInpectionFormData = async (applicationId, inspectionData, id = null) => {
  try {
    if (!applicationId) {
      return -1;
    }
    const respose = await api.post(endpoints.sendInspectionFilledData, {
      applicationId,
      inspectionData,
      id,
    });
    return respose;
  } catch (error) { }
};

export const getFilledPendingInpectionFormData = async (applicationId, section: null | string = null) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        response: {}
      };
    }

    let otherQrParam = "";

    if (section) {
      otherQrParam = `&section=${section}`;
    }

    const { status, data } = await api.get(`${endpoints.getInspectionFilledPendingDate}${applicationId}${otherQrParam}`);

    return {
      status: status,
      response: data,
    };
  } catch (error) { }
};

export const fetchInspectionForm = async (formId) => {
  try {
    if (!formId) {
      return {
        status: -1,
      };
    }

    const response = await api.get(`http://los.xgencredit.com/scws/persistence/api/v1/form/section?dataKey=${formId}`);
    return response;
  } catch (error) { }
};

export const downloadInspectionForm = async (name, url = false) => {
  try {
    if (!name) {
      return {
        status: -1,
      };
    }
    if (url) {
      return `${endpoints.downloadInspectionForm}?name=${name}`;
    }
    const response = await api.get(`${endpoints.downloadInspectionForm}?name=${name}`);
    return response;
  } catch (error) {
    return null;
  }
};

export const getScoreCardGroupData = async () => {
  try {
    const response = await api.get(endpoints?.getScoreCardGroup, {});

    return response;
  } catch (error) { }
};

export const getScoreCardGroupRateFieldMap = async (params) => {
  try {
    const response = await api.get(endpoints?.getScoreCardGroupRateFieldMapApi, { ...params });
    return response;
  } catch (error) { }
};

export const getScoreCardGroupRateFieldSelected = async (params) => {
  try {
    const response = await api.get(endpoints?.getScoreCardGroupRateFieldSelectedApi, { ...params });
    return response;
  } catch (error) { }
};

export const updateScoreCardGroupRateMap = async (params) => {
  try {
    const response = await api.get(endpoints?.updateScoreCardGroupRateMapApi, { ...params });
    return response;
  } catch (error) { }
};

export const addScoreCardGroup = async (dataObj) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/create`, {
      ...dataObj,
    });
    return res;
  } catch (error) { }
};

export const updateScoreCardGroup = async (dataObj) => {
  try {
    const res = await api.patch(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/update`, {
      ...dataObj,
    });
    return res;
  } catch (error) { }
};

export const updateScoreCardFactor = async (params) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/factor/update`, {
      ...params,
    });
    if (res.status === ERROR_CODES.success) {
      return res?.data;
    }

    return null;
  } catch (error) { }
};

export const getScoreCardGroupTemplate = async (params) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/template/get`, {
      ...params,
    });
    if (res.status === ERROR_CODES.success) {
      return res?.data;
    }

    return null;
  } catch (error) { }
};

export const updateScoreCardGroupTemplate = async (params) => {
  try {
    const res = await api.patch(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/template/update`, {
      ...params,
    });
    if (res.status === ERROR_CODES.success) {
      return res?.data;
    }

    return null;
  } catch (error) { }
};

export const applyChecklist = async (ids, oldCatalog, newCatalog,) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/checklist/clone`, {
      ids,
      oldCatalog,
      newCatalog
    })
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteChecklist = async (ids, productCatalog) => {
  try {
    const response = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/products/checklist/clone?ids=${ids}&productCatalog=${productCatalog}`)
    return response;
  } catch (error) {
    return null;
  }
};

export { addWorkflowConfigurations, addWorkflowMetaData, addWorkflowProductMapping, assignTabControlData, getAllMasterData, getWorkflowDataByWorkflowId, getWorkflowMetaData, updateWorkflowConfigurations, updateWorkflowMetaData, updateWorkflowProductMapping } from "./workflowApiCalls";

// export const getCMSDetails =  async() => {
//   try {
//     return await CMSHistoryItems;
//   } catch (err) { }
// }

export const cloneConditions = async (type, oldCatalog, newCatalog, ids, applicantType) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/conditions/clone`, {
      type,
      oldCatalog,
      newCatalog,
      ids,
      applicantType
    })
    return response;

  } catch (error) {
    return null;
  }
}

export const configStageDepartment = async (workflowId, departmentId, stageIds) => {
  try {
    const responce = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/configs/stages/department`, {
      workflowId,
      departmentId,
      stageIds
    })
    return responce;

  } catch (error) {
    return null;
  }
}

export const getStageDepartmentMapping = async (workflowId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/configs/stages/department?workflowId=${workflowId}`)
    return response;

  } catch (error) {
    return null;
  }
}

export const configStageTask = async (payload) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/configurations/stages/tasks`, payload)
    return response;

  } catch (error) {
    return null;
  }
}

export const getStageTask = async (productId: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/configurations/stages/tasks?productId=${productId}`)
    return response
  } catch (error) {
    return null;
  }
}

export const deleteStageTask = async (catalog, stage, taskIndex) => {
  try {
    const response = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/configurations/stage/task?catalog=${catalog}&stage=${stage}&task=${taskIndex}`);
    return response;
  } catch (error) {
    return null;
  }
}

export const configureRevertableStages = async (payload) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/stages/reverts/apply`, payload);
    return response;
  } catch (error) {
    return null;
  }
}

export const configuredRevertableStages = async (payload) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/stages/reverts/configs`, payload);
    return response;
  } catch (error) {
    return null;
  }
}

export const generateInspectionId = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/inspections/generate/id?applicationId=${applicationId}`);
    return data;
  } catch (error) {
    return null;
  }
}

export const saveInspectionOfficerDetails = async (payload) => {
  try {
    const { data = {} } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/inspections/audit`, payload);
    return data;
  } catch (error) {
    return null;
  }
}

export const getInspectionOfficerDetails = async (applicationId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/inspections/audit?applicationId=${applicationId}`);
    return response;
  } catch (error) {
    return null;
  }
}

export const deleteInspectionOfficerDetails = async (payload) => {
  try {
    const response = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/inspections/audit`, payload);
    return response;
  } catch (error) {
    return null;
  }
}
