import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { deSelectedBtn, selectedBtn } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

/**-----------------------------------Auto Installment Recovery Required Section----------------------------------------------------**/

export const AutoInstallmentRecoveryRequiredSection = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Auto Recovery Installements</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionAutoInstallmentRecoveryRequired.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={(e) => {
                  props?.doc?.actionAutoInstallmentRecoveryRequired.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionAutoInstallmentRecoveryRequired.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={(e) => {
                  props?.doc?.actionAutoInstallmentRecoveryRequired.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionAutoInstallmentRecoveryRequired.get() && <AutoInstallmentRecoveryRequiredInputSection props={props} />}
    </>
  );
};

const AutoInstallmentRecoveryRequiredInputSection = ({ props }) => {
  const { fdLoanAutoRecoveryAccountNumber, fdAccountName } = props.fields || {};
  const { t: translate } = useTranslation();

  return (
    <InputFieldsStack>
      <InputGrid visibility={fdLoanAutoRecoveryAccountNumber?.visible}>
        <TextBoxComponent value={props?.doc?.fdLoanAutoRecoveryAccountNumber?.get()} onChange={(e) => props?.doc?.fdLoanAutoRecoveryAccountNumber?.set(e.target.value)} label={translate("Recovery Account Number")} onInput={OnDemandValueFormatter.numeric} required={props?.doc?.actionAutoInstallmentRecoveryRequired.get()} />
      </InputGrid>
      <InputGrid visibility={fdAccountName?.visible}>
        <TextBoxComponent value={props?.doc?.fdAccountName?.get()} onChange={(e) => props?.doc?.fdAccountName?.set(e.target.value)} label={translate("Account Name")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={props?.doc?.actionAutoInstallmentRecoveryRequired.get()} />
      </InputGrid>
    </InputFieldsStack>
  );
};

/**-----------------------------------------Loan Transaction Mode Section-----------------------------------------------------------**/

export const LoanTransactionModeSection = ({ props }) => {
  return (
    <>
      <InputGrid visibility={1}>
        <Box mb={2}>
          <FormLabelComponent> Loan Transaction Mode?</FormLabelComponent>
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.actionIsCash.get() === true && props?.doc?.actionIsBankTransfer.get() === false ? selectedBtn : deSelectedBtn}
              title={"Cash"}
              onClick={(e) => {
                props?.doc?.actionIsCash.set(true);
                props?.doc?.actionIsBankTransfer.set(false);
              }}
            />
          </Box>
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.actionIsCash.get() === false && props?.doc?.actionIsBankTransfer.get() === true ? selectedBtn : deSelectedBtn}
              title={"Bank Transfer"}
              onClick={(e) => {
                props?.doc?.actionIsCash.set(false);
                props?.doc?.actionIsBankTransfer.set(true);
              }}
            />
          </Box>
        </Box>
      </InputGrid>
      <LoanTransactionModeInputSection props={props} />
    </>
  );
};

const LoanTransactionModeInputSection = ({ props }) => {
  const { fdAmountByCash, fdamountByTransfer, fdAccountNumberSavings } = props.fields || {};
  const { t: translate } = useTranslation();

  return (
    <>
      {props?.doc?.actionIsCash.get() && (
        <InputGrid visibility={fdAmountByCash.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={translate("Amount By Cash")}
            thousandSeparator={true}
            value={formatCurrency(props?.doc?.fdAmountByCash?.get())}
            required={props?.doc?.actionIsCash.get() ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.fdAmountByCash?.set(value);
            }}

          // isAllowed={withValueLimit}
          />
        </InputGrid>
      )}
      {props?.doc?.actionIsBankTransfer.get() && (
        <>
          <InputGrid visibility={fdamountByTransfer.visible}>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              label={translate("Amount By Transfer")}
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.fdamountByTransfer?.get())}
              required={props?.doc?.actionIsBankTransfer.get() ? true : false}
              decimalScale={2}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.fdamountByTransfer?.set(value);
              }}
            // isAllowed={withValueLimit}
            />
          </InputGrid>
          <InputGrid visibility={fdAccountNumberSavings.visible}>
            <TextBoxComponent value={props?.doc?.fdAccountNumberSavings?.get()} onChange={(e) => props?.doc?.fdAccountNumberSavings?.set(e.target.value)} label={translate("Account Number (Saving account)")} onInput={OnDemandValueFormatter.numeric} required={props?.doc?.actionIsBankTransfer.get() ? true : false} />
          </InputGrid>
        </>
      )}
    </>
  );
};
