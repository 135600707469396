import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { standardDateFormatter } from "los-util";
import moment from "moment";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../utility/fomatters";
import { AddressViewComponent } from "../AddressInformation";
import { RenderingHelper } from "./Components";
import { ContactDetails } from "./ContactDetails";

export const CurrentEmployments = (props) => {
  const deprecatedDateFormatterToggle = moment(formattedDate("1/2/2024")).isAfter(props?.applicationLastUpdatedDate);
  const currentEmployeements = props?.currentEmployeements || [];
  const masterData: any = useState(masterDataDetails);
  const masterDataStore = MasterDataProvider.provideMasterData();
  const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t?.id === key);

  const renderTitle = (item: any = {}) => {
    let value: any = "";

    let title = "Current Employment -";
    let companyName: any = "";

    if (item?.employmentStatus !== 1) {
      return `${title} ${typedData("EMPLOYMENT_STATUS", parseInt(item?.employmentStatus))?.name}`;
    }

    if (item?.employerName == 1) {
      if (item?.companyName != null && item?.companyName != "") {
        value = item?.companyName;
      }
    } else if (item?.employerName == "OTHER") {
      value = item?.companyName;
    } else {
      value = item?.employerName;
    }

    if (value) {
      if (value?.toString()?.length > 3) {
        companyName = value;
      } else {
        const companies: any[] = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies?.find((c) => c?.id?.get() == value);
        companyName = company?.name?.get() ?? "";
      }
    }

    title = `${title} ${companyName}`;

    return title;
  };

  const getEmployerName = (obj: any = {}) => {
    let value: any = "";

    if (obj?.employerName == 1) {
      if (obj?.companyName != null && obj?.companyName != "") {
        value = obj?.companyName;
      }
    } else if (obj?.employerName == "OTHER") {
      value = obj?.companyName;
    } else {
      value = obj?.employerName;
    }

    let companyName: any = "";

    if (value) {
      if (value.toString().length > 3) {
        companyName = value;
      } else {
        const companies: any[] = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies?.find((c) => c?.id?.get() == value);
        companyName = company?.name?.get() ?? "";
      }
    }

    return companyName;
  };

  const getValidDate = (date) => {
    if (deprecatedDateFormatterToggle) {
      return date ? standardDateFormatter(date) : null;
    } else {
      return date;
    }
  };

  return (
    <div className="full-width margin-xs-b">
      <Grid container>
        {currentEmployeements?.filter((obj) => obj)
          ?.filter((obj) => !obj.removedItem)?.map((employment, index) => {
            const employmentType = typedData("EMPLOYMENT_TYPE", employment?.employmentType);
            const occupation = typedData("OCCUPATION", employment?.occupation);
            const employmentStatus = typedData("EMPLOYMENT_STATUS", parseInt(employment?.employmentStatus));
            const industryType = typedData("INDUSTRY_TYPE", employment?.industryType);
            const natureOfEmployment = [
              { id: 1, name: "Contract" },
              { id: 2, name: "Apprenticeship" },
              { id: 3, name: "Traineeship" },
              { id: 4, name: "Employment on commission" },
              { id: 5, name: "Probation" },
            ].find((t) => t?.id === employment?.natureOfEmployment);

            let startDate = employment?.startDate ? employment?.startDate : "-";
            let pensionableDate = employment?.pensionableDate ? employment?.pensionableDate : "-";
            pensionableDate = pensionableDate === "Invalid date" ? "-" : pensionableDate;

            const noOfYearsToPension =
              parseInt(employment?.noOfYearsToPension) > 0
                ? `${employment.noOfYearsToPension} Year${employment.noOfYearsToPension > 1 ? "s" : ""}${parseInt(employment?.noOfMonthsToPension) > 0
                  ? ` , ${employment.noOfMonthsToPension} Month${employment.noOfMonthsToPension > 1 ? "s" : ""}`
                  : ""
                }`
                : parseInt(employment?.noOfMonthsToPension) > 0
                  ? `${employment.noOfMonthsToPension} Month${employment.noOfMonthsToPension > 1 ? "s" : ""}`
                  : "0 Years";

            return (
              <>
                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                  {renderTitle(employment)}
                </Grid>

                {employmentStatus?.name !== "Unemployed" ? (
                  <>
                    <Grid mb={4} container key={index}>
                      <Grid container item p={1} spacing={1} className="data-box" key={index}>
                        {RenderingHelper.addItem(employment, "employmentType", employmentType?.name)}
                        {RenderingHelper.addItem(employment, "occupation", occupation?.name)}
                        {RenderingHelper.addItem(employment, "designation")}
                        {RenderingHelper.addItem(employment, "startDate", getValidDate(startDate), true)}
                        {RenderingHelper.addItem(employment, "fullTimeOrPartTime", employment?.fullTimeOrPartTime == 1 ? "Full Time" : "Part Time")}
                        {RenderingHelper.addItem(employment, "companyName", employment?.employmentStatus == 1 ? getEmployerName(employment) : "-")}
                        {RenderingHelper.addItem(employment, "employmentStatus", employmentStatus?.name)}
                        {RenderingHelper.addItem(employment, "permenantEmployment", employmentStatus?.permenantEmployment == 1 ? "Yes" : "No")}
                        {employment?.permenantEmployment != null && employment?.permenantEmployment > 0 && employment?.permanentDate != null && employment?.permanentDate != "" ? RenderingHelper.addItem(employment, "permanentDate", getValidDate(employment?.permanentDate), true) : ""}
                        {employment?.permenantEmployment != null && employment?.permenantEmployment < 1 ? RenderingHelper.addItem(employment, "natureOfEmployment", natureOfEmployment?.name) : ""}
                        {RenderingHelper.addItem(employment, "employmentTimePeriod")}
                        {RenderingHelper.addItem(employment, "pensionableEmployment", employment?.pensionableEmployment == 1 ? "Yes" : "No")}
                        {employment?.pensionableEmployment != null ? RenderingHelper.addItem(employment, "pensionableDate", pensionableDate, true) : ""}
                        {employment?.pensionableEmployment != null ? RenderingHelper.addItem(employment, "noOfYearsToPension", noOfYearsToPension) : ""}
                        {RenderingHelper.addItem(employment, "industryType", industryType?.name)}
                        <AddressViewComponent addressData={employment?.address} title="Address Information" viewType="tab" />
                      </Grid>

                      {employment?.contactTypes?.filter((obj) => obj)
                        ?.filter((obj) => !obj.removedItem)?.map((type, index) => (
                          <ContactDetails contactDetails={type} index={index} key={index} nohead={true} isBusinessDetails={false}></ContactDetails>
                        ))}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}
      </Grid>
    </div>
  );
};
