import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { masterDataDetails } from "../../../../../configs/mainStore";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";

export const ContactDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const contactData: any = data?.formData?.contactData;
  let label = "Contact Details";

  try {
    const { primaryApplicant = undefined } = data?.formStructure;
    label = primaryApplicant?.[innerComponentData?.id]?.label;
  } catch {
  }
  const getRelationshipName = (relationshipId) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    return masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS.RELATIONSHIP, relationshipId) || "-";
  };
  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Contact Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit?.includes(innerComponentData?.id)} />}

      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          {label ? label : "Contact Details"}
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Telephone
            </Grid>

            <Grid container>
              <Grid container item p={1} className="data-box">
                <Grid item xs={3}>
                  <DetailItem title="Primary Contact Number" value={contactData?.primaryContact ? contactData?.primaryContact : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem title="SMS Number" value={contactData?.primarySms ? contactData?.primarySms : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem title="Subscribe to Bank’s news alerts" value={contactData?.bankSmsNewsAlerts == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
              </Grid>

              <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Other Contact Numbers</div>
              </Grid>
              {contactData?.contactTypes?.length > 0 &&
                contactData?.contactTypes
                  ?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)
                  ?.map((contact, index) => {
                    const contactData = masterData?.CONTACT_TYPE?.get()?.find((t) => t.id == contact?.contactType);
                    return (

                      <Grid container item m={1} p={1} key={index} className="data-box">
                        <Grid item xs={3}>
                          <DetailItem title="Contact Type" value={contactData?.name ? contactData?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                          <DetailItem title="Phone Number" value={contact?.contactNumber ? contact.contactNumber : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      </Grid>
                    );
                  })}

              {isMainContactDetails ?
                <>
                  <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">Relation Contact</div>
                  </Grid>

                  <Grid container item p={1} spacing={1} className="data-box">
                    <Grid item xs={3}>
                      <DetailItem title="Name" value={contactData?.relationName ? contactData?.relationName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem title="Land Number" value={contactData?.relationLandNumber ? contactData?.relationLandNumber : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem
                        title="Relationship"
                        value={getRelationshipName(contactData.relationship)}
                        valueFontClass="font-size-14"
                        titleFontClass="font-size-12"
                      />
                    </Grid>
                    {contactData?.relationship === 8 && (
                      <Grid item xs={3}>
                        <DetailItem
                          title="Other"
                          value={contactData?.otherRelationship || "-"}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                    )}
                  </Grid>
                </> : <></>
              }              
              <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Primary Email Address</div>
              </Grid>

              <Grid container item p={1} className="data-box">
                <Grid item xs={3}>
                  <DetailItem title="Primary Email Address" value={contactData?.primaryEmail ? contactData?.primaryEmail : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem title="Subscribe to Bank’s news alerts" value={contactData?.bankEmailNewsAlerts == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
              <div className="basic-font basic-font-color-bold font-size-16 align-left">Other Email Address</div>
            </Grid>
            {contactData?.emailTypes?.length > 0 &&
              contactData?.emailTypes?.filter((obj) => obj)
                ?.filter((obj) => !obj.removedItem)?.map((email, index) => {
                  const emailData = masterData?.EMAIL_TYPE?.get()?.find((t) => t.id == email?.emailType);
                  return (
                    <Grid container item m={1} p={1} key={index} className="data-box">
                      <Grid item xs={3}>
                        <DetailItem title="Email Type" value={emailData?.name ? emailData?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailItem title="Email Address" value={email?.emailAddress ? email.emailAddress : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                      </Grid>
                    </Grid>
                  );
                })}

            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
              <div className="basic-font basic-font-color-bold font-size-16 align-left">Social Media</div>
            </Grid>

            <Grid container item p={1} className="data-box">
              {contactData?.socialContactTypes?.length > 0 &&
                contactData?.socialContactTypes?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)?.map((media, index) => {
                    const socialMediaData = masterData?.SOCIAL_MEDIA_TYPE?.get()?.find((t) => t.id == media?.type);
                    return (
                      <Grid container item p={1} key={index} className="data-box">
                        {socialMediaData?.name && (
                          <Grid item xs={3}>
                            <DetailItem title="Social Media" value={socialMediaData.name ? socialMediaData.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                        )}
                        {media?.url && (
                          <Grid item xs={3}>
                            <DetailItem title="Social Media Name / URL" value={media.url ? media.url : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactDetails;
