import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import DetailItem from "../../DetailItem";
import { useState, useEffect } from "react";

export const duration = (obj, yearKey, monthKey) => {
  let year = "";
  let month = "";

  obj[yearKey] = obj[yearKey] || 0;
  obj[monthKey] = obj[monthKey] || 0;

  if (obj[yearKey] instanceof String) {
    obj[yearKey] = parseInt(obj[yearKey]);
  }
  if (obj[monthKey] instanceof String) {
    obj[monthKey] = parseInt(obj[monthKey]);
  }

  if (obj[yearKey] > 0) {
    year = `${obj[yearKey] > 9 ? obj[yearKey] : "0" + obj[yearKey]} Year${obj[yearKey] > 1 ? "s" : ""}`;
  }

  if (obj[monthKey] > 0) {
    month = `${obj[monthKey] > 9 ? obj[monthKey] : "0" + obj[monthKey]} Month${obj[monthKey] > 1 ? "s" : ""}`;
  }

  return `${year} ${month}`;
};

const labelsMap = {
  taxIdentificationNumber: "Tax File Number",
  office: "Office",
  indexNumber: "Index Number",
  yearOfAssessment: "Year of Assessment",
  statutoryIncome: "Statutory Income",
  estimatedIncome: "Estimated Income",
  valueSubjectsToTax: "Value Subject to Tax",
  valuePayable: "Value Payable",
  taxPaid: "Paid Tax",
  taxFileNumber: "Tax File Number",

  typeOfBusiness: "Business type",
  businessSector: "Sector",
  businessSubSector: "Sub sector",
  natureOfBusiness: "Nature of business",
  businessRegNo: "Business Registration Number",
  businessCategory:"Business Category",
  brDate: "Business Registration Date",
  nofEmployyes: "Number of employees",
  startDate: "Start Date",

  yearsOfOperation: "No of Years in Operation",
  businessExperience: "Past business experience",
  website: "Website",

  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine3: "Address Line 3",
  addressLine4: "Address Line 4",
  city: "City",
  district: "District",
  dsDivision: "DS Division",
  empGnDivision: "GN Division",
  postalCode: "Postal Code",
  province: "Province",

  noOfEmployees: "Number of Employees",
  assetType: "Asset Type",
  assetValue: "Asset Value",

  noOfFemaleEmployees: "Female Employees",
  noOfMaleEmployees: "Male Employees",
};

export const addItem = (object, key, val = "", isMasterData = false, isDateInput = false) => {
  if (isMasterData) {
    const masterDataStore = MasterDataProvider.provideMasterData();
    val = masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS?.NO_OF_EMPLOYEES, val !== "" ? val : object?.[key]) || null;
  }

  return <Item key={key} label={isDateInput ? `${labelsMap?.[key]} (DD/MM/YYYY)` : labelsMap?.[key] || key} value={val || "N/A"} />;
};

export const typedData = (masterData, masterType, key) => masterData[masterType]?.get()?.find((t) => t?.id === key);

const Item = ({ label, value }) => (
  <Grid item xs={3}>
    <DetailItem title={label} value={value} valueFontClass="font-size-14" titleFontClass="font-size-12" />
  </Grid>
);


export function useSearchDebounce(delay = 350) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}