import { Grid } from "@mui/material";
import { addItem } from "./Helpers";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import { useState } from "@hookstate/core";

export const AuthorityCertificationDetails = ({ business }) => {
  const masterData: any = useState(masterDataDetails);
  const authorityCertifications = business?.authorityCertifications || [];
  const typedData = (masterType, key) =>
    masterData[masterType]?.get()?.find((t) => t.id === key);

  const BuildAuthorityCertificationDetails = ({ authorityCertification }) => {
    const certification = typedData(
      "AUTHORITY_OR_CERTIFICATION",
      authorityCertification?.certification
    );

    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          
          {addItem(
            authorityCertification,
            "Authority or certification for industry",
            certification?.name ?? "N/A"
          )}
          {authorityCertification?.certification === 5 &&
            addItem(
              authorityCertification,
              "Other",
              authorityCertification?.otherCertification ?? "N/A"
            )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="full-width margin-xs-b">
      <Grid
        container
        p={1}
        className="border-radius-5 basic-font basic-font-color-bold font-size-16"
        style={{ backgroundColor: "#e6f0fa" }}
      >
        Business Obtained Certificates
      </Grid>
      {authorityCertifications.length > 0 ? (
        <>
          {authorityCertifications
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((authorityCertification, index) => {
              return (
                <BuildAuthorityCertificationDetails
                  key={index}
                  authorityCertification={authorityCertification}
                />
              );
            })}
        </>
      ) : (
        <ErrorMessageComponent
          headMessage={""}
          errorMessage={"No Data Available"}
        />
      )}
    </div>
  );
};
