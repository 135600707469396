import { Downgraded, useState } from "@hookstate/core";
import { Grid, Typography } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { userDetails } from "../../../../../../../../configs/mainStore";
import isProductOf from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../../../../CommunicationService/CMTextInput";
import { toast } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";

const AnalysisOfJointBorrower = (props) => {
  const isCommercial = isProductOf({ primaryApplicant: true, types: [] });
  const { jb, appraisalData, onChange } = props;
  const {
    personalData: { individualId, oldNic, newNic, initials, primaryLastName },
  } = jb;
  const bankRelation: any = useState(appraisalData.bankRelations?.[individualId] || {});
  const nic = [newNic, oldNic].filter((id) => id).join("/");
  const saving = useState(false);

  const { userId }: any = userDetails.attach(Downgraded).get();
  const bankRelations = MasterDataProvider.provideMasterData().getMasterData(MASTER_DATA_KEYS?.BANK_RELATION);

  const setRelationData = (key, value) => {
    let data: any = bankRelation.get();

    const { createdAt = new Date().toLocaleString(), createdBy = userId, ...rest } = data || {};

    data = { ...rest, createdAt, createdBy, [key]: value, updatedAt: new Date().toLocaleString(), updatedBy: userId };
    bankRelation.set(data);
  };

  const submit = async () => {
    if (!isCommercial) {
      if (!bankRelation?.comment?.get()) {
        return toast({ status: "error", message: "Bank relation comment required to proceed." });
      }
    }

    saving.set(true);
    const result = await onChange({ [individualId]: bankRelation.attach(Downgraded).get() });
    bankRelation.set(result?.bankRelations?.[individualId]);
    saving.set(false);
  };

  const helperText = () => {
    let text = "";

    const { createdBy, createdAt, updatedBy, updatedAt } = bankRelation.get() || {};

    if (updatedBy || createdBy) {
      text += userName(parseInt(updatedBy || createdBy));
    }

    if (updatedAt || createdAt) {
      text += " on " + (updatedAt || createdAt);
    }

    return text;
  };

  return (
    <Grid container>
      <Grid item xs={12} mb={3.5}>
        <Typography variant="subtitle1" color={"gray"}>
          {" "}
          Relationship With RDB (Joint): {[initials, primaryLastName].join(" ")}
        </Typography>
      </Grid>

      <Grid item xs={12} key={nic + "item" + individualId} mb={4}>
        <Grid container>
          <Grid item xs={12} mb={2}>
            <InputGrid visibility={1} variant="fullWidth" key={individualId}>
              <SelectComponent
                key={`SelectComponent_` + individualId}
                label={"Relationship With RDB: " + nic}
                value={bankRelation.relation.get() ?? ""}
                values={bankRelations}
                onChange={(e) => {
                  setRelationData("relation", e.target.value);
                }}
                defaultPlaceholder={true}
                disabled={props?.disableStatus}
              />
            </InputGrid>
          </Grid>
          <Grid item xs={12} mb={2}>
            <CMTextInput
              desc={`Analysis of the Loan Applicant ${!isCommercial ? "*" : ""}`}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    setRelationData("comment", e.target.value);
                  }}
                  multiline={true}
                  minRows={3}
                  maxRows={10}
                  value={bankRelation.comment.get() ?? ""}
                  disabled={props?.disableStatus}
                  helperText={helperText()}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            {saving.get() ? <CircularProgressComponent size={30} /> : <ButtonComponent title="SUBMIT" variant="contained" onClick={submit} color="info" disabled={props?.disableStatus} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalysisOfJointBorrower;
