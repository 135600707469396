import { useState } from "@hookstate/core";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React, { useEffect } from "react";
import { ErrorMessageComponent } from "../../UserCreditComponents/ErrorMessageComponent";
import AddressInformation from "../AddressInformation";
import ApplicationHeader from "../ApplicationHeader";
import AssetsDetails from "../AssetsDetails";
import BankDetails from "../BankDetails";
import BusinessDetails from "../BusinessDetails";
import ContactDetails from "../ContactDetails";
import EducationalDetails from "../EducationalDetails";
import EmploymentDetails from "../EmploymentDetails";
import ExpensesDetails from "../ExpensesData/ExpensesDetails";
import IdentificationDetails from "../IdentificationDetails";
import IncomeDetails from "../IncomeDetails";
import InquiryOfObligations from "../InquiryOfObligations";
import LifeInsuranceDetails from "../LifeInsuranceDetails";
import TaxDetails from "../TaxDetails";

const SupplimentaryCard = ({
    data,
    applicationId,
    mainTabData = { isAppraisal: false },
    isRawVersion = false,
    printMode = false,
    innerComponentData,
    tabsToEdit,
}) => {
    const supplementaryCardArr = data.formData.supplementaryCard;
    const expandState = useState(false);
    const formSections: any = useState([]);
    const [supplimentaryCard, setSupplimentaryCard] = React.useState<any>({});
    const [value, setValue] = React.useState("0");

    useEffect(() => {
        if (supplementaryCardArr?.length > 0) {
            const stateObj = supplementaryCardArr?.map((jointBorrowerData, i) => ({
                [i]: true,
            }));
            expandState.set(stateObj);

            const supplementaryCardDetailsArr = supplementaryCardArr.filter(
                (item?: any) => item?.personalData?.sectionErrorStatus === "success"
            );
            setSupplimentaryCard(supplementaryCardDetailsArr);
        }
    }, [supplementaryCardArr]);

    useEffect(() => {
        formSections.set(Object.values(data?.formStructure?.["jb"]));
    }, [data]);

    const components: any = {
        "primary-applicant-details": IdentificationDetails,
        "contact-details": ContactDetails,
        "address-details": AddressInformation,
        "education-details": EducationalDetails,
        "employment-details": EmploymentDetails,
        "business-details": BusinessDetails,
        "income-details": IncomeDetails,
        "expenses-details": ExpensesDetails,
        "bank-details": BankDetails,
        "assets-details": AssetsDetails,
        "tax-details": TaxDetails,
        "life-insurance-details": LifeInsuranceDetails,
        "inquiry-of-obligations": InquiryOfObligations,
    };

    const sectionIndex = (sectionKey: number) => {
        return "0" + (sectionKey + 1);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div className="mainSectionWrapper full-width full-height">
            {(!isRawVersion || printMode) && (
                <ApplicationHeader
                    formData={data?.formData}
                    requestId={data?.requestId}
                    applicationId={applicationId}
                    title={"Supplementary Cards"}
                    isAppraisal={mainTabData?.isAppraisal}
                    isRawVersion={isRawVersion}
                    disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
                />
            )}
            <>
                {supplimentaryCard?.length > 0 ? (
                    <div className="full-width">
                        <Grid container className="inner-component-height" style={{ overflow: "hidden" }}>
                            <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            {supplimentaryCard?.length > 0 &&
                                                supplimentaryCard?.map((sc, index) => {
                                                    const name = sc?.personalData?.initialsInFull ? sc?.personalData?.initialsInFull : "";
                                                    if (sc?.hasOwnProperty("removedItem") && sc?.["removedItem"] === true) return;

                                                    if (!isRawVersion) return <Tab label={`Supplimentary ${sectionIndex(index)} ${name ? " - " + name : ""}`} value={index.toString()} key={index} />;
                                                })}
                                        </TabList>
                                    </Box>
                                    {supplimentaryCard?.length > 0 &&
                                        supplimentaryCard?.map((sc, index) => {
                                            return (
                                                <TabPanel value={index.toString()} key={index}>
                                                    {formSections.get()?.map((section: any, i: any) => {
                                                        const Component = components[section?.key];
                                                        if (section?.key && section.visible && Component) {
                                                            return <Component data={{ formData: { ...sc } }} applicationId={applicationId} isMainContactDetails={false} key={i} isJointBurrower={true} guarantorJBSection={section?.key} />;
                                                        }
                                                    })}
                                                </TabPanel>
                                            );
                                        })}
                                </TabContext>
                            </Box>
                        </Grid>
                    </div>
                ) : (
                    <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                )}
            </>
        </div>
    );
};


export default SupplimentaryCard;