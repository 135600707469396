import { useMemo } from "react";
import { BasicDataProps } from "../../../../../configs/interfaces";
import { machineryEquipmentDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections";
import { MovableMachineryEquipement } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Movable/Machinery/MachineryAndEquipment";
import BuildCollateralData from "./Build/BuildCollateralData";

export const CreditMovableMachineryEquipement = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const basicData: BasicDataProps = useMemo(() => (
    {
      innerComponentData, applicationId,
      creditFileState: data,
      defaultObject: { ...machineryEquipmentDefault },
      mainTitle: "Machinery And Equipment", parentTitle: 'Movable',
      parentDataKey: "movable", dataKey: "machineryEquipment"
    }),
    [applicationId, data, innerComponentData]);

  return (
    <BuildCollateralData basicData={basicData} children={MovableMachineryEquipement} />
  )
};
