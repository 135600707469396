import { Box } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React from "react";
import { useTranslation } from "react-i18next";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { deSelectedBtn, selectedBtn, VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

/**-----------------------------------WAREHOUSE DETAILS REQUIRED---------------------------------------------**/

export const OwnershipOfWarehousesAction = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1} variant={VARIANT?.longGrid}>
          <Box mb={2}>
            <FormLabelComponent>Whether the ownership of the warehouses belongs to the loan applicant ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionOwnershipOfWarehouses.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionOwnershipOfWarehouses.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionOwnershipOfWarehouses.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionOwnershipOfWarehouses.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {!props?.doc?.actionOwnershipOfWarehouses.get() && <OwnershipOfWarehousesInputsSection props={props} />}
    </>
  );
};

export const OwnershipOfWarehousesInputsSection = ({ props }) => {
  const { t: translate } = useTranslation();
  const { pledgeLeaseAgreementNumber, pledgeDateOfLeaseAgreement, pledgeCustomerType } = props.fields || {};

  const masterDataStore: any = MasterDataProvider.provideMasterData();

  return (
    <InputFieldsStack>
      <InputGrid visibility={pledgeLeaseAgreementNumber.visible}>
        <TextBoxComponent value={props?.doc?.pledgeLeaseAgreementNumber.get()} onChange={(e) => props?.doc?.pledgeLeaseAgreementNumber.set(e.target.value)} label={translate("Lease agreement number confirming ownership")} required={false} onInput={OnDemandValueFormatter.alphaNumeric} />
      </InputGrid>
      <InputGrid visibility={pledgeDateOfLeaseAgreement.visible}>

        <ValidateDatePicker
          value={formattedDate(props?.doc?.pledgeDateOfLeaseAgreement.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.pledgeDateOfLeaseAgreement.set(preparedDate);
            }
          }}
          label={translate("Date of Lease Agreement (DD/MM/YYYY)")}
          enableFuture={false}
          disablePast={false}
          required={false}
        />
      </InputGrid>
      <InputGrid visibility={pledgeCustomerType.visible}>
        <SelectComponent label={translate("Customer Type")} value={props?.doc?.pledgeCustomerType.get()} values={masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MOVABLE_CUSTOMER_TYPE)} required={!props?.doc?.actionOwnershipOfWarehouses.get() ? true : false} onChange={(e) => props?.doc?.pledgeCustomerType.set(e.target.value)} defaultPlaceholder={true} />
      </InputGrid>
      <InputGrid visibility={pledgeLeaseAgreementNumber.visible}>
        <TextBoxComponent value={props?.doc?.pledgeRegistrationNumber.get()} onChange={(e) => props?.doc?.pledgeRegistrationNumber.set(e.target.value)} label={translate("Registration Number")} required={!props?.doc?.actionOwnershipOfWarehouses.get() ? true : false} onInput={OnDemandValueFormatter.alphaNumeric} />
      </InputGrid>
    </InputFieldsStack>
  );
};

export const WarehouseDetailsRequiredAction = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Warehouse details Required ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWarehouseDetailsRequired.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionWarehouseDetailsRequired.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWarehouseDetailsRequired.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionWarehouseDetailsRequired.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionWarehouseDetailsRequired.get() && <WarehouseDetailsRequiredInputsSection props={props} />}
    </>
  );
};

export const WarehouseDetailsRequiredInputsSection = ({ props }) => {
  const { t: translate } = useTranslation();
  const masterDataStore: any = MasterDataProvider.provideMasterData();

  const masterDateValues = {
    districtMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT),
  };
  const { pledgeWarehouseAddress, pledgeDistrict, pledgeCity, pledgeConditionOfTheWarehouse } = props.fields || {};
  return (
    <InputFieldsStack>
      <InputGrid visibility={pledgeWarehouseAddress.visible}>
        <TextBoxComponent value={props?.doc?.pledgeWarehouseAddress.get()} onChange={(e) => props?.doc?.pledgeWarehouseAddress.set(e.target.value)} label={translate("Warehouse Address")} required={props?.doc?.actionWarehouseDetailsRequired.get() ? true : false} onInput={OnDemandValueFormatter.any} />
      </InputGrid>
      <InputGrid visibility={pledgeDistrict.visible}>
        <AutoCompleteProxyComponent
          label={translate("District")}
          value={props?.doc?.pledgeDistrict.get()}
          fullWidth={true}
          required={props?.doc?.actionWarehouseDetailsRequired.get() ? true : false}
          options={masterDateValues.districtMasterData}
          onChange={(e) => {
            return props?.doc?.pledgeDistrict.set(e ? e.id : null);
          }}
          cls="select_basic"
        />
      </InputGrid>
      <InputGrid visibility={pledgeCity.visible}>
        <TextBoxComponent value={props?.doc?.pledgeCity.get()} onChange={(e) => props?.doc?.pledgeCity.set(e.target.value)} label={translate("City")} required={props?.doc?.actionWarehouseDetailsRequired.get() ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
      </InputGrid>
      <InputGrid visibility={pledgeConditionOfTheWarehouse.visible}>
        <TextBoxComponent value={props?.doc?.pledgeConditionOfTheWarehouse.get()} onChange={(e) => props?.doc?.pledgeConditionOfTheWarehouse.set(e.target.value)} label={translate("Condition Of The Warehouse")} required={props?.doc?.actionWarehouseDetailsRequired.get() ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
      </InputGrid>
      {/* <InputGrid visibility={pledgeApproximateDistanceFromTheBranch.visible}>
        <TextBoxComponent value={props?.doc?.pledgeApproximateDistanceFromTheBranch.get()} onChange={(e) => props?.doc?.pledgeApproximateDistanceFromTheBranch.set(e.target.value)} label={translate("Approximate Distance From The Branch")} required={props?.doc?.actionWarehouseDetailsRequired.get() ? true : false} onInput={OnDemandValueFormatter.numeric} />
      </InputGrid> */}
    </InputFieldsStack>
  );
};

/**-----------------------------------Stocks Under Bank's Lock Key---------------------------------------------**/

export const StocksUnderBankLockAction = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Are Stocks under Bank's Lock Key?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionStocksUnderBankLock.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionStocksUnderBankLock.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionStocksUnderBankLock.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionStocksUnderBankLock.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
        {props?.doc?.actionStocksUnderBankLock.get() && <StocksUnderBankLockInputsSection props={props} />}
      </InputFieldsStack>
    </>
  );
};

export const StocksUnderBankLockInputsSection = ({ props }) => {
  const { t: translate } = useTranslation();
  const { pledgeKeyNumber, pledgeDateBanksLockKey } = props.fields || {};
  //pledgeDateBanksLockKey
  return (
    <React.Fragment>
      <InputGrid visibility={pledgeDateBanksLockKey.visible}>
        <ValidateDatePicker
          label={`${translate("Date of under Banks Lock Key")} (DD/MM/YYYY)`}
          value={formattedDate(props?.doc?.pledgeDateBanksLockKey.get())}
          onChange={(date) => {
            props?.doc?.pledgeDateBanksLockKey?.set(standardDateFormatter(date));
          }}
          required={pledgeDateBanksLockKey?.mandatory === 1 ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={pledgeKeyNumber.visible}>
        <TextBoxComponent value={props?.doc?.pledgeKeyNumber.get()} onChange={(e) => props?.doc?.pledgeKeyNumber.set(e.target.value)} label={translate("Key Number")} required={props?.doc?.actionStocksUnderBankLock.get() ? true : false} onInput={OnDemandValueFormatter.numeric} />
      </InputGrid>
    </React.Fragment>
  );
};
