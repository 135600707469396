import { getDateValidationErrorKeys } from "../../../configs/validations/validation-rules/validationRules";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler, validatedFieldKey } from "./structureValidation";

export const obligationDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isNoObligations, isSecuritySectionNext?) => {
  const futureDateRelatedFieldsToValidate: any = {
    loanLease: ["grantedDate"],
    creditCard: [],
    pawning: [],
    overdraft: [],
  };

  const pastDateRelatedFieldsToValidate: any = {
    loanLease: [],
    creditCard: [],
    pawning: ["matureDate"],
    overdraft: ["expiryDate"],
  };

  if (!(state?.loanLease?.length || state?.creditCard?.length || state?.pawning?.length || state?.overdraft?.length)) {

    if (Number(state?.liablityCategory?.get() || 0) === -1) return [];

    return messageMappings(["liabilityCategory"]);
  }

  let finalKeyArr: any = [];

  let errArrInArraysLoanLease = state?.loanLease?.length > 0 ? formStateArrayTypeHandler({ structure, state: state?.loanLease, sectionKey: "loanLease" }) : [];

  let errArrInArraysCreditCard = state?.creditCard?.length > 0 ? formStateArrayTypeHandler({ structure, state: state?.creditCard, sectionKey: "creditCard" }) : [];

  let errArrInArraysPawning = state?.pawning?.length > 0 ? formStateArrayTypeHandler({ structure, state: state?.pawning, sectionKey: "pawning" }) : [];

  let errArrInArraysOverdraft = state?.overdraft?.length > 0 ? formStateArrayTypeHandler({ structure, state: state?.overdraft, sectionKey: "overdraft" }) : [];


  if (errArrInArraysLoanLease?.length > 0) {
    finalKeyArr = finalKeyArr.concat(errArrInArraysLoanLease);
  }

  if (errArrInArraysCreditCard?.length > 0) {
    finalKeyArr = finalKeyArr.concat(errArrInArraysCreditCard);
  }

  if (errArrInArraysPawning?.length > 0) {
    finalKeyArr = finalKeyArr.concat(errArrInArraysPawning);
  }

  if (errArrInArraysOverdraft?.length > 0) {
    finalKeyArr = finalKeyArr.concat(errArrInArraysOverdraft);
  }

  if (isGuarantor || isJointBorrower) {
    state?.loanLease?.get().forEach((obj) => {
      if (obj.security == 1) {
        if (obj.securityType == "") {
          finalKeyArr.push("securityType");
        }
      }
    });
  }

  // Loan Lease custom Validations
  state?.loanLease?.get().forEach((obj) => {

    // Date Fields Validations  - Loan Lease
    futureDateRelatedFieldsToValidate.loanLease.forEach((field) => {
      let errors: any = getDateValidationErrorKeys(obj, field, finalKeyArr, "futureDate", "future");

      if (errors.length > 0) {
        errors.forEach((error) => {
          finalKeyArr.push(error);
        });
      }
    });

    if (!obj?.hasOwnProperty("removedItem") && obj.security === 1) {
      let result = validatedFieldKey(obj?.["securityType"] || "", "securityType");
      finalKeyArr = result ? [...finalKeyArr, result] : finalKeyArr;
    }

    if (!obj?.hasOwnProperty("removedItem") && obj?.exisistingFacilty && obj?.exisistingFacilty === 1) {
      let result = validatedFieldKey(obj?.["ifContinued"] || "", "ifContinued");
      finalKeyArr = result ? [...finalKeyArr, result] : finalKeyArr;
    }
  });

  // Over Draft
  state?.overdraft?.get().forEach((obj) => {
    // Date Fields Validations  - Over Draft
    pastDateRelatedFieldsToValidate.overdraft.forEach((field) => {
      let errors: any = getDateValidationErrorKeys(obj, field, finalKeyArr, "pastDate", "past");

      if (errors.length > 0) {
        errors.forEach((error) => {
          finalKeyArr.push(error);
        });
      }
    });
  });

  // Pawning
  state?.pawning?.get().forEach((obj) => {
    // Date Fields Validations  - Over Draft
    pastDateRelatedFieldsToValidate.pawning.forEach((field) => {
      let errors: any = getDateValidationErrorKeys(obj, field, finalKeyArr, "pastDate", "past");

      if (errors.length > 0) {
        errors.forEach((error) => {
          finalKeyArr.push(error);
        });
      }
    });
  });

  let uniqueChars = finalKeyArr.filter((c, index) => {
    return finalKeyArr.indexOf(c) === index;
  });

  const preparedErrorMessages = messageMappings(uniqueChars);
  return preparedErrorMessages;
};
