import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { FIELDS_WRAPPER_VALUES } from "../../../configs/constants/styleValues";
import { FieldMainSectionsWrapperPropsTypes } from "../../../configs/interfaces";

export const FieldSectionWrapper: FC<FieldMainSectionsWrapperPropsTypes> = ({ title, children }) => {
  return (
    // <InputGroup margin={2} title={title}>
    <Box m={FIELDS_WRAPPER_VALUES.margin} p={FIELDS_WRAPPER_VALUES.padding} mt={FIELDS_WRAPPER_VALUES?.marginTop}>
      <Grid>{children}</Grid>
    </Box>
    // </InputGroup>
  );
};

export const GridSectionWrapper: FC<FieldMainSectionsWrapperPropsTypes> = ({ title, children }) => {
  return (
    // <InputGroup margin={2} title={title}>
    <Box m={FIELDS_WRAPPER_VALUES.margin} p={FIELDS_WRAPPER_VALUES.padding} mt={FIELDS_WRAPPER_VALUES?.marginTop}>
      <Grid container>{children}</Grid>
    </Box>
    // </InputGroup>
  );
};
